import react, { ReactElement, Fragment } from "react";
import { Breadcrumb } from "antd";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { UserOutlined, createFromIconfontCN } from "@ant-design/icons";
import { scriptUrl } from "../../axios/baseUrl";
import option, { SearchType } from "../../views/content/user/index/state";
import styles from "./breadcrumb.less";
const IconFont = createFromIconfontCN<string>({
  scriptUrl: scriptUrl,
});

const MyIcon = createFromIconfontCN({
  scriptUrl: scriptUrl,
});

export class IBreadcrumb extends react.Component<RouteComponentProps> {
  public handleClick: Function = (path: string, query?: object): void => {
    this.props.history.push(path, query || {});
  };

  public SelfRender: Function = (): ReactElement => {
    const location: any = this.props.history.location;
    const pathArr: Array<string> = location.pathname.split("/");
    if (pathArr.includes("user")) {
      if (pathArr[pathArr.length - 1] === "user") {
        return (
          <Breadcrumb.Item>
            <UserOutlined /> 用户管理
          </Breadcrumb.Item>
        );
      } else {
        if (pathArr.includes("detail")) {
          return (
            <Fragment>
              <Breadcrumb.Item
                className={styles.breadcrumbItem}
                onClick={() => this.handleClick("/content/user")}
              >
                <UserOutlined /> <span>用户管理</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className={styles.breadcrumbItem}
                onClick={() => this.handleClick(location.state.private.url)}
              >
                <MyIcon type="icon-icon" />{" "}
                <span>{location.state.private.name}</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item>用户详情</Breadcrumb.Item>
            </Fragment>
          );
        } else {
          var title: string = "";
          Object.keys(option).forEach((key: string): void => {
            option[key].forEach((item: SearchType): void => {
              location.pathname === item.url && (title = item.name);
            });
          });
          return (
            <Fragment>
              <Breadcrumb.Item
                className={styles.breadcrumbItem}
                onClick={() => this.handleClick("/content/user")}
              >
                <UserOutlined /> <span>用户管理</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{title}</Breadcrumb.Item>
            </Fragment>
          );
        }
      }
    } else if (pathArr.includes("problem")) {
      if (pathArr[pathArr.length - 1] === "monitoring") {
        return (
          <Fragment>
            <Breadcrumb.Item
              className={styles.breadcrumbItem}
              onClick={() => this.handleClick("/content/problem")}
            >
              <IconFont type="icon-wenhao-xianxingyuankuang" />{" "}
              <span>查找问题</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>监控诊断</Breadcrumb.Item>
          </Fragment>
        );
      } else if (pathArr[pathArr.length - 1] === "monitoringDetails") {
        const chanSId: string = JSON.parse(
          decodeURI(location.search).split("?")[1]
        ).chanSId;
        return (
          <Fragment>
            <Breadcrumb.Item
              className={styles.breadcrumbItem}
              onClick={() => this.handleClick("/content/problem")}
            >
              <IconFont type="icon-wenhao-xianxingyuankuang" />{" "}
              <span>查找问题</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className={styles.breadcrumbItem}
              onClick={() =>
                this.handleClick("/content/problem/monitoring", { chanSId })
              }
            >
              <span>监控诊断</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>通话详情</Breadcrumb.Item>
          </Fragment>
        );
      } else if (pathArr[pathArr.length - 1] === "problemSee") {
        return (
          <Fragment>
            <Breadcrumb.Item
              className={styles.breadcrumbItem}
              onClick={() => this.handleClick("/content/problem")}
            >
              <IconFont type="icon-wenhao-xianxingyuankuang" />{" "}
              <span>查找问题</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>问题查看</Breadcrumb.Item>
          </Fragment>
        );
      } else if (pathArr[pathArr.length - 1] === "problemSeeDetails") {
        return (
          <Fragment>
            <Breadcrumb.Item
              className={styles.breadcrumbItem}
              onClick={() => this.handleClick("/content/problem")}
            >
              <IconFont type="icon-wenhao-xianxingyuankuang" />{" "}
              <span>查找问题</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className={styles.breadcrumbItem}
              onClick={() =>
                this.handleClick("/content/problem/problemSee", location.state)
              }
            >
              <span>问题查看</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>查看详情</Breadcrumb.Item>
          </Fragment>
        );
      } else {
        return (
          <Breadcrumb.Item>
            <IconFont type="icon-wenhao-xianxingyuankuang" /> 查找问题
          </Breadcrumb.Item>
        );
      }
    } else if (pathArr.includes("email")) {
      if (pathArr[pathArr.length - 1] === "dealEmail") {
        const title: string = location.state ? "编辑邮件" : "添加邮件";
        return (
          <Fragment>
            <Breadcrumb.Item
              className={styles.breadcrumbItem}
              onClick={() => this.handleClick("/content/email")}
            >
              <IconFont type="icon-youjian1" /> <span>邮件管理</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{title}</Breadcrumb.Item>
          </Fragment>
        );
      }
      if (pathArr[pathArr.length - 1] === "viewEmail") {
        return (
          <Fragment>
            <Breadcrumb.Item
              className={styles.breadcrumbItem}
              onClick={() => this.handleClick("/content/email")}
            >
              <IconFont type="icon-youjian1" /> <span>邮件管理</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>邮件详情</Breadcrumb.Item>
          </Fragment>
        );
      } else {
        return (
          <Breadcrumb.Item>
            <IconFont type="icon-youjian1" /> 邮件管理
          </Breadcrumb.Item>
        );
      }
    } else if (pathArr.includes("version")) {
      if (pathArr[pathArr.length - 1] === "dealVersion") {
        const title: string = location.state.versionId ? "编辑SDK" : "更新SDK";
        return (
          <Fragment>
            <Breadcrumb.Item
              className={styles.breadcrumbItem}
              onClick={() => this.handleClick("/content/version")}
            >
              <IconFont type="icon-huojian" /> <span>SDK版本</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{title}</Breadcrumb.Item>
          </Fragment>
        );
      }
      if (pathArr[pathArr.length - 1] === "detail") {
        return (
          <Fragment>
            <Breadcrumb.Item
              className={styles.breadcrumbItem}
              onClick={() => this.handleClick("/content/version")}
            >
              <IconFont type="icon-huojian" /> <span>SDK版本</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>SDK详情</Breadcrumb.Item>
          </Fragment>
        );
      } else {
        return (
          <Breadcrumb.Item>
            <IconFont type="icon-huojian" /> SDK版本
          </Breadcrumb.Item>
        );
      }
    } else if (pathArr.includes("directories")) {
      return (
        <Breadcrumb.Item>
          <IconFont type="icon-chakan1" /> 名单管理
        </Breadcrumb.Item>
      );
    } else if (pathArr.includes("financial")) {
      return (
        <Breadcrumb.Item>
          <IconFont type="icon-qian1" /> 财务管理
        </Breadcrumb.Item>
      );
    } else if (pathArr.includes("private")) {
      if (
        pathArr[pathArr.length - 1] === "customerAdd" ||
        pathArr[pathArr.length - 1] === "customerAddNext"
      ) {
        return (
          <Fragment>
            <Breadcrumb.Item
              className={styles.breadcrumbItem}
              onClick={() => this.handleClick("/content/private")}
            >
              <IconFont type="icon-privateClouds" /> <span>私有云管理</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>添加客户</Breadcrumb.Item>
          </Fragment>
        );
      } else if (pathArr[pathArr.length - 1] === "auditCustomer") {
        return (
          <Fragment>
            <Breadcrumb.Item
              className={styles.breadcrumbItem}
              onClick={() => this.handleClick("/content/private")}
            >
              <IconFont type="icon-privateClouds" /> <span>私有云管理</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>审核详情</Breadcrumb.Item>
          </Fragment>
        );
      } else if (pathArr.includes("detail")) {
        return (
          <Fragment>
            <Breadcrumb.Item
              className={styles.breadcrumbItem}
              onClick={() => this.handleClick("/content/private")}
            >
              <IconFont type="icon-privateClouds" /> <span>私有云管理</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className={styles.breadcrumbItem}
              onClick={() => this.handleClick(location.state.private.url)}
            >
              <MyIcon type="icon-icon" />{" "}
              <span>{location.state.private.name}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>客户详情</Breadcrumb.Item>
          </Fragment>
        );
      } else {
        return (
          <Breadcrumb.Item>
            <IconFont type="icon-privateClouds" /> 私有云管理
          </Breadcrumb.Item>
        );
      }
    } else if (pathArr.includes("permiss")) {
      return (
        <Breadcrumb.Item>
          <IconFont type="icon-permissions" /> 权限管理
        </Breadcrumb.Item>
      );
    } else if (pathArr.includes("privateNew")) {
      if (pathArr.includes("serveDetail")) {
        return (
          <Fragment>
            <Breadcrumb.Item
              className={styles.breadcrumbItem}
              onClick={() => this.handleClick("/content/privateNew")}
            >
              <IconFont type="icon-privateClouds" />{" "}
              <span>私有云管理(new)</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className={styles.breadcrumbItem}
              onClick={() => this.handleClick(location.state.private.url)}
            >
              <MyIcon type="icon-icon" />{" "}
              <span>{location.state.private.name}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>客户详情</Breadcrumb.Item>
          </Fragment>
        );
      } else if (pathArr.includes("serveAdd")) {
        return (
          <Fragment>
            <Breadcrumb.Item
              className={styles.breadcrumbItem}
              onClick={() => this.handleClick("/content/privateNew")}
            >
              <IconFont type="icon-privateClouds" /> <span>私有云管理(new)</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>添加客户</Breadcrumb.Item>
          </Fragment>
        );
      } else {
        return (
          <Breadcrumb.Item>
            <IconFont type="icon-privateClouds" /> 私有云管理(new)
          </Breadcrumb.Item>
        );
      }
    } else {
      return (
        <Fragment>
          <Breadcrumb.Item
            className={styles.breadcrumbItem}
            onClick={() => this.handleClick("/content/user")}
          >
            <UserOutlined /> <span>用户管理</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>All users</Breadcrumb.Item>
        </Fragment>
      );
    }
  };

  render(): ReactElement {
    return (
      <Breadcrumb className={styles.breadcrumb}>{this.SelfRender()}</Breadcrumb>
    );
  }
}

export default withRouter(IBreadcrumb);
