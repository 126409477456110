/**
 * 服务选择
 */

import {
  Checkbox,
  Col,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  Modal,
  Row,
  Tooltip,
} from "antd";
import { InfoCircleFilled } from "@ant-design/icons";

import moment from "moment";

import styles from "./servecheck.less";
import {
  FormInfoConfig,
  FormInfoConfigEnum,
  ServeCheckProps,
  ServeListType,
} from "./type";
import { ReactElement, useEffect, useImperativeHandle, useState } from "react";
import {
  ANYRTC_SERVE_OPTIONS,
  CLEAR_SERVE_CONFIG,
  CONFIG_TRANSITION_PORT,
  PORT_TRANSITION_CONFIG,
  RTC_SERVE_OPTIONS,
  TALK_SERVE_OPTIONS,
  getDefaultServeConfig,
} from "./serveconfig";
import {
  basicExtremumVerify,
  basicNullVerify,
  basicNumberVerify,
} from "./basicverify";

// 日期限制(不可小于明天，不可大于明年的今天)
const disabledPickerDate: DatePickerProps["disabledDate"] = (current) => {
  // 测试用户
  return (
    (current && current < moment().endOf("day")) ||
    (current && current > moment().add(1, "years"))
  );
};

export default function ServeCheck(serveProps: ServeCheckProps) {
  /** 组件方法暴漏 start */
  useImperativeHandle(serveProps.onRef, () => {
    return {
      getFormData: () => {
        return new Promise<{
          // 过期时间
          expirTime: number;
          basicInfo: any;
          checkoutList: number[];
        }>((resolve, reject) => {
          expirTimeForm
            .validateFields()
            .then((res) => {
              // 时间校验成功后，获取当前选中以及对应填充的数据
              const { checkIdList, portConfig } = CONFIG_TRANSITION_PORT(
                [...anyRTCList, ...rtcList, ...quickTalkList],
                serveInfo
              );
              // console.log(
              //   "时间校验成功后，获取当前选中以及对应填充的数据",
              //   checkIdList,
              //   portConfig
              // );
              resolve({
                expirTime: res.expirTime
                  ? moment(res.expirTime).endOf("day").unix()
                  : moment().endOf("day").unix(),
                basicInfo: portConfig || {},
                checkoutList: checkIdList || [],
              });
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
    };
  });

  // 过期时间表单
  const [expirTimeForm] = Form.useForm();

  /** 表单校验 */
  // RTC 最小服务器数量限制
  const [restrictRTCMinServerNum, setRestrictRTCMinServerNum] = useState(1);
  // RTM 最小服务器数量限制
  const [restrictRTMMinServerNum, setRestrictRTMMinServerNum] = useState(1);
  // 互动白板最小服务器数量限制
  const [restrictWhiteBoardMinServerNum, setRestrictWhiteBoardMinServerNum] =
    useState(1);
  // 实时对讲最小服务器数量限制
  const [restrictVoiceMinServerNum, setRestrictVoiceMinServerNum] = useState(1);

  // RTC-云端录制最小服务器数量限制
  const [
    restrictRTCCloudRecordMinServerNum,
    setRestrictRTCCloudRecordMinServerNum,
  ] = useState(1);
  // RTC-小程序最小服务器数量限制
  const [restrictRTCMiniMinServerNum, setRestrictRTCMiniMinServerNum] =
    useState(1);
  // RTC-旁路推流最小服务器数量限制
  const [restrictRTCPushMinServerNum, setRestrictRTCPushMinServerNum] =
    useState(1);
  // RTC-输入在线媒体流最小服务器数量限制
  const [
    restrictRTCOnlineMediaMinServerNum,
    setRestrictRTCOnlineMediaMinServerNum,
  ] = useState(1);

  // 融合通讯-业务授权最小服务器数量限制
  const [
    restrictCharacterLicensingMinServerNum,
    setRestrictCharacterLicensingMinServerNum,
  ] = useState(1);

  // 融合通讯-国标下联网关最小服务器数量限制
  const [
    restrictDownLinkGatewayMinServerNum,
    setRestrictDownLinkGatewayMinServerNum,
  ] = useState(1);
  // 融合通讯-国标上联网关最小服务器数量限制
  const [
    restrictConnectedGatewayMinServerNum,
    setRestrictConnectedGatewayMinServerNum,
  ] = useState(1);
  // 融合通讯-融合会议网关最小服务器数量限制
  const [restrictMeetGatewayMinServerNum, setRestrictMeetGatewayMinServerNum] =
    useState(1);
  // 融合通讯-H.323连接器网关最小服务器数量限制
  const [restrictH323GatewayMinServerNum, setRestrictH323GatewayMinServerNum] =
    useState(1);
  // 融合通讯-电话会议网关最小服务器数量限制
  const [
    restrictTeleconferencingGatewayMinServerNum,
    setRestrictTeleconferencingGatewayMinServerNum,
  ] = useState(1);
  // 融合通讯-RTC2SIP 最小服务器数量限制
  const [
    restrictRTC2SIPGatewayMinServerNum,
    setRestrictRTC2SIPGatewayMinServerNum,
  ] = useState(1);
  // 融合通讯-非标监控网关最小服务器数量限制
  const [
    restrictNonstandardMonitorGatewayMinServerNum,
    setRestrictNonstandardMonitorGatewayMinServerNum,
  ] = useState(1);

  /**
   * 特殊校验处理
   */
  // 融合通讯-业务授权 总账号数
  const CharacterLicensingAccountVerify = async (
    rule: any,
    value: any
  ): Promise<void> => {
    // 获取调度员+管理员的信息
    const oInfo = serveForm.getFieldsValue([
      FormInfoConfigEnum.CharacterLicensingYardmanNum,
      FormInfoConfigEnum.CharacterLicensingAdminNum,
    ]);

    // console.log("获取调度员+管理员的信息", oInfo);

    const oMin =
      Number(oInfo.CharacterLicensingAdminNum || 0) +
      Number(oInfo.CharacterLicensingYardmanNum || 0);
    if (value) {
      if (oMin > 2147483647) {
        throw new Error(`请输入小于或等于2147483647的数字`);
      } else if (value < (oMin || 1) || value > 2147483647) {
        throw new Error(`请输入${oMin || 1}-2147483647之间的数字`);
      }
    }
  };
  // 融合通讯-业务授权 调度员/管理员数量发生变化时校验总账号
  const CharacterLicensingAccountChangeVerify = async (
    rule: any,
    value: any
  ): Promise<void> => {
    if (value && typeof Number(value) === "number") {
      // 校验账号总数量
      serveForm.validateFields([
        FormInfoConfigEnum.CharacterLicensingAccountAllNum,
      ]);
    }
  };

  // 融合通讯-国标下联网 最大通道数量(最大的并发数为通道数的最小值)
  const DownLinkGatewayMaxChannelVerify = async (
    rule: any,
    value: any
  ): Promise<void> => {
    const oInfo = serveForm.getFieldValue(
      FormInfoConfigEnum.DownLinkGatewayMaxConcurrencyNum
    );
    const oMin = Number(oInfo) || 1;
    if (value) {
      if (oMin > 2147483647) {
        throw new Error(`请输入小于或等于2147483647的数字`);
      } else if (value < oMin || value > 2147483647) {
        throw new Error(`请输入${oMin}-2147483647之间的数字`);
      }
    }
  };
  // 融合通讯-国标下联网 并发数发生变化时校验最大通道数
  const DownLinkGatewayMaxConcurrencyChangeVerify = async (
    rule: any,
    value: any
  ): Promise<void> => {
    if (value && typeof Number(value) === "number") {
      // 校验最大通道数量
      serveForm.validateFields([
        FormInfoConfigEnum.DownLinkGatewayMaxChannelNum,
      ]);
    }
  };

  // 融合通讯-国标上联网关 最大通道数量(最大的并发数为通道数的最小值)
  const ConnectedGatewayMaxChannelVerify = async (
    rule: any,
    value: any
  ): Promise<void> => {
    const oInfo = serveForm.getFieldValue(
      FormInfoConfigEnum.ConnectedGatewayMaxConcurrencyNum
    );
    const oMin = Number(oInfo) || 1;
    if (value) {
      if (oMin > 2147483647) {
        throw new Error(`请输入小于或等于2147483647的数字`);
      } else if (value < oMin || value > 2147483647) {
        throw new Error(`请输入${oMin}-2147483647之间的数字`);
      }
    }
  };
  // 融合通讯-国标上联网关 并发数发生变化时校验最大通道数
  const ConnectedGatewayMaxConcurrencyChangeVerify = async (
    rule: any,
    value: any
  ): Promise<void> => {
    if (value && typeof Number(value) === "number") {
      // 校验最大通道数量
      serveForm.validateFields([
        FormInfoConfigEnum.ConnectedGatewayMaxChannelNum,
      ]);
    }
  };

  // 融合通讯-非标监控网关 最大通道数量(最大的并发数为通道数的最小值)
  const NonstandardMonitorGatewayMaxChannelVerify = async (
    rule: any,
    value: any
  ): Promise<void> => {
    const oInfo = serveForm.getFieldValue(
      FormInfoConfigEnum.NonstandardMonitorGatewayMaxConcurrencyNum
    );
    const oMin = Number(oInfo) || 1;
    if (value) {
      if (oMin > 2147483647) {
        throw new Error(`请输入小于或等于2147483647的数字`);
      } else if (value < oMin || value > 2147483647) {
        throw new Error(`请输入${oMin}-2147483647之间的数字`);
      }
    }
  };
  // 融合通讯-非标监控网关 并发数发生变化时校验最大通道数
  const NonstandardMonitorGatewayMaxConcurrencyChangeVerify = async (
    rule: any,
    value: any
  ): Promise<void> => {
    if (value && typeof Number(value) === "number") {
      // 校验最大通道数量
      serveForm.validateFields([
        FormInfoConfigEnum.NonstandardMonitorGatewayMaxChannelNum,
      ]);
    }
  };

  /** 初始输入框填充 */
  // 到期日期初始值
  const [initexpirTime] = useState(
    serveProps.defaultConfig?.expirTime
      ? moment.unix(serveProps.defaultConfig?.expirTime).format("YYYY-MM-DD")
      : ""
  );

  /**
   * 服务选择进行处理
   */
  // anyRTC 服务选择列表
  const [anyRTCList, setAnyRTCList] = useState<any[]>(ANYRTC_SERVE_OPTIONS);
  // RTC 服务选择列表
  const [rtcList, setRtcList] = useState<any[]>(RTC_SERVE_OPTIONS);
  // 快对讲服务选择列表
  const [quickTalkList, setQuickTalkList] = useState<any[]>(TALK_SERVE_OPTIONS);

  // 设置服务选择(通过label判断服务选择)
  const setCheckListLabelFn = (
    serveType: ServeListType,
    type: string,
    check: boolean = true
  ) => {
    if (serveType === "anyRTC") {
      const list = anyRTCList.map((item) => {
        if (item.label === type) {
          item.checked = check;
        }
        return item;
      });
      setAnyRTCList(list);
    }

    if (serveType === "RTC") {
      const list = rtcList.map((item) => {
        if (item.label === type) {
          item.checked = check;
        }
        return item;
      });
      setRtcList(list);
    }

    if (serveType === "Talk") {
      const list = quickTalkList.map((item) => {
        if (item.label === type) {
          item.checked = check;
        }
        return item;
      });
      setQuickTalkList(list);
    }
    return [];
  };

  // 服务信息
  const [serveInfo, setServeInfo] = useState<FormInfoConfig>(
    getDefaultServeConfig()
  );
  // 记录编辑初始服务信息
  const [recordEditInitServeInfo, setRecordEditInitServeInfo] =
    useState<FormInfoConfig>();

  // 服务选择处理
  const serveCategorySelectionChange = (
    e: any,
    type: string,
    isForm: boolean,
    optionType: ServeListType
  ) => {
    // 操作
    const currentCheck = e.target.checked;
    // 选中服务
    if (currentCheck) {
      // 存在弹窗服务
      if (isForm) {
        setServeModalType(type);
        setServeModalConfigType(optionType);
        setIsModalOpen(true);
        return;
      }
      setCheckListLabelFn(optionType, type, true);
    } else {
      // 取消选中RTC服务时清空RTC服务选择
      if (type === ANYRTC_SERVE_OPTIONS[0].label) {
        // 清空 RTC 选择
        const list = rtcList.map((item) => {
          if (item.checked) {
            item.checked = false;
          }
          return item;
        });
        setRtcList(list);
      }
      // 清除选中的服务配置
      const oM = CLEAR_SERVE_CONFIG(optionType, type, recordEditInitServeInfo);
      setServeInfo({
        ...serveInfo,
        ...oM,
      });
      // 取消对应服务
      setCheckListLabelFn(optionType, type, false);
    }
  };

  // 当前组件销毁
  useEffect(() => {
    // 编辑修改时设置
    if (serveProps.defaultConfig) {
      const { basicConfig, configuredServeList } = serveProps.defaultConfig;

      if (configuredServeList && configuredServeList.length > 0) {
        const { serveType, serveConfig } = PORT_TRANSITION_CONFIG(
          configuredServeList,
          basicConfig,
          // 设置服务器最小值(仅编辑时)
          serveProps.isEdit
            ? {
                setRestrictRTCMinServerNum,
                setRestrictRTMMinServerNum,
                setRestrictWhiteBoardMinServerNum,
                setRestrictVoiceMinServerNum,

                setRestrictRTCCloudRecordMinServerNum,
                setRestrictRTCMiniMinServerNum,
                setRestrictRTCPushMinServerNum,
                setRestrictRTCOnlineMediaMinServerNum,

                setRestrictCharacterLicensingMinServerNum,
                setRestrictDownLinkGatewayMinServerNum,
                setRestrictConnectedGatewayMinServerNum,
                setRestrictMeetGatewayMinServerNum,
                setRestrictH323GatewayMinServerNum,
                setRestrictTeleconferencingGatewayMinServerNum,
                setRestrictRTC2SIPGatewayMinServerNum,
                setRestrictNonstandardMonitorGatewayMinServerNum,
              }
            : undefined
        );
        // 初始设置
        // console.log("初始设置", serveType, serveConfig);
        // 设置属性
        setServeInfo({ ...serveConfig });
        // 记录初始属性
        setRecordEditInitServeInfo({ ...serveConfig });

        // 设置选中
        if (serveType.includes("anyRTC")) {
          const list = ANYRTC_SERVE_OPTIONS.map((item) => {
            if (configuredServeList.includes(item.value)) {
              item.checked = true;
            }
            return item;
          });
          setAnyRTCList(list);
        }

        if (serveType.includes("RTC")) {
          const list = RTC_SERVE_OPTIONS.map((item) => {
            if (configuredServeList.includes(item.value)) {
              item.checked = true;
            }
            return item;
          });
          setRtcList(list);
        }

        if (serveType.includes("Talk")) {
          const list = TALK_SERVE_OPTIONS.map((item) => {
            if (configuredServeList.includes(item.value)) {
              item.checked = true;
            }
            return item;
          });
          setQuickTalkList(list);
        }
      }
    }

    return () => {
      // 组件销毁
      setAnyRTCList(
        ANYRTC_SERVE_OPTIONS.map((item) => {
          item.checked = false;
          return item;
        })
      );
      setQuickTalkList(
        TALK_SERVE_OPTIONS.map((item) => {
          item.checked = false;
          return item;
        })
      );
      setRtcList(
        RTC_SERVE_OPTIONS.map((item) => {
          item.checked = false;
          return item;
        })
      );
      // 恢复默认值
      setServeInfo(getDefaultServeConfig());
      // 清空编辑记录
      setRecordEditInitServeInfo(undefined);
    };
  }, [serveProps]);

  /**
   * 服务相关表单弹窗填写
   */
  const [serveForm] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  // 表单类型
  const [serveModalType, setServeModalType] = useState("");
  // 弹窗打开的配置类型
  const [serveModalConfigType, setServeModalConfigType] =
    useState<ServeListType>("anyRTC");

  // 弹窗确认按钮
  const handleOk = () => {
    serveForm
      .validateFields()
      .then((res) => {
        // 记录当表单填写的数据
        setServeInfo({ ...serveInfo, ...res });
        // 设置当前选中
        setCheckListLabelFn(serveModalConfigType, serveModalType, true);

        setIsModalOpen(false);
      })
      .catch((err) => {
        const { errorFields, values } = err;
        if (!errorFields || errorFields.length === 0) {
          // 记录当表单填写的数据
          setServeInfo({ ...serveInfo, ...values });
          // 设置当前选中
          setCheckListLabelFn(serveModalConfigType, serveModalType, true);

          setIsModalOpen(false);
        }
      });
  };
  // 弹窗关闭按钮
  const handleCancel = () => {
    // 关闭弹窗
    setIsModalOpen(false);
  };

  return (
    <div className={styles.serve_check}>
      {/* 服务有效期 */}
      <Form
        form={expirTimeForm}
        size="large"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        {/* 到期时间 */}
        <Form.Item
          label="到期日期"
          name="expirTime"
          initialValue={
            initexpirTime ? moment(initexpirTime, "YYYY-MM-DD") : ""
          }
          rules={[
            { required: true, validator: basicNullVerify("请配置到期日期") },
          ]}
        >
          <DatePicker
            format="YYYY-MM-DD"
            className={styles.form_item_input}
            disabledDate={disabledPickerDate}
            placeholder="请配置到期日期"
          />
        </Form.Item>
      </Form>
      {/* 服务选择 */}
      <div className={styles.serve_check_options}>
        {/* anyRTC */}
        <div className={styles.serve_check_options_item}>
          <h3 className={styles.ul_title}>anyRTC 服务</h3>
          <ul className={styles.check_ul}>
            {anyRTCList.map((item: any): ReactElement => {
              return (
                <li key={item.label} className={styles.check_ul_li_check}>
                  <Checkbox
                    checked={item.checked}
                    onChange={(e) => {
                      serveCategorySelectionChange(
                        e,
                        item.label,
                        item.isForm,
                        "anyRTC"
                      );
                    }}
                  >
                    {item.label}
                  </Checkbox>
                  {/* RTC 服务 */}
                  {item.label === ANYRTC_SERVE_OPTIONS[0].label &&
                    item.checked && (
                      <ul className={styles.check_li_options_ul}>
                        {/* RTC 相关配置 */}
                        <li className={styles.check_li_options_ul_li_config}>
                          并发数：
                          {serveInfo[
                            FormInfoConfigEnum.RTCConcurrencyConcurrencyNum
                          ] || 0}
                        </li>
                        <li className={styles.check_li_options_ul_li_config}>
                          最大服务器数：
                          {serveInfo[FormInfoConfigEnum.RTCMaxServerNum] || 0}
                        </li>
                        {/* RTC 相关服务选择 */}
                        {rtcList &&
                          rtcList.map((serveItem: any) => {
                            return (
                              <li
                                key={serveItem.label}
                                className={styles.check_ul_li_check}
                              >
                                <Checkbox
                                  checked={serveItem.checked}
                                  onChange={(e) => {
                                    serveCategorySelectionChange(
                                      e,
                                      serveItem.label,
                                      serveItem.isForm,
                                      "RTC"
                                    );
                                  }}
                                >
                                  {serveItem.label}
                                </Checkbox>
                                {/* 云端录制 */}
                                {serveItem.label ===
                                  RTC_SERVE_OPTIONS[0].label &&
                                  serveItem.checked && (
                                    <ul className={styles.check_li_options_ul}>
                                      <li
                                        className={
                                          styles.check_li_options_ul_li_config
                                        }
                                      >
                                        最大服务器数：
                                        {serveInfo[
                                          FormInfoConfigEnum
                                            .RTCCloudRecordMaxServerNum
                                        ] || 0}
                                      </li>
                                    </ul>
                                  )}
                                {/* 小程序 */}
                                {serveItem.label ===
                                  RTC_SERVE_OPTIONS[1].label &&
                                  serveItem.checked && (
                                    <ul className={styles.check_li_options_ul}>
                                      <li
                                        className={
                                          styles.check_li_options_ul_li_config
                                        }
                                      >
                                        最大服务器数：
                                        {serveInfo[
                                          FormInfoConfigEnum.RTCMiniMaxServerNum
                                        ] || 0}
                                      </li>
                                    </ul>
                                  )}
                                {/* 旁路推流 */}
                                {serveItem.label ===
                                  RTC_SERVE_OPTIONS[2].label &&
                                  serveItem.checked && (
                                    <ul className={styles.check_li_options_ul}>
                                      <li
                                        className={
                                          styles.check_li_options_ul_li_config
                                        }
                                      >
                                        最大服务器数：
                                        {serveInfo[
                                          FormInfoConfigEnum.RTCPushMaxServerNum
                                        ] || 0}
                                      </li>
                                    </ul>
                                  )}
                                {/* 输入在线媒体流 */}
                                {serveItem.label ===
                                  RTC_SERVE_OPTIONS[3].label &&
                                  serveItem.checked && (
                                    <ul className={styles.check_li_options_ul}>
                                      <li
                                        className={
                                          styles.check_li_options_ul_li_config
                                        }
                                      >
                                        最大服务器数：
                                        {serveInfo[
                                          FormInfoConfigEnum
                                            .RTCOnlineMediaMaxServerNum
                                        ] || 0}
                                      </li>
                                    </ul>
                                  )}
                              </li>
                            );
                          })}
                      </ul>
                    )}
                  {/* RTM 服务 */}
                  {item.label === ANYRTC_SERVE_OPTIONS[1].label &&
                    item.checked && (
                      <ul className={styles.check_li_options_ul}>
                        <li className={styles.check_li_options_ul_li_config}>
                          最大服务器数：
                          {serveInfo[FormInfoConfigEnum.RTMMaxServerNum] || 0}
                        </li>
                      </ul>
                    )}
                  {/* 互动白板服务 */}
                  {item.label === ANYRTC_SERVE_OPTIONS[2].label &&
                    item.checked && (
                      <ul className={styles.check_li_options_ul}>
                        <li className={styles.check_li_options_ul_li_config}>
                          最大服务器数：
                          {serveInfo[
                            FormInfoConfigEnum.WhiteBoardMaxServerNum
                          ] || 0}
                        </li>
                      </ul>
                    )}
                  {/* 实时对讲服务 */}
                  {item.label === ANYRTC_SERVE_OPTIONS[3].label &&
                    item.checked && (
                      <ul className={styles.check_li_options_ul}>
                        <li className={styles.check_li_options_ul_li_config}>
                          最大服务器数：
                          {serveInfo[FormInfoConfigEnum.VoiceMaxServerNum] || 0}
                        </li>
                      </ul>
                    )}
                </li>
              );
            })}
          </ul>
        </div>
        {/* 快对讲 */}
        <div
          className={styles.serve_check_options_item}
          style={{ marginLeft: "20px" }}
        >
          <h3 className={styles.ul_title}>融合通讯服务</h3>
          <ul className={styles.check_ul}>
            {quickTalkList.map((item: any): ReactElement => {
              return (
                <li key={item.label} className={styles.check_ul_li_check}>
                  <div>
                    <Checkbox
                      checked={item.checked}
                      onChange={(e) => {
                        serveCategorySelectionChange(
                          e,
                          item.label,
                          item.isForm,
                          "Talk"
                        );
                      }}
                    >
                      {item.label}
                    </Checkbox>
                    {item.hint && (
                      <Tooltip title={item.hint}>
                        <InfoCircleFilled
                          className={styles.ul_li_tooltip_icon}
                          style={{
                            fontSize: "12px",
                            color: '#999999'
                          }}
                        />
                      </Tooltip>
                    )}
                  </div>
                  {/* 业务授权 */}
                  {item.label === TALK_SERVE_OPTIONS[0].label &&
                    item.checked && (
                      <ul className={styles.check_li_options_ul}>
                        <li className={styles.check_li_options_ul_li_config}>
                          总账号数：
                          {serveInfo[
                            FormInfoConfigEnum.CharacterLicensingAccountAllNum
                          ] || 0}
                        </li>
                        <li className={styles.check_li_options_ul_li_config}>
                          调度员数：
                          {serveInfo[
                            FormInfoConfigEnum.CharacterLicensingYardmanNum
                          ] || 0}
                        </li>
                        <li className={styles.check_li_options_ul_li_config}>
                          管理员数：
                          {serveInfo[
                            FormInfoConfigEnum.CharacterLicensingAdminNum
                          ] || 0}
                        </li>
                        <li className={styles.check_li_options_ul_li_config}>
                          会议录制并发数：
                          {serveInfo[
                            FormInfoConfigEnum
                              .CharacterLicensingMeetRecordConcurrencyNum
                          ] || 0}
                        </li>
                        <li className={styles.check_li_options_ul_li_config}>
                          通话录制并发数：
                          {serveInfo[
                            FormInfoConfigEnum
                              .CharacterLicensingCallRecordConcurrencyNum
                          ] || 0}
                        </li>
                        <li className={styles.check_li_options_ul_li_config}>
                          最大频道成员数：
                          {serveInfo[
                            FormInfoConfigEnum
                              .CharacterLicensingMaxChannelMemberNum
                          ] || 0}
                        </li>
                        <li className={styles.check_li_options_ul_li_config}>
                          最大服务器数：
                          {serveInfo[
                            FormInfoConfigEnum.CharacterLicensingMaxServerNum
                          ] || 0}
                        </li>
                      </ul>
                    )}
                  {/* 国标下联网关 */}
                  {item.label === TALK_SERVE_OPTIONS[1].label &&
                    item.checked && (
                      <ul className={styles.check_li_options_ul}>
                        <li className={styles.check_li_options_ul_li_config}>
                          最大通道数：
                          {serveInfo[
                            FormInfoConfigEnum.DownLinkGatewayMaxChannelNum
                          ] || 0}
                        </li>
                        <li className={styles.check_li_options_ul_li_config}>
                          最大并发数：
                          {serveInfo[
                            FormInfoConfigEnum.DownLinkGatewayMaxConcurrencyNum
                          ] || 0}
                        </li>
                        <li className={styles.check_li_options_ul_li_config}>
                          最大服务器数：
                          {serveInfo[
                            FormInfoConfigEnum.DownLinkGatewayMaxServerNum
                          ] || 0}
                        </li>
                      </ul>
                    )}
                  {/* 国标上联网关 */}
                  {item.label === TALK_SERVE_OPTIONS[2].label &&
                    item.checked && (
                      <ul className={styles.check_li_options_ul}>
                        <li className={styles.check_li_options_ul_li_config}>
                          最大通道数：
                          {serveInfo[
                            FormInfoConfigEnum.ConnectedGatewayMaxChannelNum
                          ] || 0}
                        </li>
                        <li className={styles.check_li_options_ul_li_config}>
                          最大并发数：
                          {serveInfo[
                            FormInfoConfigEnum.ConnectedGatewayMaxConcurrencyNum
                          ] || 0}
                        </li>
                        <li className={styles.check_li_options_ul_li_config}>
                          最大平台数：
                          {serveInfo[
                            FormInfoConfigEnum.ConnectedGatewayMaxPlatformNum
                          ] || 0}
                        </li>
                        <li className={styles.check_li_options_ul_li_config}>
                          最大服务器数：
                          {serveInfo[
                            FormInfoConfigEnum.ConnectedGatewayMaxServerNum
                          ] || 0}
                        </li>
                      </ul>
                    )}
                  {/* 融合会议网关 */}
                  {item.label === TALK_SERVE_OPTIONS[3].label &&
                    item.checked && (
                      <ul className={styles.check_li_options_ul}>
                        <li className={styles.check_li_options_ul_li_config}>
                          最大会议并发数：
                          {serveInfo[
                            FormInfoConfigEnum.MeetGatewayMaxConcurrencyNum
                          ] || 0}
                        </li>
                        <li className={styles.check_li_options_ul_li_config}>
                          最大服务器数：
                          {serveInfo[
                            FormInfoConfigEnum.MeetGatewayMaxServerNum
                          ] || 0}
                        </li>
                      </ul>
                    )}
                  {/* H.323连接器网关 */}
                  {item.label === TALK_SERVE_OPTIONS[4].label &&
                    item.checked && (
                      <ul className={styles.check_li_options_ul}>
                        <li className={styles.check_li_options_ul_li_config}>
                          最大设备数量：
                          {serveInfo[
                            FormInfoConfigEnum.H323GatewayMaxDeviceNum
                          ] || 0}
                        </li>
                        <li className={styles.check_li_options_ul_li_config}>
                          最大呼出并发数：
                          {serveInfo[
                            FormInfoConfigEnum
                              .H323GatewayMaxExpirationConcurrencyNum
                          ] || 0}
                        </li>
                        <li className={styles.check_li_options_ul_li_config}>
                          最大呼入并发数：
                          {serveInfo[
                            FormInfoConfigEnum
                              .H323GatewayMaxCallinConcurrencyNum
                          ] || 0}
                        </li>
                        <li className={styles.check_li_options_ul_li_config}>
                          最大服务器数：
                          {serveInfo[
                            FormInfoConfigEnum.H323GatewayMaxServerNum
                          ] || 0}
                        </li>
                      </ul>
                    )}
                  {/* 电话会议网关 */}
                  {item.label === TALK_SERVE_OPTIONS[5].label &&
                    item.checked && (
                      <ul className={styles.check_li_options_ul}>
                        <li className={styles.check_li_options_ul_li_config}>
                          最大并发数：
                          {serveInfo[
                            FormInfoConfigEnum
                              .TeleconferencingGatewayMaxConcurrencyNum
                          ] || 0}
                        </li>
                        <li className={styles.check_li_options_ul_li_config}>
                          最大服务器数：
                          {serveInfo[
                            FormInfoConfigEnum
                              .TeleconferencingGatewayMaxServerNum
                          ] || 0}
                        </li>
                      </ul>
                    )}
                  {/* RTC2SIP */}
                  {item.label === TALK_SERVE_OPTIONS[6].label &&
                    item.checked && (
                      <ul className={styles.check_li_options_ul}>
                        <li className={styles.check_li_options_ul_li_config}>
                          最大并发数：
                          {serveInfo[
                            FormInfoConfigEnum.RTC2SIPGatewayMaxConcurrencyNum
                          ] || 0}
                        </li>
                        <li className={styles.check_li_options_ul_li_config}>
                          最大服务器数：
                          {serveInfo[
                            FormInfoConfigEnum.RTC2SIPGatewayMaxServerNum
                          ] || 0}
                        </li>
                      </ul>
                    )}
                  {/* 非标监控网关 */}
                  {item.label === TALK_SERVE_OPTIONS[7].label &&
                    item.checked && (
                      <ul className={styles.check_li_options_ul}>
                        <li className={styles.check_li_options_ul_li_config}>
                          最大通道数：
                          {serveInfo[
                            FormInfoConfigEnum
                              .NonstandardMonitorGatewayMaxChannelNum
                          ] || 0}
                        </li>
                        <li className={styles.check_li_options_ul_li_config}>
                          最大并发数：
                          {serveInfo[
                            FormInfoConfigEnum
                              .NonstandardMonitorGatewayMaxConcurrencyNum
                          ] || 0}
                        </li>
                        <li className={styles.check_li_options_ul_li_config}>
                          最大服务器数：
                          {serveInfo[
                            FormInfoConfigEnum
                              .NonstandardMonitorGatewayMaxServerNum
                          ] || 0}
                        </li>
                      </ul>
                    )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      {/* 弹窗 */}
      <Modal
        title={serveModalType + "服务授权"}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="取消"
        okText="确定"
        destroyOnClose
      >
        <Form
          form={serveForm}
          size="large"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          {/* anyRTC-RTC */}
          {serveModalType === ANYRTC_SERVE_OPTIONS[0].label && (
            <Row>
              <Col span={24}>
                {/* 音视频并发数 */}
                <Form.Item
                  name={FormInfoConfigEnum.RTCConcurrencyConcurrencyNum}
                  label="并发数"
                  initialValue={
                    serveInfo[
                      FormInfoConfigEnum.RTCConcurrencyConcurrencyNum
                    ] || ""
                  }
                  rules={[
                    {
                      required: true,
                      validator: basicNullVerify("请输入RTC并发数"),
                    },
                    {
                      validator: basicNumberVerify(),
                    },
                    {
                      validator: basicExtremumVerify(),
                    },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[
                          FormInfoConfigEnum.RTCConcurrencyConcurrencyNum
                        ] || ""
                      }
                      placeholder="请输入RTC并发数"
                    />
                  </div>
                </Form.Item>
                {/* 最大服务器数*/}
                <Form.Item
                  name={FormInfoConfigEnum.RTCMaxServerNum}
                  label="最大服务器数"
                  initialValue={
                    serveInfo[FormInfoConfigEnum.RTCMaxServerNum] || ""
                  }
                  rules={[
                    {
                      required: true,
                      validator: basicNullVerify("请输入最大服务器数"),
                    },
                    {
                      validator: basicNumberVerify(),
                    },
                    {
                      validator: basicExtremumVerify(restrictRTCMinServerNum),
                    },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[FormInfoConfigEnum.RTCMaxServerNum] || ""
                      }
                      placeholder="请输入最大服务器数"
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
          )}
          {/* anyRTC-RTM(最大服务器数) */}
          {serveModalType === ANYRTC_SERVE_OPTIONS[1].label && (
            <Form.Item
              name={FormInfoConfigEnum.RTMMaxServerNum}
              label="最大服务器数"
              initialValue={serveInfo[FormInfoConfigEnum.RTMMaxServerNum] || ""}
              rules={[
                {
                  required: true,
                  validator: basicNullVerify("请输入最大服务器数"),
                },
                {
                  validator: basicNumberVerify(),
                },
                {
                  validator: basicExtremumVerify(restrictRTMMinServerNum),
                },
              ]}
            >
              <div className={styles.form_item_input}>
                <Input
                  defaultValue={
                    serveInfo[FormInfoConfigEnum.RTMMaxServerNum] || ""
                  }
                  placeholder="请输入最大服务器数"
                />
              </div>
            </Form.Item>
          )}
          {/* anyRTC-互动白板(最大服务器数) */}
          {serveModalType === ANYRTC_SERVE_OPTIONS[2].label && (
            <Form.Item
              name={FormInfoConfigEnum.WhiteBoardMaxServerNum}
              label="最大服务器数"
              initialValue={
                serveInfo[FormInfoConfigEnum.WhiteBoardMaxServerNum] || ""
              }
              rules={[
                {
                  required: true,
                  validator: basicNullVerify("请输入最大服务器数"),
                },
                {
                  validator: basicNumberVerify(),
                },
                {
                  validator: basicExtremumVerify(
                    restrictWhiteBoardMinServerNum
                  ),
                },
              ]}
            >
              <div className={styles.form_item_input}>
                <Input
                  defaultValue={
                    serveInfo[FormInfoConfigEnum.WhiteBoardMaxServerNum] || ""
                  }
                  placeholder="请输入最大服务器数"
                />
              </div>
            </Form.Item>
          )}
          {/* anyRTC-实时对讲(最大服务器数) */}
          {serveModalType === ANYRTC_SERVE_OPTIONS[3].label && (
            <Form.Item
              name={FormInfoConfigEnum.VoiceMaxServerNum}
              label="最大服务器数"
              initialValue={
                serveInfo[FormInfoConfigEnum.VoiceMaxServerNum] || ""
              }
              rules={[
                {
                  required: true,
                  validator: basicNullVerify("请输入最大服务器数"),
                },
                {
                  validator: basicNumberVerify(),
                },
                {
                  validator: basicExtremumVerify(restrictVoiceMinServerNum),
                },
              ]}
            >
              <div className={styles.form_item_input}>
                <Input
                  defaultValue={
                    serveInfo[FormInfoConfigEnum.VoiceMaxServerNum] || ""
                  }
                  placeholder="请输入最大服务器数"
                />
              </div>
            </Form.Item>
          )}

          {/* anyRTC-RTC-云端录制(最大服务器数) */}
          {serveModalType === RTC_SERVE_OPTIONS[0].label && (
            <Form.Item
              name={FormInfoConfigEnum.RTCCloudRecordMaxServerNum}
              label="最大服务器数"
              initialValue={
                serveInfo[FormInfoConfigEnum.RTCCloudRecordMaxServerNum] || ""
              }
              rules={[
                {
                  required: true,
                  validator: basicNullVerify("请输入最大服务器数"),
                },
                {
                  validator: basicNumberVerify(),
                },
                {
                  validator: basicExtremumVerify(
                    restrictRTCCloudRecordMinServerNum
                  ),
                },
              ]}
            >
              <div className={styles.form_item_input}>
                <Input
                  defaultValue={
                    serveInfo[FormInfoConfigEnum.RTCCloudRecordMaxServerNum] ||
                    ""
                  }
                  placeholder="请输入最大服务器数"
                />
              </div>
            </Form.Item>
          )}
          {/* anyRTC-RTC-小程序(最大服务器数) */}
          {serveModalType === RTC_SERVE_OPTIONS[1].label && (
            <Form.Item
              name={FormInfoConfigEnum.RTCMiniMaxServerNum}
              label="最大服务器数"
              initialValue={
                serveInfo[FormInfoConfigEnum.RTCMiniMaxServerNum] || ""
              }
              rules={[
                {
                  required: true,
                  validator: basicNullVerify("请输入最大服务器数"),
                },
                {
                  validator: basicNumberVerify(),
                },
                {
                  validator: basicExtremumVerify(restrictRTCMiniMinServerNum),
                },
              ]}
            >
              <div className={styles.form_item_input}>
                <Input
                  defaultValue={
                    serveInfo[FormInfoConfigEnum.RTCMiniMaxServerNum] || ""
                  }
                  placeholder="请输入最大服务器数"
                />
              </div>
            </Form.Item>
          )}
          {/* anyRTC-RTC-旁路推流(最大服务器数) */}
          {serveModalType === RTC_SERVE_OPTIONS[2].label && (
            <Form.Item
              name={FormInfoConfigEnum.RTCPushMaxServerNum}
              label="最大服务器数"
              initialValue={
                serveInfo[FormInfoConfigEnum.RTCPushMaxServerNum] || ""
              }
              rules={[
                {
                  required: true,
                  validator: basicNullVerify("请输入最大服务器数"),
                },
                {
                  validator: basicNumberVerify(),
                },
                {
                  validator: basicExtremumVerify(restrictRTCPushMinServerNum),
                },
              ]}
            >
              <div className={styles.form_item_input}>
                <Input
                  defaultValue={
                    serveInfo[FormInfoConfigEnum.RTCPushMaxServerNum] || ""
                  }
                  placeholder="请输入最大服务器数"
                />
              </div>
            </Form.Item>
          )}
          {/* anyRTC-RTC-输入在线媒体流(最大服务器数) */}
          {serveModalType === RTC_SERVE_OPTIONS[3].label && (
            <Form.Item
              name={FormInfoConfigEnum.RTCOnlineMediaMaxServerNum}
              label="最大服务器数"
              initialValue={
                serveInfo[FormInfoConfigEnum.RTCOnlineMediaMaxServerNum] || ""
              }
              rules={[
                {
                  required: true,
                  validator: basicNullVerify("请输入最大服务器数"),
                },
                {
                  validator: basicNumberVerify(),
                },
                {
                  validator: basicExtremumVerify(
                    restrictRTCOnlineMediaMinServerNum
                  ),
                },
              ]}
            >
              <div className={styles.form_item_input}>
                <Input
                  defaultValue={
                    serveInfo[FormInfoConfigEnum.RTCOnlineMediaMaxServerNum] ||
                    ""
                  }
                  placeholder="请输入最大服务器数"
                />
              </div>
            </Form.Item>
          )}

          {/* 融合通讯-业务授权 */}
          {serveModalType === TALK_SERVE_OPTIONS[0].label && (
            <Row>
              <Col span={24}>
                {/* 总账号数 */}
                <Form.Item
                  name={FormInfoConfigEnum.CharacterLicensingAccountAllNum}
                  label="总账号数"
                  initialValue={
                    serveInfo[
                      FormInfoConfigEnum.CharacterLicensingAccountAllNum
                    ] || ""
                  }
                  rules={[
                    {
                      required: true,
                      validator: basicNullVerify("请输入总账号数"),
                    },
                    {
                      validator: basicNumberVerify(),
                    },
                    {
                      validator: CharacterLicensingAccountVerify,
                    },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[
                          FormInfoConfigEnum.CharacterLicensingAccountAllNum
                        ] || ""
                      }
                      placeholder="请输入总账号数"
                    />
                  </div>
                </Form.Item>
                {/* 调度员数 */}
                <Form.Item
                  name={FormInfoConfigEnum.CharacterLicensingYardmanNum}
                  label="调度员数"
                  initialValue={
                    serveInfo[
                      FormInfoConfigEnum.CharacterLicensingYardmanNum
                    ] || ""
                  }
                  rules={[
                    { validator: basicNumberVerify() },
                    { validator: basicExtremumVerify(0) },
                    { validator: CharacterLicensingAccountChangeVerify },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[
                          FormInfoConfigEnum.CharacterLicensingYardmanNum
                        ] || ""
                      }
                      placeholder="请输入调度员数"
                    />
                  </div>
                </Form.Item>
                {/* 管理员数 */}
                <Form.Item
                  name={FormInfoConfigEnum.CharacterLicensingAdminNum}
                  label="管理员数"
                  initialValue={
                    serveInfo[FormInfoConfigEnum.CharacterLicensingAdminNum] ||
                    ""
                  }
                  rules={[
                    {
                      required: true,
                      validator: basicNullVerify("请输入管理员数"),
                    },
                    {
                      validator: basicNumberVerify(),
                    },
                    { validator: CharacterLicensingAccountChangeVerify },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[
                          FormInfoConfigEnum.CharacterLicensingAdminNum
                        ] || ""
                      }
                      placeholder="请输入管理员数"
                    />
                  </div>
                </Form.Item>
                {/* 会议录制并发数 */}
                <Form.Item
                  name={
                    FormInfoConfigEnum.CharacterLicensingMeetRecordConcurrencyNum
                  }
                  label="会议录制并发数"
                  initialValue={
                    serveInfo[
                      FormInfoConfigEnum
                        .CharacterLicensingMeetRecordConcurrencyNum
                    ] || ""
                  }
                  rules={[
                    {
                      validator: basicNumberVerify(),
                    },
                    {
                      validator: basicExtremumVerify(),
                    },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[
                          FormInfoConfigEnum
                            .CharacterLicensingMeetRecordConcurrencyNum
                        ] || ""
                      }
                      placeholder="请输入会议录制并发数"
                    />
                  </div>
                </Form.Item>
                {/* 通话录制并发数 */}
                <Form.Item
                  name={
                    FormInfoConfigEnum.CharacterLicensingCallRecordConcurrencyNum
                  }
                  label="通话录制并发数"
                  initialValue={
                    serveInfo[
                      FormInfoConfigEnum
                        .CharacterLicensingCallRecordConcurrencyNum
                    ] || ""
                  }
                  rules={[
                    {
                      validator: basicNumberVerify(),
                    },
                    {
                      validator: basicExtremumVerify(),
                    },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[
                          FormInfoConfigEnum
                            .CharacterLicensingCallRecordConcurrencyNum
                        ] || ""
                      }
                      placeholder="请输入通话录制并发数"
                    />
                  </div>
                </Form.Item>
                {/* 最大频道成员数 */}
                <Form.Item
                  name={
                    FormInfoConfigEnum.CharacterLicensingMaxChannelMemberNum
                  }
                  label="最大频道成员数"
                  initialValue={
                    serveInfo[
                      FormInfoConfigEnum.CharacterLicensingMaxChannelMemberNum
                    ] || ""
                  }
                  rules={[
                    {
                      required: true,
                      validator: basicNullVerify("请输入最大频道成员数"),
                    },
                    {
                      validator: basicNumberVerify(),
                    },
                    { validator: basicExtremumVerify(1, 5000) },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[
                          FormInfoConfigEnum
                            .CharacterLicensingMaxChannelMemberNum
                        ] || ""
                      }
                      placeholder="请输入最大频道成员数"
                    />
                  </div>
                </Form.Item>
                {/* 最大服务器数 */}
                <Form.Item
                  name={FormInfoConfigEnum.CharacterLicensingMaxServerNum}
                  label="最大服务器数"
                  initialValue={
                    serveInfo[
                      FormInfoConfigEnum.CharacterLicensingMaxServerNum
                    ] || ""
                  }
                  rules={[
                    {
                      required: true,
                      validator: basicNullVerify("请输入最大服务器数"),
                    },
                    {
                      validator: basicNumberVerify(),
                    },
                    {
                      validator: basicExtremumVerify(
                        restrictCharacterLicensingMinServerNum
                      ),
                    },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[
                          FormInfoConfigEnum.CharacterLicensingMaxServerNum
                        ] || ""
                      }
                      placeholder="请输入最大服务器数"
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
          )}
          {/* 融合通讯-国标下联网关 */}
          {serveModalType === TALK_SERVE_OPTIONS[1].label && (
            <Row>
              <Col span={24}>
                {/* 最大通道数 */}
                <Form.Item
                  name={FormInfoConfigEnum.DownLinkGatewayMaxChannelNum}
                  label="最大通道数"
                  initialValue={
                    serveInfo[
                      FormInfoConfigEnum.DownLinkGatewayMaxChannelNum
                    ] || ""
                  }
                  rules={[
                    {
                      required: true,
                      validator: basicNullVerify("请输入最大通道数"),
                    },
                    {
                      validator: basicNumberVerify(),
                    },
                    { validator: DownLinkGatewayMaxChannelVerify },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[
                          FormInfoConfigEnum.DownLinkGatewayMaxChannelNum
                        ] || ""
                      }
                      placeholder="请输入最大通道数"
                    />
                  </div>
                </Form.Item>
                {/* 最大并发数 */}
                <Form.Item
                  name={FormInfoConfigEnum.DownLinkGatewayMaxConcurrencyNum}
                  label="最大并发数"
                  initialValue={
                    serveInfo[
                      FormInfoConfigEnum.DownLinkGatewayMaxConcurrencyNum
                    ] || ""
                  }
                  rules={[
                    {
                      required: true,
                      validator: basicNullVerify("请输入最大并发数"),
                    },
                    {
                      validator: basicNumberVerify(),
                    },
                    { validator: basicExtremumVerify() },
                    {
                      validator: DownLinkGatewayMaxConcurrencyChangeVerify,
                    },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[
                          FormInfoConfigEnum.DownLinkGatewayMaxConcurrencyNum
                        ] || ""
                      }
                      placeholder="请输入最大并发数"
                    />
                  </div>
                </Form.Item>
                {/* 最大服务器数 */}
                <Form.Item
                  name={FormInfoConfigEnum.DownLinkGatewayMaxServerNum}
                  label="最大服务器数"
                  initialValue={
                    serveInfo[FormInfoConfigEnum.DownLinkGatewayMaxServerNum] ||
                    ""
                  }
                  rules={[
                    {
                      required: true,
                      validator: basicNullVerify("请输入最大服务器数"),
                    },
                    {
                      validator: basicNumberVerify(),
                    },
                    {
                      validator: basicExtremumVerify(
                        restrictDownLinkGatewayMinServerNum
                      ),
                    },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[
                          FormInfoConfigEnum.DownLinkGatewayMaxServerNum
                        ] || ""
                      }
                      placeholder="请输入最大服务器数"
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
          )}
          {/* 融合通讯-国标上联网关 */}
          {serveModalType === TALK_SERVE_OPTIONS[2].label && (
            <Row>
              <Col span={24}>
                {/* 最大通道数 */}
                <Form.Item
                  name={FormInfoConfigEnum.ConnectedGatewayMaxChannelNum}
                  label="最大通道数"
                  initialValue={
                    serveInfo[
                      FormInfoConfigEnum.ConnectedGatewayMaxChannelNum
                    ] || ""
                  }
                  rules={[
                    {
                      required: true,
                      validator: basicNullVerify("请输入最大通道数"),
                    },
                    {
                      validator: basicNumberVerify(),
                    },
                    { validator: ConnectedGatewayMaxChannelVerify },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[
                          FormInfoConfigEnum.ConnectedGatewayMaxChannelNum
                        ] || ""
                      }
                      placeholder="请输入最大通道数"
                    />
                  </div>
                </Form.Item>
                {/* 最大并发数 */}
                <Form.Item
                  name={FormInfoConfigEnum.ConnectedGatewayMaxConcurrencyNum}
                  label="最大并发数"
                  initialValue={
                    serveInfo[
                      FormInfoConfigEnum.ConnectedGatewayMaxConcurrencyNum
                    ] || ""
                  }
                  rules={[
                    {
                      required: true,
                      validator: basicNullVerify("请输入最大并发数"),
                    },
                    {
                      validator: basicNumberVerify(),
                    },
                    { validator: basicExtremumVerify() },
                    {
                      validator: ConnectedGatewayMaxConcurrencyChangeVerify,
                    },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[
                          FormInfoConfigEnum.ConnectedGatewayMaxConcurrencyNum
                        ] || ""
                      }
                      placeholder="请输入最大并发数"
                    />
                  </div>
                </Form.Item>
                {/* 最大平台数 */}
                <Form.Item
                  name={FormInfoConfigEnum.ConnectedGatewayMaxPlatformNum}
                  label="最大平台数"
                  initialValue={
                    serveInfo[
                      FormInfoConfigEnum.ConnectedGatewayMaxPlatformNum
                    ] || ""
                  }
                  rules={[
                    {
                      required: true,
                      validator: basicNullVerify("请输入最大平台数"),
                    },
                    {
                      validator: basicNumberVerify(),
                    },
                    {
                      validator: basicExtremumVerify(),
                    },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[
                          FormInfoConfigEnum.ConnectedGatewayMaxPlatformNum
                        ] || ""
                      }
                      placeholder="请输入最大平台数"
                    />
                  </div>
                </Form.Item>
                {/* 最大服务器数 */}
                <Form.Item
                  name={FormInfoConfigEnum.ConnectedGatewayMaxServerNum}
                  label="最大服务器数"
                  initialValue={
                    serveInfo[
                      FormInfoConfigEnum.ConnectedGatewayMaxServerNum
                    ] || ""
                  }
                  rules={[
                    {
                      required: true,
                      validator: basicNullVerify("请输入最大服务器数"),
                    },
                    {
                      validator: basicNumberVerify(),
                    },
                    {
                      validator: basicExtremumVerify(
                        restrictConnectedGatewayMinServerNum
                      ),
                    },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[
                          FormInfoConfigEnum.ConnectedGatewayMaxServerNum
                        ] || ""
                      }
                      placeholder="请输入最大服务器数"
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
          )}
          {/* 融合通讯-融合会议网关 */}
          {serveModalType === TALK_SERVE_OPTIONS[3].label && (
            <Row>
              <Col span={24}>
                {/* 最大会议并发数 */}
                <Form.Item
                  name={FormInfoConfigEnum.MeetGatewayMaxConcurrencyNum}
                  label="最大会议并发数"
                  initialValue={
                    serveInfo[
                      FormInfoConfigEnum.MeetGatewayMaxConcurrencyNum
                    ] || ""
                  }
                  rules={[
                    {
                      required: true,
                      validator: basicNullVerify("请输入最大会议并发数"),
                    },
                    {
                      validator: basicNumberVerify(),
                    },
                    {
                      validator: basicExtremumVerify(),
                    },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[
                          FormInfoConfigEnum.MeetGatewayMaxConcurrencyNum
                        ] || ""
                      }
                      placeholder="请输入最大会议并发数"
                    />
                  </div>
                </Form.Item>
                {/* 最大服务器数 */}
                <Form.Item
                  name={FormInfoConfigEnum.MeetGatewayMaxServerNum}
                  label="最大服务器数"
                  initialValue={
                    serveInfo[FormInfoConfigEnum.MeetGatewayMaxServerNum] || ""
                  }
                  rules={[
                    {
                      required: true,
                      validator: basicNullVerify("请输入最大服务器数"),
                    },
                    {
                      validator: basicNumberVerify(),
                    },
                    {
                      validator: basicExtremumVerify(
                        restrictMeetGatewayMinServerNum
                      ),
                    },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[FormInfoConfigEnum.MeetGatewayMaxServerNum] ||
                        ""
                      }
                      placeholder="请输入最大服务器数"
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
          )}
          {/* 融合通讯-H.323连接器网关 */}
          {serveModalType === TALK_SERVE_OPTIONS[4].label && (
            <Row>
              <Col span={24}>
                {/* 最大设备数量 */}
                <Form.Item
                  name={FormInfoConfigEnum.H323GatewayMaxDeviceNum}
                  label="最大设备数量"
                  initialValue={
                    serveInfo[FormInfoConfigEnum.H323GatewayMaxDeviceNum] || ""
                  }
                  rules={[
                    {
                      required: true,
                      validator: basicNullVerify("请输入最大设备数量"),
                    },
                    {
                      validator: basicNumberVerify(),
                    },
                    {
                      validator: basicExtremumVerify(),
                    },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[FormInfoConfigEnum.H323GatewayMaxDeviceNum] ||
                        ""
                      }
                      placeholder="请输入最大设备数量"
                    />
                  </div>
                </Form.Item>
                {/* 最大呼出并发数 */}
                <Form.Item
                  name={
                    FormInfoConfigEnum.H323GatewayMaxExpirationConcurrencyNum
                  }
                  label="最大呼出并发数"
                  initialValue={
                    serveInfo[
                      FormInfoConfigEnum.H323GatewayMaxExpirationConcurrencyNum
                    ] || ""
                  }
                  rules={[
                    {
                      required: true,
                      validator: basicNullVerify("请输入最大呼出并发数"),
                    },
                    {
                      validator: basicNumberVerify(),
                    },
                    {
                      validator: basicExtremumVerify(),
                    },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[
                          FormInfoConfigEnum
                            .H323GatewayMaxExpirationConcurrencyNum
                        ] || ""
                      }
                      placeholder="请输入最大呼出并发数"
                    />
                  </div>
                </Form.Item>
                {/* 最大呼入并发数 */}
                <Form.Item
                  name={FormInfoConfigEnum.H323GatewayMaxCallinConcurrencyNum}
                  label="最大呼入并发数"
                  initialValue={
                    serveInfo[
                      FormInfoConfigEnum.H323GatewayMaxCallinConcurrencyNum
                    ] || ""
                  }
                  rules={[
                    {
                      validator: basicNumberVerify(),
                    },
                    {
                      validator: basicExtremumVerify(0),
                    },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[
                          FormInfoConfigEnum.H323GatewayMaxCallinConcurrencyNum
                        ] || ""
                      }
                      placeholder="请输入最大呼入并发数"
                    />
                  </div>
                </Form.Item>
                {/* 最大服务器数 */}
                <Form.Item
                  name={FormInfoConfigEnum.H323GatewayMaxServerNum}
                  label="最大服务器数"
                  initialValue={
                    serveInfo[FormInfoConfigEnum.H323GatewayMaxServerNum] || ""
                  }
                  rules={[
                    {
                      required: true,
                      validator: basicNullVerify("请输入最大服务器数"),
                    },
                    {
                      validator: basicNumberVerify(),
                    },
                    {
                      validator: basicExtremumVerify(
                        restrictH323GatewayMinServerNum
                      ),
                    },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[FormInfoConfigEnum.H323GatewayMaxServerNum] ||
                        ""
                      }
                      placeholder="请输入最大服务器数"
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
          )}
          {/* 融合通讯-电话会议网关 */}
          {serveModalType === TALK_SERVE_OPTIONS[5].label && (
            <Row>
              <Col span={24}>
                {/* 最大并发数 */}
                <Form.Item
                  name={
                    FormInfoConfigEnum.TeleconferencingGatewayMaxConcurrencyNum
                  }
                  label="最大并发数"
                  initialValue={
                    serveInfo[
                      FormInfoConfigEnum
                        .TeleconferencingGatewayMaxConcurrencyNum
                    ] || ""
                  }
                  rules={[
                    {
                      required: true,
                      validator: basicNullVerify("请输入最大并发数"),
                    },
                    {
                      validator: basicNumberVerify(),
                    },
                    {
                      validator: basicExtremumVerify(),
                    },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[
                          FormInfoConfigEnum
                            .TeleconferencingGatewayMaxConcurrencyNum
                        ] || ""
                      }
                      placeholder="请输入最大并发数"
                    />
                  </div>
                </Form.Item>
                {/* 最大服务器数 */}
                <Form.Item
                  name={FormInfoConfigEnum.TeleconferencingGatewayMaxServerNum}
                  label="最大服务器数"
                  initialValue={
                    serveInfo[
                      FormInfoConfigEnum.TeleconferencingGatewayMaxServerNum
                    ] || ""
                  }
                  rules={[
                    {
                      required: true,
                      validator: basicNullVerify("请输入最大服务器数"),
                    },
                    {
                      validator: basicNumberVerify(),
                    },
                    {
                      validator: basicExtremumVerify(
                        restrictTeleconferencingGatewayMinServerNum
                      ),
                    },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[
                          FormInfoConfigEnum.TeleconferencingGatewayMaxServerNum
                        ] || ""
                      }
                      placeholder="请输入最大服务器数"
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
          )}
          {/* 融合通讯-RTC2SIP网关 */}
          {serveModalType === TALK_SERVE_OPTIONS[6].label && (
            <Row>
              <Col span={24}>
                {/* 最大并发数 */}
                <Form.Item
                  name={FormInfoConfigEnum.RTC2SIPGatewayMaxConcurrencyNum}
                  label="最大并发数"
                  initialValue={
                    serveInfo[
                      FormInfoConfigEnum.RTC2SIPGatewayMaxConcurrencyNum
                    ] || ""
                  }
                  rules={[
                    {
                      required: true,
                      validator: basicNullVerify("请输入最大并发数"),
                    },
                    {
                      validator: basicNumberVerify(),
                    },
                    {
                      validator: basicExtremumVerify(),
                    },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[
                          FormInfoConfigEnum.RTC2SIPGatewayMaxConcurrencyNum
                        ] || ""
                      }
                      placeholder="请输入最大并发数"
                    />
                  </div>
                </Form.Item>
                {/* 最大服务器数 */}
                <Form.Item
                  name={FormInfoConfigEnum.RTC2SIPGatewayMaxServerNum}
                  label="最大服务器数"
                  initialValue={
                    serveInfo[FormInfoConfigEnum.RTC2SIPGatewayMaxServerNum] ||
                    ""
                  }
                  rules={[
                    {
                      required: true,
                      validator: basicNullVerify("请输入最大服务器数"),
                    },
                    {
                      validator: basicNumberVerify(),
                    },
                    {
                      validator: basicExtremumVerify(
                        restrictRTC2SIPGatewayMinServerNum
                      ),
                    },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[
                          FormInfoConfigEnum.RTC2SIPGatewayMaxServerNum
                        ] || ""
                      }
                      placeholder="请输入最大服务器数"
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
          )}
          {/* 融合通讯-非标监控网关 */}
          {serveModalType === TALK_SERVE_OPTIONS[7].label && (
            <Row>
              <Col span={24}>
                {/* 最大通道数 */}
                <Form.Item
                  name={
                    FormInfoConfigEnum.NonstandardMonitorGatewayMaxChannelNum
                  }
                  label="最大通道数"
                  initialValue={
                    serveInfo[
                      FormInfoConfigEnum.NonstandardMonitorGatewayMaxChannelNum
                    ] || ""
                  }
                  rules={[
                    {
                      required: true,
                      validator: basicNullVerify("请输入最大通道数"),
                    },
                    {
                      validator: basicNumberVerify(),
                    },
                    {
                      validator: NonstandardMonitorGatewayMaxChannelVerify,
                    },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[
                          FormInfoConfigEnum
                            .NonstandardMonitorGatewayMaxChannelNum
                        ] || ""
                      }
                      placeholder="请输入最大通道数"
                    />
                  </div>
                </Form.Item>
                {/* 最大并发数 */}
                <Form.Item
                  name={
                    FormInfoConfigEnum.NonstandardMonitorGatewayMaxConcurrencyNum
                  }
                  label="最大并发数"
                  initialValue={
                    serveInfo[
                      FormInfoConfigEnum
                        .NonstandardMonitorGatewayMaxConcurrencyNum
                    ] || ""
                  }
                  rules={[
                    {
                      required: true,
                      validator: basicNullVerify("请输入最大并发数"),
                    },
                    {
                      validator: basicNumberVerify(),
                    },
                    {
                      validator: basicExtremumVerify(),
                    },
                    {
                      validator:
                        NonstandardMonitorGatewayMaxConcurrencyChangeVerify,
                    },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[
                          FormInfoConfigEnum
                            .NonstandardMonitorGatewayMaxConcurrencyNum
                        ] || ""
                      }
                      placeholder="请输入最大并发数"
                    />
                  </div>
                </Form.Item>
                {/* 最大服务器数 */}
                <Form.Item
                  name={
                    FormInfoConfigEnum.NonstandardMonitorGatewayMaxServerNum
                  }
                  label="最大服务器数"
                  initialValue={
                    serveInfo[
                      FormInfoConfigEnum.NonstandardMonitorGatewayMaxServerNum
                    ] || ""
                  }
                  rules={[
                    {
                      required: true,
                      validator: basicNullVerify("请输入最大服务器数"),
                    },
                    {
                      validator: basicNumberVerify(),
                    },
                    {
                      validator: basicExtremumVerify(
                        restrictNonstandardMonitorGatewayMinServerNum
                      ),
                    },
                  ]}
                >
                  <div className={styles.form_item_input}>
                    <Input
                      defaultValue={
                        serveInfo[
                          FormInfoConfigEnum
                            .NonstandardMonitorGatewayMaxServerNum
                        ] || ""
                      }
                      placeholder="请输入最大服务器数"
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
      </Modal>
    </div>
  );
}
