import { FC, ReactElement } from "react";

import { CacheRoute, CacheSwitch } from "react-router-cache-route";
import { Route } from "react-router-dom";
// 首页列表
import Index from "./index/index";
// 服务详情
import ServeDetail from "./serveDetail/serveDetail";
// 服务添加
import ServeAdd from "./serveAdd/serveAdd";

const Private: FC = (): ReactElement => {
  return (
    <CacheSwitch>
      <Route path="/content/privateNew/serveAdd" component={ServeAdd} exact />
      <Route path="/content/privateNew/serveDetail" component={ServeDetail} />
      <CacheRoute path="/content/privateNew" component={Index} />
    </CacheSwitch>
  );
};

export default Private;
