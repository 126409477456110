import { FC, ReactElement, useEffect, useState } from "react";
import { Select, Button, message, Spin, Tabs, Modal } from "antd";
import { FormOutlined, PlusOutlined } from "@ant-design/icons";
import styles from "./serveDetail.less";
import axios from "../../../../axios/axios";
import { Option } from "../../../../axios/axios-types";
import { findPermissions } from "../../../../api/permiss";

import EditBasicInfo from "../../../../components/EditBasicInfo/editbasicinfo";
import AuthorizationMessage from "./AuthorizationMessage";
import DeploymentKnowledge from "./DeploymentKnowledge";
import InteractiveLog from "./InteractiveLog";
import DeveloperInformation from "./DeveloperInformation";

import { useLocation } from "react-router-dom";
import moment from "moment";

const { confirm } = Modal;

namespace Antd {
  export const { Option } = Select;
}

interface Result {
  data: {
    code: number;
    data: any;
    msg: string;
  };
}

/** 服务类型 */
enum ServeStatusEnum {
  "待授权",
  "已停用",
  "在使用",
  "快到期",
  "已到期",
}
//服务部署类型
enum ServeDeployType {
  never,
  "公有云",
  "混合云",
  "私有云",
}

const ServeDetail: FC = (): ReactElement => {
  const location: any = useLocation();
  // 客户信息加载
  const [loading, setLoading]: [boolean, Function] = useState(false);

  /** 基本信息 */
  // 公司名称
  const [companyName, setCompanyName] = useState("");
  // 电话
  const [phone, setPhone] = useState("");
  // 邮箱
  const [email, setEmail] = useState("");

  // 部署类型
  const [deployType, setDeployType] = useState(2);

  // 服务状态
  const [serveStatus, setServeStatus] = useState(0);
  // 服务状态颜色
  const serveStatusColorFn = (status: number) => {
    let oC = "";
    switch (ServeStatusEnum[status]) {
      case ServeStatusEnum[1]:
        oC = "#FC6061";
        break;
      case ServeStatusEnum[2]:
        oC = "#87CF63";
        break;
      case ServeStatusEnum[3]:
        oC = "#FABC53";
        break;
      case ServeStatusEnum[4]:
        oC = "#FC6061";
        break;
      default:
        break;
    }
    return oC;
  };
  // 服务状态切换
  const serveStatusToggleChangeFn = () => {
    // console.log("服务状态切换");
    // 服务正常时可进行冻结
    if (serveStatus === 2 || serveStatus === 3) {
      confirm({
        title: "冻结服务",
        content: "您确定要冻结该 License 吗?",
        okText: "确定",
        cancelText: "取消",
        closable: true,
        onOk() {
          console.log("OK 冻结服务");
          editServeStatusToggleFn(1);
        },
      });
      return;
    }

    // 服务冻结时可进行解冻
    confirm({
      title: "解锁服务",
      content: "您确定要解除对该 License 的冻结吗?",
      okText: "确定",
      cancelText: "取消",
      closable: true,
      onOk() {
        editServeStatusToggleFn(2);
      },
    });
  };

  // 修改服务状态
  const editServeStatusToggleFn = async (status: 0 | 1 | 2 | 3 | 4) => {
    const option: Option = {
      url: "/v2/primgr/updateLicStatus",
      data: {
        licenseId: location.state.licenseId || "",
        updateValue: status + "",
      },
      method: "POST",
    };
    setLoading(true);
    // 校验设备码
    const result: Result = await axios(option).finally(() => {
      setLoading(false);
    });
    if (result.data.code === 0) {
      message.success("操作成功");
      getServeInfoFn();
    }
  };

  // 备注
  const [remark, setRemark] = useState("");

  /** 获取详情 */
  const getServeInfoFn = async () => {
    // console.log("获取详情", location.state.licenseId);
    const option: Option = {
      url: "/v2/primgr/getDevpInfoDetail",
      data: {
        licenseId: location.state.licenseId || "",
      },
      method: "POST",
    };
    setLoading(true);
    // 校验设备码
    const result: Result = await axios(option).finally(() => {
      setLoading(false);
    });

    if (result.data.code === 0) {
      // console.log("data", result.data.data);
      const {
        companyName,
        phoneNumber,
        email,
        salerId,
        type,
        arrangeType,
        status,
        remark,
      } = result.data.data.devpInfo;
      setCompanyName(companyName);
      setPhone(phoneNumber);
      setEmail(email);

      // 销售
      setDefaultSales(salerId);
      setSalesId(salerId);
      // 客户类型
      setDefaultType(type);
      setRecordType(type);

      // 服务部署类型
      setDeployType(arrangeType);
      // 服务状态
      setServeStatus(status);
      // 备注
      setRemark(remark);

      // 设置授权服务
      const { devpInfo, inteCommunServicesList, rtcServiceList } =
        result.data.data;

      setAuthorizationBasicInfo({
        // 到期时间
        expirationTime: moment.unix(devpInfo.stopTs).format("YYYY-MM-DD"),
        // 申请时间
        enrolmentTime: moment.unix(devpInfo.applyTs).format("YYYY-MM-DD"),
        // 授权码
        authorizationCode: devpInfo.oem,
        // 机器码
        machineCode: devpInfo.equipmentCode,
        // 是否是服务上报
        isServiceReport: false,
      });

      setAuthorizationServeInfo(devpInfo);
      setServeIdList([...inteCommunServicesList, ...rtcServiceList]);
    }
  };
  useEffect(() => {
    getServeInfoFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 基本信息修改弹窗是否显示
  const [isModal, setModalVisible] = useState(false);
  // 基本信息修改类型 'name' 'tel' 'email'
  const [infoType, setInfoType] = useState("name");
  const [modalInfo, setModalInfo] = useState("");
  // 弹窗打开
  const editBasicInfo = (type: string) => {
    // 公司名称
    if (type === "name") {
      if (!findPermissions(42122))
        return message.warning("你当前无权限操作，请请添加对应权限");
      setModalInfo(companyName);
    }
    // 手机号码
    if (type === "tel") {
      if (!findPermissions(42124))
        return message.warning("你当前无权限操作，请请添加对应权限");
      setModalInfo(phone);
    }
    // 邮箱
    if (type === "email") {
      if (!findPermissions(42123))
        return message.warning("你当前无权限操作，请请添加对应权限");
      setModalInfo(email);
    }
    // 备注
    if (type === "remark") {
      if (!findPermissions(42126))
        return message.warning("你当前无权限操作，请请添加对应权限");
      setModalInfo(remark);
    }
    setInfoType(type);
    setModalVisible(true);
  };
  // 弹窗关闭
  const modalClose = (isUpdata: boolean) => {
    // console.log("是否需要更新信息", isUpdata);
    setModalVisible(false);
  };
  // 更新编辑的信息
  const modalUpdataInfo = (type: string, info: string) => {
    // console.log("更新信息", info);
    // 公司名称
    if (type === "name") {
      setCompanyName(info);
    }
    // 手机号码
    if (type === "tel") {
      setPhone(info);
    }
    // 邮箱
    if (type === "email") {
      setEmail(info);
    }
    // 备注
    if (type === "remark") {
      setRemark(info);
    }
  };
  /** 销售相关 */
  // 销售默认选中
  const [defaultSales, setDefaultSales]: [any, Function] = useState("");
  //
  const [salesId, setSalesId]: [string, Function] = useState("");
  // 销售列表
  const [arrAdminInfo, setArrAdminInfo]: [any[], Function] = useState([]);
  // 获取销售列表
  const getArrAdminInfo = async () => {
    const option: Option = {
      url: "/v2/primgr/getAllSaleUserList",
      method: "GET",
    };
    // 校验设备码
    const result: Result = await axios(option);
    if (result.data.code === 0) {
      const { arrAdminInfo } = result.data.data;
      setArrAdminInfo(arrAdminInfo);
      !defaultSales && setDefaultSales(arrAdminInfo[0].aid);
    }
  };
  useEffect(() => {
    getArrAdminInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // 销售选择并修改
  const arrAdminInfoSelectChange = async (value: string): Promise<void> => {
    if (!findPermissions(42121))
      return message.warning("你当前无权限操作，请请添加对应权限");

    const option: Option = {
      url: "/v2/primgr/updateUserSales",
      data: {
        licenseId: location.state.licenseId || "",
        salesId: value,
      },
      method: "POST",
    };
    const result: Result = await axios(option);
    if (result.data.code === 0) {
      message.success("销售修改成功");
      setSalesId(value);
      setDefaultSales(value);
    } else {
      message.error("销售修改失败");
      setDefaultSales(salesId);
    }
  };

  /** 客户类型 */
  // 客户类型默认
  const [defaultType, setDefaultType] = useState<number>(1);
  const [recordType, setRecordType] = useState<number>(1);
  // 客户类型变化
  const devpTypeSelectChange = async (value: number): Promise<void> => {
    // const data: any = {
    //   salesId: value,
    //   uId: basicInfo.uid,
    // };
    if (!findPermissions(42125))
      return message.warning("你当前无权限操作，请请添加对应权限");

    const option: Option = {
      url: "/v2/primgr/updateLicenseType",
      data: {
        licenseId: location.state.licenseId || "",
        updateValue: value + "",
      },
      method: "POST",
    };
    const result: Result = await axios(option);
    if (result.data.code === 0) {
      message.success("客户类型修改成功");
      setRecordType(value);
      setDefaultType(value);
      //   console.log("修改销售", result.data);
    } else {
      message.error("客户类型修改失败");
      setDefaultType(recordType);
    }
  };

  /**
   * 授权信息相关
   */
  // 授权基本信息
  const [authorizationBasicInfo, setAuthorizationBasicInfo] = useState<any>();
  // 授权服务信息
  const [authorizationServeInfo, setAuthorizationServeInfo] = useState<any>();
  // 授权服务Id
  const [serveIdList, setServeIdList] = useState<number[]>([]);

  // 更新授权信息
  const handleUpdateServeDetail = () => {
    console.log("授权服务变更，更新服务");
    getServeInfoFn();
  };
  return (
    <div className={styles.detail}>
      {/* 加载中状态 */}
      {loading && (
        <div className={styles.detail_loading}>
          <Spin size="large" />
        </div>
      )}

      <div className={styles.detail_conent}>
        {/* 基本信息 */}
        <div className={styles.basic_info}>
          <h3 className={styles.basic_info_h3}>基本信息</h3>
          <ul className={styles.basic_info_list}>
            {/* 公司名称 */}
            <li className={styles.basic_info_item}>
              <p className={styles.basic_info_item_label}>公司名称</p>
              <p className={styles.basic_info_item_conent}>{companyName}</p>
              <div
                className={styles.basic_info_item_icon}
                onClick={() => {
                  editBasicInfo("name");
                }}
              >
                <FormOutlined />
              </div>
            </li>
            <li className={styles.basic_info_item}>
              <p className={styles.basic_info_item_label}>邮箱</p>
              <p className={styles.basic_info_item_conent}>{email}</p>
              <div
                className={styles.basic_info_item_icon}
                onClick={() => {
                  editBasicInfo("email");
                }}
              >
                <FormOutlined />
              </div>
            </li>
            <li className={styles.basic_info_item}>
              <p className={styles.basic_info_item_label}>手机号</p>
              <p className={styles.basic_info_item_conent}>{phone}</p>
              <div
                className={styles.basic_info_item_icon}
                onClick={() => {
                  editBasicInfo("tel");
                }}
              >
                <FormOutlined />
              </div>
            </li>
            <li className={styles.basic_info_item}>
              <p className={styles.basic_info_item_label}>所属销售</p>
              <div className={styles.basic_info_item_conent}>
                <Select
                  value={defaultSales}
                  style={{ width: 200 }}
                  onChange={(value) => arrAdminInfoSelectChange(value)}
                >
                  {arrAdminInfo.map((item: any): ReactElement => {
                    return (
                      <Antd.Option key={item.aid} value={item.aid}>
                        {item.name}
                      </Antd.Option>
                    );
                  })}
                </Select>
              </div>
            </li>

            <li className={styles.basic_info_item}>
              <p className={styles.basic_info_item_label}>客户类型</p>
              <div className={styles.basic_info_item_conent}>
                <Select
                  value={defaultType}
                  style={{ width: 200 }}
                  onChange={(value) => devpTypeSelectChange(value)}
                >
                  <Antd.Option value={1}>正式期</Antd.Option>
                  <Antd.Option value={2}>测试期</Antd.Option>
                </Select>
              </div>
            </li>

            <li className={styles.basic_info_item}>
              <p className={styles.basic_info_item_label}>部署类型</p>
              <p className={styles.basic_info_item_conent}>
                {ServeDeployType[deployType]}
              </p>
            </li>

            <li className={styles.basic_info_item}>
              <p className={styles.basic_info_item_label}>服务状态</p>
              <p className={styles.basic_info_item_conent}>
                <span
                  style={{
                    color: serveStatusColorFn(serveStatus),
                  }}
                >
                  {ServeStatusEnum[serveStatus]}
                </span>
                {(serveStatus === 1 ||
                  serveStatus === 2 ||
                  serveStatus === 3) && (
                  <Button
                    style={{
                      marginLeft: "10px",
                    }}
                    type="primary"
                    size="middle"
                    onClick={serveStatusToggleChangeFn}
                  >
                    切换状态
                  </Button>
                )}
              </p>
            </li>

            {/* <li className={styles.basic_info_item}>
              <p className={styles.basic_info_item_label}>厂商</p>
              <p className={styles.basic_info_item_conent}>6666</p>
            </li> */}
          </ul>
          {/* 备注 */}
          <div className={styles.basic_info_remark}>
            <p className={styles.basic_info_item_label}>备注</p>
            <p className={styles.basic_info_item_conent}>
              {remark ? remark : "无"}
            </p>
            <Button
              type="primary"
              size="middle"
              icon={<PlusOutlined />}
              onClick={() => {
                editBasicInfo("remark");
              }}
            >
              添加备注
            </Button>
          </div>
        </div>

        {/* 详情tab切换 */}
        <Tabs defaultActiveKey="1" destroyInactiveTabPane>
          <Tabs.TabPane tab="授权信息" key="1">
            <AuthorizationMessage
              basicConfig={{
                licType: deployType,
                uid: authorizationServeInfo?.uId || "",
                licenseId: location.state.licenseId,
              }}
              basicInfo={authorizationBasicInfo}
              authorizationServe={authorizationServeInfo}
              authorizationServeId={serveIdList}
              onUpdate={handleUpdateServeDetail}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="开发者信息" key="4">
            <DeveloperInformation
              licenseId={location.state.licenseId}
              authorizationCode={authorizationBasicInfo?.authorizationCode}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="部署信息" key="2">
            <DeploymentKnowledge
              serveIdList={[106, 101, 107, 108, 102, 103, 201, 203]}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="交互日志" key="3">
            <InteractiveLog />
          </Tabs.TabPane>
        </Tabs>
      </div>

      {/* 基本信息修改 */}
      <EditBasicInfo
        isVisible={isModal}
        serveId={location.state.licenseId}
        infoType={infoType}
        infoRecord={modalInfo}
        close={(value: boolean) => {
          modalClose(value);
        }}
        updataInfo={(type: string, value: string) => {
          modalUpdataInfo(type, value);
        }}
      ></EditBasicInfo>
    </div>
  );
};

export default ServeDetail;
