/**
 * 授权信息
 */
import { CopyOutlined } from "@ant-design/icons";
import { Modal, Table, message, Button, Steps, Input } from "antd";
import { useEffect, useState } from "react";
import { Option as OptionInterface } from "../../../../axios/axios-types";
import axios from "../../../../axios/axios";
import moment from "moment";
import { findPermissions } from "../../../../api/permiss";
import { handleCopy } from "../../../../api";

import ServeCheck from "../../../../components/ServeCheck/servecheck";
import ServeCheckExhibition from "../../../../components/ServeCheck/serveexhibition";
import React from "react";

const { Column } = Table;
const { Step } = Steps;
const { Search } = Input;

interface ResultInterface {
  data: {
    code: number;
    data: any;
    msg: string;
  };
}

export default function AuthorizationMessage(customProps: {
  // 基础配置
  basicConfig: {
    licType: number;
    uid: string;
    licenseId: string;
  };

  // 基础信息
  basicInfo?: {
    // 到期时间
    expirationTime: string;
    // 申请时间
    enrolmentTime: string;
    // 授权码
    authorizationCode: string;
    // 机器码
    machineCode: string;
    // 是否是服务上报
    isServiceReport: boolean;
  };

  // 授权的基本服务
  authorizationServe?: any;
  // 授权的服务Id
  authorizationServeId?: number[];

  // 通知更新回调
  onUpdate?: (data?: any) => void;
}) {
  /**
   * 机器码(设备码)
   */
  // 本地操作变更机器码(默认无操作)
  // 无机器码手动进行提交时
  const submitMachineCodeFn = async (codeValue: string) => {
    if (!codeValue) return message.error("请输入机器码");
    // if (!findPermissions(42128))
    //   return message.warning("你当前无权限操作，请请添加对应权限");
    message.loading({
      content: "正在提交机器码",
      key: "submitMachineCode",
      duration: 0,
    });

    const option: OptionInterface = {
      url: "/v2/primgr/updateEquipmentCode",
      data: {
        licenseId: customProps.basicConfig.licenseId,
        updateValue: codeValue + '',
      },
      method: "POST",
    };
    const result: ResultInterface = await axios(option);
    const { code, msg, data } = result.data;
    if (code === 0) {
      message.destroy("submitMachineCode");
      message.success("机器码提交成功");
      // 通知更新
      customProps.onUpdate && customProps.onUpdate();
    } else {
      message.error(msg);
    }
  };

  /**
   * 授权码复制
   */
  const authorizationCopyFn = () => {
    handleCopy(customProps.basicInfo?.authorizationCode || "");
  };

  /** 下载 License(仅非服务上报) */
  const downloadLicenseFn = async () => {
    // if (!findPermissions(42128))
    //   return message.warning("你当前无权限操作，请请添加对应权限");
    const hideMessage = message.loading("文件下载中", 0, () => {
      message.success("文件下载成功");
    });
    /** 获取下载地址 */
    // /arcrm/v2/primgr/getLicenseUrl
    const getUrl: OptionInterface = {
      url: "/v2/primgr/getLicenseUrl",
      data: {
        licenseId: customProps.basicConfig.licenseId || "",
      },
      method: "POST",
    };
    const resultUrl: ResultInterface = await axios(getUrl);

    if (resultUrl.data.code === 0) {
      const fileUrl = resultUrl.data.data.filePath;
      //  /arcrm/v2/primgr/downloadFile
      const option: OptionInterface = {
        url: `/v2/primgr/downloadFile?filePath=${fileUrl}`,
        method: "GET",
        responseType: "arraybuffer",
      };
      axios(option).then((res) => {
        const fileName: string = `${moment().format("YYYY-MM-DD")}_License.txt`;
        const url: string = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/pdf;charset=utf-8" })
        );
        const a = document.createElement("a");
        a.download = fileName;
        a.href = url;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        hideMessage();
      });
    }
  };

  /** 操作记录 */
  const [isRecordModalVisible, setIsRecordModalVisible] = useState(false);
  const handleRecordModalVisible = () => {
    setIsRecordModalVisible(false);
  };
  const openRecordModalVisible = () => {
    if (!findPermissions(42126))
      return message.warning("你当前无权限操作，请请添加对应权限");
    setIsRecordModalVisible(true);
  };

  /** 授权服务编辑 */
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const handleEditModalVisible = () => {
    setIsEditModalVisible(false);
  };
  const openEditModalVisible = () => {
    if (!findPermissions(42127))
      return message.warning("你当前无权限操作，请请添加对应权限");
    setIsEditModalVisible(true);
  };
  // 通知更新
  const handleEditModalUpdate = () => {
    customProps.onUpdate && customProps.onUpdate();
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3>授权信息</h3>
        <div>
          <Button type="link" onClick={openRecordModalVisible}>
            操作记录
          </Button>
          <Button type="link" onClick={openEditModalVisible}>
            编辑
          </Button>
        </div>
      </div>

      {/* 相关服务时间 */}
      <div
        style={{
          fontSize: "14px",
          color: "#606060",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div>
          <div
            style={{
              marginBottom: "6px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                color: "#999999",
              }}
            >
              授权码：
            </span>
            <span>{customProps.basicInfo?.authorizationCode ? customProps.basicInfo?.authorizationCode : (<span
              style={{
                fontSize: "12px",
                color: "#999999",
              }}
            >
              请输入机器码进行生成
            </span>) }</span>
            {customProps.basicInfo?.authorizationCode && (
              <CopyOutlined
                style={{
                  fontSize: "20px",
                  marginLeft: "4px",
                  cursor: "pointer",
                }}
                title="复制"
                onClick={authorizationCopyFn}
              />
            )}
          </div>
          <div
            style={{
              marginBottom: "6px",
            }}
          >
            <span
              style={{
                color: "#999999",
              }}
            >
              到期时间：
            </span>
            {customProps.basicInfo?.expirationTime}
          </div>
          <div
            style={{
              marginBottom: "6px",
            }}
          >
            <span
              style={{
                color: "#999999",
              }}
            >
              申请时间：
            </span>
            {customProps.basicInfo?.enrolmentTime}
          </div>
          <div
            style={{
              marginBottom: "6px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                color: "#999999",
              }}
            >
              机器码：
            </span>
            {/* 机器码存在/不存在 */}
            {customProps.basicInfo?.machineCode ? (
              <div>
                <span>{customProps.basicInfo?.machineCode}</span>
                {/* 非服务上报可下载 License */}
                {!customProps.basicInfo?.isServiceReport && (
                  <Button type="link" onClick={downloadLicenseFn}>
                    下载 License
                  </Button>
                )}
              </div>
            ) : (
              <div>
                <Search
                  placeholder="请输入机器码"
                  enterButton="提交"
                  onSearch={submitMachineCodeFn}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* 已授权的服务 */}
      <div
        style={{
          marginTop: "20px",
          paddingLeft: "20px",
          paddingRight: "20px",
          height: "400px",
        }}
      >
        <ServeCheckExhibition
          expirTime={
            customProps.basicInfo?.expirationTime
              ? moment(customProps.basicInfo?.expirationTime).unix()
              : undefined
          }
          basicConfig={customProps.authorizationServe}
          configuredServeList={customProps.authorizationServeId}
        />
      </div>

      {/* 操作记录 */}
      {isRecordModalVisible && (
        <OperatingRecordModal
          licenseId={customProps.basicConfig.licenseId || ""}
          onVisibleChange={handleRecordModalVisible}
        />
      )}
      {/* 授权服务编辑 */}
      {isEditModalVisible && (
        <AuthorizationServeEdit
          basicConfig={customProps.basicConfig}
          defaultConfig={{
            expirTime: customProps.basicInfo?.expirationTime
              ? moment(customProps.basicInfo?.expirationTime).unix()
              : 0,
            basicConfig: customProps.authorizationServe || {},
            configuredServeList: customProps.authorizationServeId || [],
          }}
          onVisibleChange={handleEditModalVisible}
          onUpdate={handleEditModalUpdate}
        />
      )}
    </div>
  );
}

/**
 * 操作记录
 */
function OperatingRecordModal(customProps: {
  licenseId: string;
  onVisibleChange?: (visible: boolean) => void;
}) {
  /**
   * 操作记录相关业务
   */

  const [isRecord, setIsRecord] = useState(false);
  const [recordLoading, setRecordLoading] = useState(false);
  const [recordOptin, setRecordOptin] = useState([]);
  const [currentRecord, setCurrentRecord]: [number, Function] = useState(1);
  const [pageSizeRecord, setPageSizeRecord]: [number, Function] = useState(10);
  const [totalRecord, setTotalRecord]: [number, Function] = useState(0);
  // 获取操作记录
  const getRecordModal = async () => {
    setRecordLoading(true);
    // /arcrm/v2/primgr/getOperationRecords
    const option: OptionInterface = {
      url: "/v2/primgr/getOperationRecords",
      data: {
        licenseId: customProps.licenseId || "",
        pageNum: currentRecord,
        pageSize: pageSizeRecord,
      },
      method: "POST",
    };
    // 校验设备码
    const result: ResultInterface = await axios(option).finally(() => {
      setRecordLoading(false);
    });

    if (result.data.code === 0) {
      setTotalRecord(result.data.data.totalNumber);
      setRecordOptin(
        result.data.data.list
          ? result.data.data.list.map((info: { createTs: number }) => {
              return Object.assign(info, {
                startTime: moment
                  .unix(info.createTs)
                  .format("YYYY-MM-DD HH:mm:ss"),
              });
            })
          : []
      );
    } else {
      message.error(result.data?.msg || "");
    }
  };

  // 弹窗关闭
  const closeRecordModal = () => {
    setIsRecord(false);
    customProps.onVisibleChange && customProps.onVisibleChange(false);
  };

  // 操作记录分页变化
  const currentRecordChange = (pagination: any) => {
    setCurrentRecord(pagination.current);
    setPageSizeRecord(pagination.pageSize);
    getRecordModal();
  };

  useEffect(() => {
    getRecordModal();
    setIsRecord(true);
  }, []);
  return (
    <Modal
      visible={isRecord}
      title="操作记录"
      width="800px"
      footer={null}
      onCancel={closeRecordModal}
    >
      <Table
        onChange={(pagination) => currentRecordChange(pagination)}
        loading={recordLoading}
        pagination={{
          defaultCurrent: 1,
          current: currentRecord,
          showSizeChanger: true,
          pageSize: pageSizeRecord,
          total: totalRecord,
        }}
        rowKey={() => `${Math.floor(Math.random() * 123333)}`}
        sticky={true}
        scroll={{ y: 300 }}
        dataSource={recordOptin}
      >
        <Column align="center" title="操作时间" dataIndex="startTime" />
        <Column align="center" title="操作用户" dataIndex="admName" />
        <Column align="center" title="操作内容" dataIndex="operationRecords" />
      </Table>
    </Modal>
  );
}

/**
 * 授权服务编辑
 */
function AuthorizationServeEdit(customProps: {
  // 基础配置
  basicConfig: {
    licType: number;
    uid: string;
    licenseId: string;
  };

  // 默认配置
  defaultConfig?: {
    // 过期时间
    expirTime: number;
    // 基本信息配置
    basicConfig: any;
    // 已配置的服务列表
    configuredServeList: number[];
  };

  // 显示回调
  onVisibleChange?: (visible: boolean) => void;
  // 更新回调
  onUpdate?: () => void;
}) {
  const [isModal, setIsModal] = useState(false);
  // 弹窗关闭
  const closeRecordModal = () => {
    setIsModal(false);
    customProps.onVisibleChange && customProps.onVisibleChange(false);
  };

  /**
   * 步骤
   */
  const [stepNum, setStepNum] = useState(0);

  // 预览的基本服务信息
  const [previewServeBasicInfo, setPreviewServeBasicInfo] = useState<any>();
  // 服务过期时间
  const [previewServeExpirTime, setPreviewServeExpirTime] = useState(0);
  // 选择的服务Id
  const [previewServeIdList, setPreviewServeIdList] = useState<number[]>();

  /**
   * 初始设置
   */
  useEffect(() => {
    setIsModal(true);
    if (customProps.defaultConfig) {
      const { expirTime, basicConfig, configuredServeList } =
        customProps.defaultConfig;

      setPreviewServeBasicInfo(basicConfig);
      setPreviewServeExpirTime(expirTime);
      setPreviewServeIdList(configuredServeList);
    }

    // setPreviewServeBasicInfo({
    //   rtcConcurrencyNum: 20,
    //   rtcMaxServerNum: 2,
    //   rtmMaxServerNum: 2,
    // });
    // setPreviewServeExpirTime(1712678399);
    // setPreviewServeIdList([101, 106]);
  }, []);

  /**
   * 步骤一相关业务
   */
  const refServeCheckCustom = React.createRef();
  // 下一步校验
  const nextStepVerfiyFn = async () => {
    try {
      // 获取服务选择
      const { expirTime, basicInfo, checkoutList } = await (
        refServeCheckCustom.current as any
      )?.getFormData();
      if (checkoutList && checkoutList.length === 0) {
        return message.error("请选择需要授权的服务");
      }

      setPreviewServeExpirTime(expirTime);
      setPreviewServeBasicInfo(basicInfo);
      setPreviewServeIdList(checkoutList);

      setStepNum(1);
    } catch (error) {
      console.log("error", error);
    }
  };
  /**
   * 修改提交
   */
  const submitServeEditInfoFn = async () => {
    // /arcrm/v2/primgr/updateLicenseService
    const oDataInfo = {
      ...customProps.basicConfig,
      arrSType: previewServeIdList,
      expirationTime: previewServeExpirTime,
      ...previewServeBasicInfo,
    };
    const option: OptionInterface = {
      url: "/v2/primgr/updateLicenseService",
      data: oDataInfo,
      method: "POST",
    };
    // 校验设备码
    const result: ResultInterface = await axios(option);
    if (result.data.code === 0) {
      message.success("服务授权修改成功");
      customProps.onUpdate && customProps.onUpdate();
      closeRecordModal();
      // console.log("history", history);
    } else {
      message.error("服务授权失败");
    }
  };
  return (
    <Modal
      visible={isModal}
      title="修改授权服务"
      width="800px"
      footer={null}
      onCancel={closeRecordModal}
    >
      <div>
        <div
          style={{
            paddingLeft: "80px",
            paddingRight: "80px",
          }}
        >
          <Steps current={stepNum} type="navigation" size="small">
            <Step title="修改授权" />
            <Step title="预览" />
          </Steps>
        </div>
        <div style={{ marginTop: "20px" }}>
          {/* 步骤一 */}
          {stepNum === 0 && (
            <div>
              <div style={{ height: "400px" }}>
                <ServeCheck
                  onRef={refServeCheckCustom}
                  defaultConfig={{
                    expirTime: previewServeExpirTime,
                    basicConfig: previewServeBasicInfo,
                    configuredServeList: previewServeIdList,
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingTop: "20px",
                }}
              >
                <Button size="large" onClick={closeRecordModal}>
                  取消
                </Button>
                <Button
                  style={{
                    marginLeft: "20px",
                  }}
                  size="large"
                  type="primary"
                  onClick={nextStepVerfiyFn}
                >
                  下一步
                </Button>
              </div>
            </div>
          )}
          {/* 步骤二 */}
          {stepNum === 1 && (
            <div>
              <div style={{ height: "400px" }}>
                <ServeCheckExhibition
                  expirTime={previewServeExpirTime}
                  basicConfig={previewServeBasicInfo}
                  configuredServeList={previewServeIdList}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingTop: "20px",
                }}
              >
                <Button size="large" onClick={() => setStepNum(0)}>
                  上一步
                </Button>
                <Button
                  style={{
                    marginLeft: "20px",
                  }}
                  size="large"
                  type="primary"
                  onClick={submitServeEditInfoFn}
                >
                  完成
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
