export type ServeListType = "anyRTC" | "RTC" | "Talk";

/**
 * 服务选择组件所需
 */
export interface ServeCheckProps {
  onRef?: any;
  // 是否编辑
  isEdit?: boolean;
  // 默认配置
  defaultConfig?: {
    // 过期时间
    expirTime?: number;
    // 基本信息配置
    basicConfig?: any;
    // 已配置的服务列表
    configuredServeList?: number[];
  };
}

/**
 * 服务选择预览组件所需
 */
export interface ServeCheckExhibitionInterface {
  // 过期时间
  expirTime?: number;
  // 基本信息配置
  basicConfig?: any;
  // 已配置的服务列表
  configuredServeList?: number[];
}

export interface FormInfoConfig {
  // 过期时间
  expirTime?: number;

  // 音视频并发数
  RTCConcurrencyConcurrencyNum?: number | string;
  // 音视频最大服务器数量
  RTCMaxServerNum?: number | string;

  // RTM 最大服务器数量
  RTMMaxServerNum?: number | string;
  // 互动白板最大服务器数量
  WhiteBoardMaxServerNum?: number | string;
  // 实时对讲最大服务器数量
  VoiceMaxServerNum?: number | string;

  /**
   * RTC相关服务
   */
  // RTC 云端录制最大服务器数量
  RTCCloudRecordMaxServerNum?: number | string;
  // RTC 小程序最大服务器数量
  RTCMiniMaxServerNum?: number | string;
  // RTC 旁路推流最大服务器数量
  RTCPushMaxServerNum?: number | string;
  // RTC 输入在线媒体流最大服务器数量
  RTCOnlineMediaMaxServerNum?: number | string;

  /**
   * 融合通讯相关服务
   */

  // 业务授权-总账号数
  CharacterLicensingAccountAllNum?: number | string;
  // 业务授权-调度员数
  CharacterLicensingYardmanNum?: number | string;
  // 业务授权-管理员数
  CharacterLicensingAdminNum?: number | string;
  // 业务授权-会议录制并发数
  CharacterLicensingMeetRecordConcurrencyNum?: number | string;
  // 业务授权-通话录制并发数
  CharacterLicensingCallRecordConcurrencyNum?: number | string;
  // 业务授权-最大频道成员数
  CharacterLicensingMaxChannelMemberNum?: number | string;
  // 业务授权-最大服务器数
  CharacterLicensingMaxServerNum?: number | string;

  // 国标下联网关-最大通道数
  DownLinkGatewayMaxChannelNum?: number | string;
  // 国标下联网关-最大并发数
  DownLinkGatewayMaxConcurrencyNum?: number | string;
  // 国标下联网关-最大服务器数
  DownLinkGatewayMaxServerNum?: number | string;

  // 国标上联网关-最大通道数
  ConnectedGatewayMaxChannelNum?: number | string;
  // 国标上联网关-最大并发数
  ConnectedGatewayMaxConcurrencyNum?: number | string;
  // 国标上联网关-最大平台数
  ConnectedGatewayMaxPlatformNum?: number | string;
  // 国标上联网关-最大服务器数
  ConnectedGatewayMaxServerNum?: number | string;

  // 融合会议网关-最大会议并发数
  MeetGatewayMaxConcurrencyNum?: number | string;
  // 融合会议网关-最大服务器数
  MeetGatewayMaxServerNum?: number | string;

  // H.323连接器网关-最大设备数量
  H323GatewayMaxDeviceNum?: number | string;
  // H.323连接器网关-最大呼出并发数
  H323GatewayMaxExpirationConcurrencyNum?: number | string;
  // H.323连接器网关-最大呼入并发数
  H323GatewayMaxCallinConcurrencyNum?: number | string;
  // H.323连接器网关-最大服务器数
  H323GatewayMaxServerNum?: number | string;

  // 电话会议网关-最大并发数
  TeleconferencingGatewayMaxConcurrencyNum?: number | string;
  // 电话会议网关-最大服务器数
  TeleconferencingGatewayMaxServerNum?: number | string;

  // RTC2SIP 网关-最大并发数
  RTC2SIPGatewayMaxConcurrencyNum?: number | string;
  // RTC2SIP 网关-最大服务器数
  RTC2SIPGatewayMaxServerNum?: number | string;

  // 非标监控网关-最大通道数
  NonstandardMonitorGatewayMaxChannelNum?: number | string;
  // 非标监控网关-最大并发数
  NonstandardMonitorGatewayMaxConcurrencyNum?: number | string;
  // 非标监控网关-最大服务器数
  NonstandardMonitorGatewayMaxServerNum?: number | string;
}

export enum FormInfoConfigEnum {
  // 音视频并发数
  RTCConcurrencyConcurrencyNum = "RTCConcurrencyConcurrencyNum",
  // 音视频最大服务器数量
  RTCMaxServerNum = "RTCMaxServerNum",

  // RTM 最大服务器数量
  RTMMaxServerNum = "RTMMaxServerNum",
  // 互动白板最大服务器数量
  WhiteBoardMaxServerNum = "WhiteBoardMaxServerNum",
  // 实时对讲最大服务器数量
  VoiceMaxServerNum = "VoiceMaxServerNum",

  /**
   * RTC相关服务
   */
  // RTC 云端录制最大服务器数量
  RTCCloudRecordMaxServerNum = "RTCCloudRecordMaxServerNum",
  // RTC 小程序最大服务器数量
  RTCMiniMaxServerNum = "RTCMiniMaxServerNum",
  // RTC 旁路推流最大服务器数量
  RTCPushMaxServerNum = "RTCPushMaxServerNum",
  // RTC 输入在线媒体流最大服务器数量
  RTCOnlineMediaMaxServerNum = "RTCOnlineMediaMaxServerNum",

  /**
   * 融合通讯相关服务
   */

  // 业务授权-总账号数
  CharacterLicensingAccountAllNum = "CharacterLicensingAccountAllNum",
  // 业务授权-调度员数
  CharacterLicensingYardmanNum = "CharacterLicensingYardmanNum",
  // 业务授权-管理员数
  CharacterLicensingAdminNum = "CharacterLicensingAdminNum",
  // 业务授权-会议录制并发数
  CharacterLicensingMeetRecordConcurrencyNum = "CharacterLicensingMeetRecordConcurrencyNum",
  // 业务授权-通话录制并发数
  CharacterLicensingCallRecordConcurrencyNum = "CharacterLicensingCallRecordConcurrencyNum",
  // 业务授权-最大频道成员数
  CharacterLicensingMaxChannelMemberNum = "CharacterLicensingMaxChannelMemberNum",
  // 业务授权-最大服务器数
  CharacterLicensingMaxServerNum = "CharacterLicensingMaxServerNum",

  // 国标下联网关-最大通道数
  DownLinkGatewayMaxChannelNum = "DownLinkGatewayMaxChannelNum",
  // 国标下联网关-最大并发数
  DownLinkGatewayMaxConcurrencyNum = "DownLinkGatewayMaxConcurrencyNum",
  // 国标下联网关-最大服务器数
  DownLinkGatewayMaxServerNum = "DownLinkGatewayMaxServerNum",

  // 国标上联网关-最大通道数
  ConnectedGatewayMaxChannelNum = "ConnectedGatewayMaxChannelNum",
  // 国标上联网关-最大并发数
  ConnectedGatewayMaxConcurrencyNum = "ConnectedGatewayMaxConcurrencyNum",
  // 国标上联网关-最大平台数
  ConnectedGatewayMaxPlatformNum = "ConnectedGatewayMaxPlatformNum",
  // 国标上联网关-最大服务器数
  ConnectedGatewayMaxServerNum = "ConnectedGatewayMaxServerNum",

  // 融合会议网关-最大会议并发数
  MeetGatewayMaxConcurrencyNum = "MeetGatewayMaxConcurrencyNum",
  // 融合会议网关-最大服务器数
  MeetGatewayMaxServerNum = "MeetGatewayMaxServerNum",

  // H.323连接器网关-最大设备数量
  H323GatewayMaxDeviceNum = "H323GatewayMaxDeviceNum",
  // H.323连接器网关-最大呼出并发数
  H323GatewayMaxExpirationConcurrencyNum = "H323GatewayMaxExpirationConcurrencyNum",
  // H.323连接器网关-最大呼入并发数
  H323GatewayMaxCallinConcurrencyNum = "H323GatewayMaxCallinConcurrencyNum",
  // H.323连接器网关-最大服务器数
  H323GatewayMaxServerNum = "H323GatewayMaxServerNum",

  // 电话会议网关-最大并发数
  TeleconferencingGatewayMaxConcurrencyNum = "TeleconferencingGatewayMaxConcurrencyNum",
  // 电话会议网关-最大服务器数
  TeleconferencingGatewayMaxServerNum = "TeleconferencingGatewayMaxServerNum",

  // RTC2SIP 网关-最大并发数
  RTC2SIPGatewayMaxConcurrencyNum = "RTC2SIPGatewayMaxConcurrencyNum",
  // RTC2SIP 网关-最大服务器数
  RTC2SIPGatewayMaxServerNum = "RTC2SIPGatewayMaxServerNum",

  // 非标监控网关-最大通道数
  NonstandardMonitorGatewayMaxChannelNum = "NonstandardMonitorGatewayMaxChannelNum",
  // 非标监控网关-最大并发数
  NonstandardMonitorGatewayMaxConcurrencyNum = "NonstandardMonitorGatewayMaxConcurrencyNum",
  // 非标监控网关-最大服务器数
  NonstandardMonitorGatewayMaxServerNum = "NonstandardMonitorGatewayMaxServerNum",
}
