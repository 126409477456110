/**
 * 部署信息
 */

import { useEffect, useState } from "react";
import {
  ANYRTC_SERVE_OPTIONS,
  RTC_SERVE_OPTIONS,
  TALK_SERVE_OPTIONS,
} from "../../../../components/ServeCheck/serveconfig";
import type { Option as OptionInterface } from "../../../../axios/axios-types";

import { Tabs, Table, message, Select, Input, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import axios from "../../../../axios/axios";
import moment from "moment";

const { Search } = Input;
const { Column } = Table;

interface ResultInterface {
  data: {
    code: number;
    data: any;
    msg: string;
  };
}

/** 部署信息基本服务 */
export default function DeploymentKnowledge(customProps: {
  serveIdList: number[];
}) {
  const [anyRTCServeList, setAnyRTCServeList] = useState<
    { keyValue: string; tabName: string }[]
  >([]);
  const [talkServeLis, setTalkServeLis] = useState<
    { keyValue: string; tabName: string }[]
  >([]);

  useEffect(() => {
    const { anyRTCServeType, talkServeType } = basiesServeType(
      customProps.serveIdList
    );
    if (anyRTCServeType.length > 0) {
      setAnyRTCServeList([...anyRTCServeType]);
    }
    if (talkServeType.length > 0) {
      setTalkServeLis([...talkServeType]);
    }
  }, []);
  return (
    <Tabs type="card" defaultActiveKey="item-1" destroyInactiveTabPane>
      {anyRTCServeList.length > 0 && (
        <Tabs.TabPane tab="anyRTC服务" key="item-1">
          <BasicsServeType serveTypeList={anyRTCServeList} />
        </Tabs.TabPane>
      )}
      {talkServeLis.length > 0 && (
        <Tabs.TabPane tab="融合通信服务" key="item-2">
          <BasicsServeType serveTypeList={talkServeLis} />
        </Tabs.TabPane>
      )}
    </Tabs>
  );
}

/**
 * 服务分类
 */
function BasicsServeType(customProps: {
  serveTypeList?: { keyValue: string; tabName: string }[];
}) {
  /**
   * tab栏切换
   */
  const [activeKey, setActiveKey] = useState<string>();
  const handleTabChangeFn = (activeKey: any) => {
    console.log("当前服务类型", activeKey);
    setActiveKey(activeKey);
    // 清除表格相关数据
    clearTableRecordFn();
    // 获取新的服务列表
    getSelectServeTypeListFn();
  };

  /**
   * 条件过滤
   */
  // 当前服务类型
  const [currentSelectServeType, setCurrentSelectServeType] =
    useState<string>("");
  // 服务类型列表
  const [selectServeTypeList, setSelectServeTypeList] = useState<
    { value: string; label: string }[]
  >([]);
  // 服务器列表发生变化时更新当前服务类型
  useEffect(() => {
    console.log("服务器列表发生变化时更新当前服务类型", selectServeTypeList);
    if (selectServeTypeList.length > 0) {
      // 默认选择第一个服务类型
      setCurrentSelectServeType(selectServeTypeList[0].value);
      // 更新表格数据
      getTableDataListFn();
    }
  }, [selectServeTypeList]);
  // 选择服务类型
  const handleSelectServeTypeChangeFn = (value: string) => {
    console.log("服务类型", value);
    setCurrentSelectServeType(value);
    // 清除表格相关操作记录
    clearTableRecordFn();
    getTableDataListFn();
  };
  // 获取对应授权服务的服务类型
  const getSelectServeTypeListFn = () => {
    console.log("获取对应授权服务的服务类型");
    // 设置存在的服务类型
    setSelectServeTypeList([
      {
        value: "jack",
        label: "Jack",
      },
      {
        value: "lucy",
        label: "Lucy",
      },
      {
        value: "disabled",
        label: "Disabled",
      },
      {
        value: "Yiminghe",
        label: "yiminghe",
      },
    ]);
  };

  // 服务器 IP
  const [searchIp, setSearchIp] = useState("");
  const handleSearchServeIpChange = (value: string) => {
    setSearchIp(value);
    // 清除表格相关操作记录
    clearTableRecordFn();
    // 更新表格
    getTableDataListFn();
  };

  /** 初始设置 */
  useEffect(() => {
    console.log("初始设置", customProps.serveTypeList);
    // 设置初始服务类型
    if (customProps.serveTypeList && customProps.serveTypeList.length > 0) {
      setActiveKey(customProps.serveTypeList[0].keyValue);

      getSelectServeTypeListFn();
    }
  }, []);

  /**
   * 表格基本信息
   */
  const [tableLoading, setTableLoading] = useState(false);
  const [tableDataList, settableDataList] = useState<any[]>([]);
  const [currentRecord, setCurrentRecord]: [number, Function] = useState(1);
  const [pageSizeRecord, setPageSizeRecord]: [number, Function] = useState(10);
  const [totalRecord, setTotalRecord]: [number, Function] = useState(0);

  // 操作记录分页变化
  const handleCurrentRecordChange = (pagination: any) => {
    setCurrentRecord(pagination.current);
    setPageSizeRecord(pagination.pageSize);
    getTableDataListFn();
  };

  // 表格信息获取
  const getTableDataListFn = async () => {
    console.log("表格信息获取");
    settableDataList([
      {
        startTime: "2024-03-02 23:00:00",
      },
    ]);
    setTotalRecord(1);
    // setTableLoading(true);
    // const option: OptionInterface = {
    //   url: "/v2/primgr/getOperationRecords",
    //   data: {
    //     licenseId: "",
    //     pageNum: currentRecord,
    //     pageSize: pageSizeRecord,
    //   },
    //   method: "POST",
    // };
    // // 校验设备码
    // const result: ResultInterface = await axios(option).finally(() => {
    //   setTableLoading(false);
    // });
    // if (result.data.code === 0) {
    //   const { list, totalNumber } = result.data.data;
    //   if (list && totalNumber > 0) {
    //     settableDataList(
    //       list.map((info: { createTs: number }) => {
    //         return {
    //           ...info,
    //           startTime:
    //             info.createTs > 0
    //               ? moment.unix(info.createTs).format("YYYY-MM-DD HH:mm:ss")
    //               : "--",
    //         };
    //       })
    //     );
    //   } else {
    //     settableDataList([]);
    //     setTotalRecord(0);
    //   }
    // } else {
    //   message.error(result.data?.msg || "");
    // }
  };

  // 切换时清除表格当前操作记录
  const clearTableRecordFn = () => {
    settableDataList([]);
    setCurrentRecord(1);
    setPageSizeRecord(10);
    setTotalRecord(0);
  };
  return (
    <Tabs destroyInactiveTabPane onChange={handleTabChangeFn}>
      {customProps.serveTypeList &&
        customProps.serveTypeList.map((item) => {
          return (
            <Tabs.TabPane tab={item.tabName} key={item.keyValue}>
              <div>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '20px'
                }}>
                  <div style={{
                    marginRight: '20px'
                  }}>
                    <span style={{
                      fontSize: '12px',
                      color: '#b3b3b3',
                      marginRight: '10px'
                    }}>服务类型</span>
                    <Select
                      value={currentSelectServeType}
                      onChange={handleSelectServeTypeChangeFn}
                      style={{ width: 120 }}
                      options={selectServeTypeList}
                    ></Select>
                  </div>

                  <Search
                    placeholder="请输入服务器 IP"
                    allowClear
                    onSearch={handleSearchServeIpChange}
                    style={{ width: 200 }}
                  />
                </div>
                {/* 表格数据 */}
                <Table
                  onChange={(pagination) =>
                    handleCurrentRecordChange(pagination)
                  }
                  loading={tableLoading}
                  pagination={{
                    defaultCurrent: 1,
                    current: currentRecord,
                    showSizeChanger: true,
                    pageSize: pageSizeRecord,
                    total: totalRecord,
                  }}
                  rowKey={() => `${Math.floor(Math.random() * 123333)}`}
                  sticky={true}
                  dataSource={tableDataList}
                >
                  <Column align="center" title="设备码" dataIndex="startTime" />
                  <Column
                    align="center"
                    title="IP 以及其他信息"
                    dataIndex="startTime"
                    render={(_: any, record: any) => (
                      <div>
                        <span
                          style={{
                            marginRight: "4px",
                          }}
                        >
                          IP 以及其他信息
                        </span>
                        <Tooltip
                          title={() => {
                            return (
                              <ul
                                style={{
                                  listStyle: "none",
                                  padding: 0,
                                  fontSize: "12px",
                                }}
                              >
                                <li>系统类型：</li>
                                <li>系统版本：</li>
                                <li>Cpus：</li>
                                <li>Disk：</li>
                                <li>Memory：</li>
                                <li>MAC：</li>
                                <li>IP：</li>
                                <li>Architecture：</li>
                              </ul>
                            );
                          }}
                        >
                          <InfoCircleOutlined
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        </Tooltip>
                      </div>
                    )}
                  />

                  <Column
                    align="center"
                    title="服务类型"
                    dataIndex="startTime"
                  />
                  <Column
                    align="center"
                    title="服务版本"
                    dataIndex="startTime"
                  />
                  <Column align="center" title="日期" dataIndex="startTime" />
                </Table>
              </div>
            </Tabs.TabPane>
          );
        })}
    </Tabs>
  );
}

/**
 * 判断服务类型(anyRTC、融合通讯)
 */
const basiesServeType = (serveIdList: number[]) => {
  const anyRTCServeType: { keyValue: string; tabName: string }[] = [];
  const talkServeType: { keyValue: string; tabName: string }[] = [];

  // RTC 服务
  const oRTC = RTC_SERVE_OPTIONS.filter((item) =>
    serveIdList.includes(item.value)
  );
  // anyRTC服务
  const oAnyRTC = ANYRTC_SERVE_OPTIONS.filter((item) =>
    serveIdList.includes(item.value)
  );
  // 对讲服务
  const oTalk = TALK_SERVE_OPTIONS.filter((item) =>
    serveIdList.includes(item.value)
  );

  // 存在RTC服务
  if (oRTC.length > 0) {
    // 添加 RTC 后在添加 anyRTC 服务
    oAnyRTC.forEach((item) => {
      anyRTCServeType.push({
        keyValue: item.value + "",
        tabName: item.label,
      });
      if (item.label === "RTC") {
        oRTC.forEach((child) => {
          anyRTCServeType.push({
            keyValue: child.value + "",
            tabName: child.label,
          });
        });
      }
    });
  } else {
    oAnyRTC.forEach((item) => {
      anyRTCServeType.push({
        keyValue: item.value + "",
        tabName: item.label,
      });
    });
  }

  oTalk.forEach((item) => {
    talkServeType.push({
      keyValue: item.value + "",
      tabName: item.label,
    });
  });

  return {
    anyRTCServeType,
    talkServeType,
  };
};
