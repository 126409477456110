/**
 * 开发者信息
 */

import { Button, Empty, Modal, Select, message } from "antd";
import {
  CopyOutlined,
  PlusSquareOutlined,
  FormOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import { handleCopy } from "../../../../api";
import { useEffect, useState } from "react";
import axios from "../../../../axios/axios";
import moment from "moment";
import { Option as OptionInterface } from "../../../../axios/axios-types";

import DeveloperInformationStyle from "./DeveloperInformation.less";

const { confirm } = Modal;

interface ResultInterface {
  data: {
    code: number;
    data: any;
    msg: string;
  };
}

// 应用信息
interface AppInfoInterface {
  // 应用ID
  appId: string;
  // 应用证书
  appCertificate: string;
  // 用于更新，删除的id
  arAppidLicenseId?: string;
}

export default function DeveloperInformation(customProps: {
  licenseId: string;
  // 授权码
  authorizationCode?: string;
}) {
  // Customer 信息
  const [customerInfo, setCustomerInfo] = useState<{
    customerId: string;
    customerCertificate: string;
  }>();

  // 应用信息
  const [appInfoList, setAppInfoList] = useState<AppInfoInterface[]>([]);

  // 获取应用信息
  const getAppPortInfoFn = async () => {
    const option: OptionInterface = {
      url: "/v2/primgr/getLicenseAppInfo",
      data: {
        licenseId: customProps.licenseId || "",
      },
      method: "POST",
    };
    const result: ResultInterface = await axios(option);
    if (result.data.code === 0) {
      if (!result.data.data) return message.error("服务返回 null");
      const { customerCertificate, customerId, list } = result.data.data;
      setCustomerInfo({
        customerId,
        customerCertificate,
      });
      setAppInfoList(list || []);
    }
  };

  // 初始获取开发者信息
  useEffect(() => {
    getAppPortInfoFn();
  }, [customProps]);

  /**
   * 添加/编辑弹窗
   */
  const [isModalOpen, setIsModalOpen] = useState(false);
  // 弹窗类型 添加/编辑
  const [modalType, setModalType] = useState<"add" | "edit">("add");
  // 可选的appId列表
  const [appIdOptions, setAppIdOptions] = useState<AppInfoInterface[]>([]);

  // 当前选择的appId
  const [currentAppId, setCurrentAppId] = useState<string>("");
  // 当前选择的 appCertificate
  const [currentAppCertificate, setCurrentAppCertificate] =
    useState<string>("");
  // 编辑修改时记录的 arAppidLicenseId
  const [currentAppIdLicenseId, setCurrentAppIdLicenseId] =
    useState<string>("");

  // 获取弹窗下的appId列表
  const getModalAppListFn = async () => {
    // 查询列表
    const option: OptionInterface = {
      url: "/v2/primgr/getLicenseAllAppInfo",
      data: {
        licenseId: customProps.licenseId || "",
      },
      method: "POST",
    };
    const result: ResultInterface = await axios(option);
    if (result.data.code === 0) {
      if (!result.data.data) return message.error("服务返回 null");
      const { list } = result.data.data;
      if (list.length > 0) {
        console.log("list", list);

        // 设置默认选择
        setCurrentAppId(list[0].appId || "");
        setCurrentAppCertificate(list[0].appCertificate || "");
      }
      setAppIdOptions(list || []);
    }
  };

  const handleSelectChangeFn = (value: string) => {
    setCurrentAppId(value);
    const oFind = appIdOptions.filter((item) => item.appId === value);
    if (oFind.length > 0) {
      setCurrentAppCertificate(oFind[0].appCertificate);
    }
  };

  // 弹窗取消
  const handleModalCancelFn = () => {
    setCurrentAppId("");
    setAppIdOptions([]);
    setCurrentAppCertificate("");
    setCurrentAppIdLicenseId("");

    setIsModalOpen(false);
  };
  // 弹窗确认
  const handleModalConfirmFn = async () => {
    if (appIdOptions.length === 0) return handleModalCancelFn();
    // console.log("弹窗确认", currentAppId, currentAppIdLicenseId);
    const oResult: ResultInterface = await axios(
      modalType === "add"
        ? {
            url: "/v2/primgr/addLicenseAppId",
            data: {
              licenseId: customProps.licenseId || "",
              appId: currentAppId,
            },
            method: "POST",
          }
        : {
            url: "/v2/primgr/updateLicenseAppId",
            data: {
              arAppidLicenseId: currentAppIdLicenseId,
              appId: currentAppId,
            },
            method: "POST",
          }
    );
    if (oResult.data.code === 0) {
      handleModalCancelFn();
      message.success(modalType === "add" ? "添加成功" : "修改成功");
      getAppPortInfoFn();
    } else {
      message.error(oResult.data.msg);
    }
  };

  // 打开添加/编辑的应用弹窗
  const openModalFn = async (
    type: "add" | "edit",
    appInfo?: AppInfoInterface
  ) => {
    setModalType(type);
    // 获取弹窗下的appId列表
    await getModalAppListFn();
    if (type === "edit") {
      setCurrentAppIdLicenseId(appInfo?.arAppidLicenseId || "");
    }
    setIsModalOpen(true);
  };

  // 删除应用信息
  const removeAppInfoFn = (appInfo: AppInfoInterface) => {
    console.log("删除应用信息", appInfo);
    confirm({
      title: "提示",
      icon: <ExclamationCircleOutlined />,
      content: `是否要删除 ${appInfo.appId} 开发者信息？删除后需要更新 License 才可生效`,
      okText: "确定",
      okType: "danger",
      cancelText: "取消",
      async onOk() {
        const oResult: ResultInterface = await axios({
          url: "/v2/primgr/delLicenseAppId",
          data: {
            arAppidLicenseId: appInfo.arAppidLicenseId || "",
          },
          method: "POST",
        });
        if (oResult.data.code === 0) {
          message.success("删除成功");
          getAppPortInfoFn();
        } else {
          message.error(oResult.data.msg);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  /** 下载 License(仅非服务上报) */
  const downloadLicenseFn = async () => {
    // if (!findPermissions(42128))
    //   return message.warning("你当前无权限操作，请请添加对应权限");
    const hideMessage = message.loading("文件下载中", 0, () => {
      message.success("文件下载成功");
    });
    /** 获取下载地址 */
    // /arcrm/v2/primgr/getLicenseUrl
    const getUrl: OptionInterface = {
      url: "/v2/primgr/getLicenseUrl",
      data: {
        licenseId: customProps.licenseId || "",
      },
      method: "POST",
    };
    const resultUrl: ResultInterface = await axios(getUrl);

    if (resultUrl.data.code === 0) {
      const fileUrl = resultUrl.data.data.filePath;
      //  /arcrm/v2/primgr/downloadFile
      const option: OptionInterface = {
        url: `/v2/primgr/downloadFile?filePath=${fileUrl}`,
        method: "GET",
        responseType: "arraybuffer",
      };
      axios(option).then((res) => {
        const fileName: string = `${moment().format("YYYY-MM-DD")}_License.txt`;
        const url: string = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/pdf;charset=utf-8" })
        );
        const a = document.createElement("a");
        a.download = fileName;
        a.href = url;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        hideMessage();
      });
    }
  };
  return customProps.authorizationCode ? (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <h3>开发者信息</h3>
        <Button type="link" onClick={downloadLicenseFn}>
          下载 License
        </Button>
      </div>

      <div
        style={{
          fontSize: "14px",
          color: "#606060",
          display: "flex",

          justifyContent: "center",
        }}
      >
        <div style={{ width: "800px" }}>
          <div
            style={{
              marginBottom: "6px",
              display: "flex",
              alignItems: "center",
              width: "100%",
              padding: "0 160px",
            }}
          >
            <div
              style={{
                color: "#999999",
                whiteSpace: "nowrap",
              }}
            >
              Customer Certificate：
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <span
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {customerInfo?.customerCertificate}
              </span>
              {customerInfo?.customerCertificate && (
                <CopyOutlined
                  style={{
                    fontSize: "20px",
                    marginLeft: "4px",
                    cursor: "pointer",
                  }}
                  title="复制"
                  onClick={() => {
                    handleCopy(customerInfo?.customerCertificate);
                  }}
                />
              )}
            </div>
          </div>
          <div
            style={{
              marginBottom: "6px",
              display: "flex",
              alignItems: "center",
              width: "100%",
              padding: "0 160px",
            }}
          >
            <div
              style={{
                color: "#999999",
                whiteSpace: "nowrap",
              }}
            >
              Customer ID：
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <span
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {customerInfo?.customerId}
              </span>
              {customerInfo?.customerId && (
                <CopyOutlined
                  style={{
                    fontSize: "20px",
                    marginLeft: "4px",
                    cursor: "pointer",
                  }}
                  title="复制"
                  onClick={() => {
                    handleCopy(customerInfo?.customerId);
                  }}
                />
              )}
            </div>
          </div>

          {/* 应用信息 */}
          <div
            style={{
              marginBottom: "6px",
              display: "flex",
              alignItems: "flex-start",
              width: "100%",
              padding: "0 160px",
            }}
          >
            <div
              style={{
                color: "#999999",
                whiteSpace: "nowrap",
              }}
            >
              应用信息：
            </div>
            <div
              style={{
                width: "100%",
                minWidth: "340px",
              }}
            >
              {appInfoList.length > 0 &&
                appInfoList.map((item, appIndex) => {
                  return (
                    <div
                      key={item.appId + appIndex}
                      style={{
                        marginBottom: "10px",
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <div
                        className={
                          DeveloperInformationStyle.developer_info_item
                        }
                        style={{
                          flex: 1,
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              color: "#999999",
                              whiteSpace: "nowrap",
                            }}
                          >
                            APP ID：
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              overflow: "hidden",
                            }}
                          >
                            <span
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.appId}
                            </span>
                            <CopyOutlined
                              style={{
                                fontSize: "20px",
                                marginLeft: "4px",
                                cursor: "pointer",
                              }}
                              title="复制"
                              onClick={() => {
                                handleCopy(item.appId);
                              }}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              color: "#999999",
                              whiteSpace: "nowrap",
                            }}
                          >
                            AppCertificate：
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              overflow: "hidden",
                            }}
                          >
                            <span
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.appCertificate}
                            </span>
                            <CopyOutlined
                              style={{
                                fontSize: "20px",
                                marginLeft: "4px",
                                cursor: "pointer",
                              }}
                              title="复制"
                              onClick={() => {
                                handleCopy(item.appCertificate);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {/* 操作 */}
                      <div
                        style={{
                          width: "50px",
                          marginLeft: "20px",
                          fontSize: "20px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FormOutlined
                          style={{
                            color: "#39A0FF",
                            cursor: "pointer",
                          }}
                          title="编辑"
                          onClick={() => {
                            openModalFn("edit", item);
                          }}
                        />
                        {appIndex !== 0 && (
                          <DeleteOutlined
                            style={{
                              color: "#FF4D4F",
                              marginLeft: "10px",
                              cursor: "pointer",
                            }}
                            title="删除"
                            onClick={() => {
                              removeAppInfoFn(item);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              {/* 添加 */}
              <div
                className={DeveloperInformationStyle.developer_info_item}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "70px",
                }}
                onClick={() => {
                  openModalFn("add");
                }}
              >
                <PlusSquareOutlined
                  style={{
                    color: "#CCCCCC",
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                  title="添加"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 弹窗 */}
      <Modal
        title={modalType === "add" ? "添加开发者信息" : "编辑开发者信息"}
        cancelText="取消"
        okText="确定"
        visible={isModalOpen}
        onOk={handleModalConfirmFn}
        onCancel={handleModalCancelFn}
      >
        {isModalOpen && appIdOptions.length > 0 ? (
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: "#999999",
                  whiteSpace: "nowrap",
                }}
              >
                APP ID:
              </div>
              <div
                style={{
                  flex: 1,
                  overflow: "hidden",
                  marginLeft: "10px",
                }}
              >
                {currentAppId && (
                  <Select
                    defaultValue={currentAppId}
                    size="large"
                    style={{ width: "100%" }}
                    onChange={handleSelectChangeFn}
                    options={appIdOptions.map((item) => {
                      return {
                        value: item.appId,
                        label: item.appId,
                      };
                    })}
                  />
                )}
              </div>
            </div>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: "#999999",
                  whiteSpace: "nowrap",
                }}
              >
                AppCertificate:
              </div>
              <div
                style={{
                  marginLeft: "20px",
                  flex: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {currentAppCertificate}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>暂无可添加应用</span>}
            />
          </div>
        )}
      </Modal>
    </div>
  ) : (
    <div>
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<span>请输入机器码进行生成授权码</span>}
      />
    </div>
  );
}
