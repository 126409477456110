// 添加服务
import { FC, ReactElement, useEffect, useRef, useState } from "react";
import styles from "./serveAdd.less";
// import ServeFormComponent from "../../../../components/ServeForm/serveformcustom";
// import ServeCustom from "../../../../components/ServeForm/servecustom";

import ServeCheck from "../../../../components/ServeCheck/servecheck";
import ServeCheckExhibition from "../../../../components/ServeCheck/serveexhibition";

import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Steps,
  message,
} from "antd";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "../../../../axios/axios";
import { Option } from "../../../../axios/axios-types";

const { Step } = Steps;
const { TextArea } = Input;
const { Search } = Input;
namespace Antd {
  export const { Option } = Select;
}

interface Result {
  data: {
    code: number;
    data: any;
  };
}

/** 校验 */
const validateLimit: any = async (rule: any, value: any): Promise<void> => {
  if (rule.field === "email") {
    // eslint-disable-next-line no-useless-escape
    const myreg: RegExp = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    // const myreg: RegExp = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
    if (value === "") {
      throw new Error("请填写邮箱");
    } else if (!myreg.test(value)) {
      throw new Error("邮箱格式不正确");
    }
  } else if (rule.field === "tel") {
    const phoneReg: RegExp = /^[1][3,4,5,7,8,9][0-9]{9}$/;
    if (value === "") {
      throw new Error("请填写手机号码");
    } else if (!phoneReg.test(value)) {
      throw new Error("手机号码有误");
    }
  } else if (rule.field === "companyName") {
    if (value === "" || !value) {
      throw new Error("请输入公司名称");
    }
  } else if (rule.field === "remark") {
    if (value === "") {
      throw new Error("请输入备注");
    }
  } else if (rule.field === "appId") {
    if (value === "" || !value) {
      throw new Error("请输入 AppID");
    }
  }
};

const ServeAdd: FC = (): ReactElement => {
  const location: any = useLocation();

  /** 步骤 */
  const [stepCurrent, setStepCurrent] = useState(0);
  // 加载
  const [loading, setLoading] = useState<boolean>(false);

  /** ----- 步骤一 start ------ */
  //  客户类型表单初始数据
  const [initdevpType] = useState(location.state?.devp || 1);
  // 部署类型初始数据
  const [initlicType] = useState(3);
  // 销售初始选择
  const [initSaleId, setInitSaleId] = useState("");
  // 公司名称
  const [initcompanyName, setInitCompanyName] = useState("");
  // 邮箱
  const [initemail, setInitEmail] = useState("");
  // 手机号码
  const [initphone, setInitPhone] = useState("");
  // 获取 form
  const formRefs = useRef(null);
  // this.refData3 = React.createRef();

  /** 销售列表 */
  const [arrAdminInfo, setArrAdminInfo]: [any[], Function] = useState([]);
  const getArrAdminInfo = async () => {
    const option: Option = {
      url: "/v2/primgr/getAllSaleUserList",
      // data: {
      //   appId: value,
      // },
      method: "GET",
    };
    // 校验设备码
    const result: Result = await axios(option);
    if (result.data.code === 0) {
      const { arrAdminInfo } = result.data.data;
      setArrAdminInfo(arrAdminInfo);
      setInitSaleId(arrAdminInfo[0].aid);
      (formRefs.current as any)?.resetFields(["salesId"]);
    }
  };
  // getArrAdminInfo();
  useEffect(() => {
    getArrAdminInfo();
  }, []);

  /** appId 搜索 */
  const appIdSearchFn = async (value: string) => {
    if (!value) {
      return message.error("请输入 appId");
    }
    const option: Option = {
      url: "/v2/primgr/getDevpInfoByAppId",
      data: {
        appId: value,
      },
      method: "POST",
    };
    setLoading(true);
    // 校验设备码
    const result: Result = await axios(option);
    setLoading(false);
    if (result.data.code === 0) {
      // console.log("appIdxixi", result.data.data);

      // setArrAdminInfo(result.data.data.arrAdminInfo);
      // setInitSaleId(result.data.data.arrAdminInfo[0].aid);

      const { cellphone, email, name } = result.data.data.userDetail;
      setInitCompanyName(name);
      setInitEmail(email);
      setInitPhone(cellphone);
      (formRefs.current as any).resetFields([
        "companyName",
        "email",
        "phone",
        // "salesId",
      ]);
    } else {
      message.error("appId 信息查询失败");
    }
  };
  /** 表单校验 */
  const onFinish = async (values: any) => {
    // console.log("表单校验", values);
    setLoading(true);
    const option: Option = {
      url: "/v2/primgr/insertDevp",
      data: {
        appId: values.appId || "",
        companyName: values.companyName || "",
        email: values.email || "",
        phone: values.phone || "",
        oem: "",
        devpType: values.devpType,
        licType: values.licType,
        sale: values.salesId || "",
        remark: values.remark || "",
        uid: values.salesId || "",
        // equipmentCode: "784512",
      },
      method: "POST",
    };

    // 校验设备码
    const result: Result = await axios(option);
    setLoading(false);
    if (result.data.code === 0) {
      const { licType, licenseId } = result.data.data;

      // licenseId 与 appid 绑定
      const oBindAppid = await axios({
        url: "/v2/primgr/addLicenseAppId",
        data: {
          appId: values.appId,
          licenseId,
        },
        method: "POST",
      });
      if (oBindAppid.data.code === 0) {
        setRecordDeployType(licType);
        setRecordLicenseId(licenseId);

        setRecordId(values.salesId || "");
        // 进入下一步
        setStepCurrent(1);
      } else {
        message.error("licenseId 与 appid 绑定失败");
      }
    } else {
      message.error("信息添加失败");
    }
  };
  /** 表单校验失败 */
  const onFinishFailed = (errorInfo: any) => {
    console.log("表单校验失败", errorInfo);
  };
  /** ----- 步骤一 end ------ */

  /** ------ 步骤二 start ----- */
  // 记录步骤一的 uId
  const [recordId, setRecordId] = useState("");
  // 记录步骤一的部署类型
  const [recordDeployType, setRecordDeployType] = useState(0);
  // 记录步骤一的 licenseId
  const [recordLicenseId, setRecordLicenseId] = useState("");
  const refServeCustom = React.createRef();

  // 确认授权
  const sumbitAuthorization = async () => {
    try {
      // 获取服务选择
      const { expirTime, basicInfo, checkoutList } = await (
        refServeCustom.current as any
      )?.getFormData();

      if (checkoutList && checkoutList.length === 0) {
        return message.error("请选择需要授权的服务");
      }
      console.log("获取服务选择", expirTime, basicInfo, checkoutList);
      setPreviewServeExpirTime(expirTime);
      setPreviewServeBasicInfo(basicInfo);
      setPreviewServeIdList(checkoutList);

      setStepCurrent(2);

      // setLoading(true);
      // const option: Option = {
      //   url: "/v2/primgr/insertService",
      //   data: {
      //     licType: recordDeployType,
      //     uid: "",
      //     licenseId: recordLicenseId,
      //     arrSType: serveInfo.checkoutList,
      //     // 到期时间
      //     expirationTime: serveInfo.formInfo.expirTime,
      //     // 音视频并发数
      //     concurrentNum: serveInfo.formInfo.RTCConcurrency || 0,
      //     // 账号数量
      //     accountNum: serveInfo.formInfo.talkAccounts || 0,
      //     // 监控网关最大通道数
      //     // maxChanNum: serveInfo.formInfo.talkMonitorAccounts || 0,
      //     // SIP监控网关通道数
      //     sipChanNum: serveInfo.formInfo.talkMonitorAccounts || 0,
      //     // SIP监控网关最大账号数
      //     // sipAccountNum: serveInfo.formInfo.talkSIPMonitorAccounts || 0,
      //     //  SIP监控网关并发数
      //     sipConrurrentNum: serveInfo.formInfo.talkSIPMonitorAccounts || 0,
      //     // 会议网关最大任务数
      //     confTaskNum: serveInfo.formInfo.talkConferenceAccounts || 0,
      //     // H323连接器最大设备数
      //     h323ConnNum: serveInfo.formInfo.talkH323Accounts || 0,
      //     // H323连接器并发数
      //     h323ConrurrentNum: serveInfo.formInfo.talkH323ConrurrentNum || 0,
      //     // 电话会议并发数
      //     phoneConfConcurrentNum: serveInfo.formInfo.talkPhoneAccounts || 0,

      //     // 上联网关最大平台数
      //     maxCascadePlatformNum: serveInfo.formInfo.talkUpgradeAccounts || 0,
      //     // 上联网关最大并发数
      //     maxCascadeConrurrentNum: serveInfo.formInfo.talkUpperAccounts || 0,
      //   },
      //   method: "POST",
      // };
      // // 校验设备码
      // const result: Result = await axios(option);
      // setLoading(false);
      // if (result.data.code === 0) {
      //   message.success("服务授权成功");
      //   history.go(-1);
      //   // console.log("history", history);
      // } else {
      //   message.error("服务授权失败");
      // }
    } catch (error) {
      console.log("error", error);
    }
  };
  /** ------ 步骤二 end ----- */

  /** ------ 步骤三 start ----- */
  // 预览的基本服务信息
  const [previewServeBasicInfo, setPreviewServeBasicInfo] = useState({});
  // 服务过期时间
  const [previewServeExpirTime, setPreviewServeExpirTime] = useState(0);
  // 选择的服务Id
  const [previewServeIdList, setPreviewServeIdList] = useState<number[]>();

  // 返回原页
  const history: any = useHistory();
  // 提交预览
  const submitPreviewServeFn = async () => {
    // console.log("预览的基本服务信息", previewServeBasicInfo);
    // console.log("服务过期时间", previewServeExpirTime);
    // console.log("选择的服务Id", previewServeIdList);

    setLoading(true);

    const oDataInfo = {
      licType: recordDeployType,
      uid: recordId,
      licenseId: recordLicenseId,
      arrSType: previewServeIdList,
      expirationTime: previewServeExpirTime,
      ...previewServeBasicInfo,
    };

    const option: Option = {
      url: "/v2/primgr/insertService",
      data: oDataInfo,
      method: "POST",
    };
    // 校验设备码
    const result: Result = await axios(option);
    setLoading(false);
    if (result.data.code === 0) {
      message.success("服务授权成功");
      history.go(-1);
      // console.log("history", history);
    } else {
      message.error("服务授权失败");
    }
  };

  /** ------ 步骤三 end ----- */

  return (
    <div className={styles.serve_add}>
      {/* 步骤 */}
      <div className={styles.step_div}>
        <Steps
          className={styles.step}
          size="small"
          current={stepCurrent}
          labelPlacement="vertical"
        >
          <Step title="填写联系信息" />
          <Step title="服务授权" />
          <Step title="预览" />
        </Steps>
      </div>
      {/* 步骤一 */}
      {stepCurrent === 0 && (
        <div className={styles.step_conent}>
          <div className={styles.step_two}>
            <Form
              ref={formRefs}
              className={styles.step_two_form}
              name="userInfo"
              initialValues={{
                devpType: initdevpType,
                licType: initlicType,
                salesId: initSaleId,
                companyName: initcompanyName,
                email: initemail,
                phone: initphone,
              }}
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 19 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              size="large"
            >
              <Row>
                <Col span={11}>
                  <Form.Item
                    label="AppID"
                    name="appId"
                    rules={[{ required: true, validator: validateLimit }]}
                  >
                    <Search
                      disabled={loading}
                      placeholder="请输入 AppID"
                      allowClear
                      onSearch={appIdSearchFn}
                    />
                    {/* <Input placeholder="" /> */}
                  </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                  <Form.Item label="客户类型" name="devpType">
                    <Select disabled={loading} style={{ width: 200 }}>
                      <Antd.Option value={1}>正式期</Antd.Option>
                      <Antd.Option value={2}>测试期</Antd.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={11}>
                  <Form.Item
                    label="公司名称"
                    name="companyName"
                    rules={[{ required: true, validator: validateLimit }]}
                  >
                    <Input disabled={loading} placeholder="请输入公司名称" />
                  </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                  <Form.Item label="部署类型" name="licType">
                    <Select disabled={loading} style={{ width: 200 }}>
                      <Antd.Option value={3}>私有云</Antd.Option>
                      <Antd.Option value={2}>混合云</Antd.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={11}>
                  <Form.Item
                    label="邮箱"
                    name="email"
                    rules={[{ required: true, validator: validateLimit }]}
                  >
                    <Input disabled={loading} placeholder="请输入邮箱" />
                  </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                  {/* rules={[{ required: true, message: '请选择销售' }]} */}
                  <Form.Item label="所属销售" name="salesId">
                    <Select disabled={loading} style={{ width: 200 }}>
                      {arrAdminInfo.map((item: any): ReactElement => {
                        return (
                          <Antd.Option key={item.aid} value={item.aid}>
                            {item.name}
                          </Antd.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={11}>
                  <Form.Item
                    label="手机号码"
                    name="phone"
                    rules={[{ required: true, validator: validateLimit }]}
                  >
                    <Input disabled={loading} placeholder="请输入邮箱" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={18}>
                  <Form.Item
                    labelCol={{ span: 3 }}
                    wrapperCol={{ span: 21 }}
                    label="备注"
                    name="remark"
                  >
                    <TextArea disabled={loading} placeholder="请输入备注" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
                    <div className={styles.step_two_form_button}>
                      {/* <Space size={16}> */}
                      <Button
                        loading={loading}
                        size="large"
                        type="primary"
                        htmlType="submit"
                      >
                        下一步
                      </Button>
                      {/* </Space> */}
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      )}
      {/* 步骤二 */}
      {stepCurrent === 1 && (
        <div className={styles.step_conent}>
          {/* 服务选择 */}
          <div className={styles.step_three}>
            <div
              className={styles.step_three_select}
              style={{
                height: "400px",
              }}
            >
              <ServeCheck
                onRef={refServeCustom}
                defaultConfig={{
                  expirTime: previewServeExpirTime,
                  basicConfig: previewServeBasicInfo,
                  configuredServeList: previewServeIdList,
                }}
              />
            </div>
            <div className={styles.step_three_form_button}>
              <Space size={16}>
                <Button
                  onClick={() => {
                    setStepCurrent(0);
                  }}
                  size="large"
                >
                  上一步
                </Button>
                <Button
                  onClick={() => {
                    sumbitAuthorization();
                  }}
                  size="large"
                  type="primary"
                >
                  下一步
                </Button>
              </Space>
            </div>
          </div>
        </div>
      )}
      {/* 步骤三 */}
      {stepCurrent === 2 && (
        <div className={styles.step_conent}>
          {/* 服务选择 */}
          <div className={styles.step_three}>
            <div
              className={styles.step_three_select}
              style={{
                height: "400px",
              }}
            >
              <ServeCheckExhibition
                expirTime={previewServeExpirTime}
                basicConfig={previewServeBasicInfo}
                configuredServeList={previewServeIdList}
              />
            </div>
            <div className={styles.step_three_form_button}>
              <Space size={16}>
                <Button
                  onClick={() => {
                    setStepCurrent(1);
                  }}
                  size="large"
                >
                  上一步
                </Button>
                <Button
                  onClick={submitPreviewServeFn}
                  size="large"
                  type="primary"
                >
                  完成
                </Button>
              </Space>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServeAdd;
