import { FC, ReactElement, useEffect, useState } from 'react';
import { Input, Checkbox, Tree, Button, message, Collapse } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { Option } from '../../../../../axios/axios-types';
import axios from '../../../../../axios/axios';
import styles from './addRole.less';

const { Panel } = Collapse;

interface Result {
	data: {
        code: number;
        data: any;
    };
};

const AddRole: FC = (): ReactElement => {
    const location: any = useLocation();
    const history: any = useHistory();
    const [roleName, setRoleName]: [string, Function] = useState('');
    const [roleDesc, setRoleDesc]: [string, Function] = useState('');
    const [options, setOptions]: [any[], Function] = useState([]);
    const [arrPerm, setArrPerm]: [any[], Function] = useState([]);
    const [indeterminate, setIndeterminate]: [boolean, Function] = useState(false);
    const [checkAll, setCheckAll]: [boolean, Function] = useState(false);
    const [effect, setEffect]: [boolean, Function] = useState(false);

    
    useEffect((): void => {
        getTreePermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect((): void => {
        if (location.state && options.length && arrPerm.length) {
            const roleId: string | undefined = location.state.roleId;
            roleId !== undefined && getRoleInfo(+roleId);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [effect]);

    const getRoleInfo: Function = async (roleId: number): Promise<void> => {
        const option: Option = {
            url: '/perm/getRoleInfo',
            data: { roleId },
            method: 'POST'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            setRoleName(result.data.data.roleName);
            setRoleDesc(result.data.data.roleDesc);
            if (result.data.data.permArr) {
                const permArr = JSON.parse(result.data.data.permArr);
                if (Array.isArray(permArr)) {
                    const option: any[] = JSON.parse(JSON.stringify(options));
                    const arrPer: any[] = JSON.parse(JSON.stringify(arrPerm));
                    permArr.forEach((item: any, index: number): void => {
                        item.forEach((id: number[]): void => {
                            arrPer[index].push(id);
                        });
                        const list: number[] = getList(item, option[index].children);
                        option[index].checkedKeys = list;
                        option[index].indeterminate = !!item.length && item.length < option[index].allChildNumber + 1;
                        option[index].checkAll = item.length === option[index].allChildNumber + 1;
                        const checkAll: boolean = option.every((item: any): boolean => item.checkAll);
                        const indeterminate: boolean = option.some((item: any): boolean => item.indeterminate || item.checkAll);
                        setIndeterminate(indeterminate && !checkAll);
                        setCheckAll(checkAll);
                    });
                    setOptions(option);
                    setArrPerm(arrPer);
                }
            }
        } else {
           message.error("获取角色权限失败");
        }
    };

    const getList: Function = (permArrItem: any[], child: any[], list: number[] = []): number[] => {
        permArrItem.forEach((id: number[]): void => {
            deepAllChildren(id[0], child, list, permArrItem);
        });
        return list;
    };
    
    const deepAllChildren: Function = (id: number, child: any[], list: number[] = [], permArrItem: any[]): void => {
        child.forEach((item: any): void => {
            if (item.value === id) {
                if (item.children) {
                    const bool: boolean = deepEvery(item.children, permArrItem);
                    bool && list.push(id);
                } else {
                    list.push(id);
                }
            } else {
                if (item.children) {
                    deepAllChildren(id, item.children, list, permArrItem);
                }
            }
        });
    };

    const deepEvery: Function = (child: any[], permArrItem: any[]): boolean => {
        return child.every((item: any): boolean => {
            const target: number[] = permArrItem.find((id: number[]): boolean => {
                return id[0] === item.value;
            });
            if (target) {
                if (item.children) {
                    return deepEvery(item.children, permArrItem);
                } else {
                    return true;
                }
            } else {
                return false;
            }
        });
    };

    const getTreePermission: Function = async (): Promise<void> => {
        const option: Option = {
            url: '/perm/getTreePermission',
            method: 'GET'
        };
        const result: Result = await axios(option);
        if (result.data.code === 0) {
            const arrPer: any[] = [];
            result.data.data.treePerm = JSON.parse(result.data.data.treePerm);
            console.log("🚀 ~ file: addRole.tsx:130 ~ constgetTreePermission:Function= ~ result.data.data.treePerm:", result.data.data.treePerm)
            result.data.data.treePerm.forEach((item: any): void => {
                arrPer.push([]);
                item.checkedKeys = [];
                item.indeterminate = false;
                item.checkAll = false;
                item.allChildNumber = deepChild(item.children);
            });
            setOptions(result.data.data.treePerm);
            setArrPerm(arrPer);
            setEffect(!effect);
        }
    };

    const deepChild: Function = (arr: any[], num: { total: number } = { total: 0 }): number => {
        arr.forEach((item: any): void => {
            num.total += 1;
            item.children && deepChild(item.children, num);
        });
        return num.total;
    };

    const handleBack: Function = (): void => {
        history.goBack();
    };

    const handleAllChange: Function = (e: any): void => {
        dealAllChange(e.target.checked);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const dealAllChange: Function = (checked: boolean, index: number | undefined): void => {
        const option: any[] = JSON.parse(JSON.stringify(options));
        const arr: any[] = [];
        (index !== undefined? [option[index]] : option).forEach((item: any, sub: number): void => {
            const list: any[] = [];
            arr.push([]);
            if (checked) {
                item.children.forEach((child: any): void => {
                    list.push(child.value);
                    child.children && deepAddChild(child.children, list);
                });
            }
            item.checkAll = checked;
            item.indeterminate = false;
            item.checkedKeys = list;
            list.forEach((id: any): void => {
                arr[sub].push([id]);
            });
            index !== undefined && dealCheckAll(list, index, option);
        });
        index === undefined && setArrPerm(arr);
        setOptions(option);
    };

    const deepAddChild: Function = (option: any[], list: any[]): void => {
        option.forEach((item: any): void => {
            list.push(item.value);
            if (item.children) {
                deepAddChild(item.children, list);
            }
        });
    };

    const handleTreeCheck: Function = (checkedKeys: React.Key[], infor: any, index: number): void => {
        const data: React.Key[] = [...checkedKeys, ...infor.halfCheckedKeys];
        const option: any[] = JSON.parse(JSON.stringify(options));
        option[index].checkedKeys = checkedKeys;
        option[index].indeterminate = !!checkedKeys.length && checkedKeys.length < option[index].allChildNumber;
        option[index].checkAll = checkedKeys.length === option[index].allChildNumber;
        dealCheckAll(data, index, option);
        setOptions(option);
    };

    const dealCheckAll: Function = (checkedKeys: React.Key[], index: number, option: any): void => {
        const arrPer: any[] = JSON.parse(JSON.stringify(arrPerm));
        const checkAll: boolean = option.every((item: any): boolean => item.checkAll);
        const indeterminate: boolean = option.some((item: any): boolean => item.indeterminate || item.checkAll);
        arrPer[index] = [];
        checkedKeys.forEach((item: any): void => {
            arrPer[index].push([item]);
        });
        setIndeterminate(indeterminate && !checkAll);
        setCheckAll(checkAll);
        setArrPerm(arrPer);
    };

    const insertRole: Function = async (): Promise<void> => {
        if (roleName.replace(/\s/g, "") === "") {
            message.error("请输入角色名称");
        } else if (roleDesc.replace(/\s/g, "") === "") {
            message.error("请输入角色描述");
        } else {
            const bool: boolean = arrPerm.some((item: any): boolean => item.length);
            if (bool) {
                const arr: any[] = JSON.parse(JSON.stringify(arrPerm));
                arr.forEach((item: any, index: number): void => {
                    const target: number = options[index].value;                    
                    if (item.length && !item.find((id: any[]): boolean => target === id[0])) {
                        item.unshift([target]);
                    }
                });
                const roleId: any = location.state? location.state.roleId : undefined;
                const option: Option = {
                    url: roleId? '/perm/updateRole' : '/perm/insertRole',
                    data: {
                        arrPerm: arr,
                        roleDesc,
                        roleName,
                        roleId: roleId,
                    },
                    method: 'POST'
                };
                const result: Result = await axios(option);
                if (result.data.code === 0) {
                    message.success(`角色${roleId? "修改" : "添加"}成功`);
                    handleBack();
                } else if (result.data.code === 1103) {
                    message.error("这个角色已经存在");
                } else {
                    message.error(`角色${roleId? "修改" : "添加"}失败`);
                }
            } else {
                message.error("请选择角色权限");
            }
        }
    };
    return (
        <div className={ styles.addRole }>
            <div className={ styles.modal }>
                <div className={ styles.title }>Setting Role</div>
                <div className={ styles.inputBox }>
                    <div className={ styles.inputItem }>
                        <span>角色名称：</span>
                        <Input placeholder="9字以内" maxLength={ 9 } value={ roleName } onChange={ (e) => setRoleName(e.target.value) } />
                    </div>
                    <div className={ styles.inputItem }>
                        <span>角色描述：</span>
                        <Input placeholder="29字以内" maxLength={ 29 } value={ roleDesc } onChange={ (e) => setRoleDesc(e.target.value) } />
                    </div>
                </div>
                <div className={ styles.allPermissions }>
                    <span className={ styles.span }>角色权限</span>
                    <Checkbox indeterminate={ indeterminate } onChange={ (e) => handleAllChange(e) } checked={ checkAll }>
                    全部权限
                    </Checkbox>
                </div>
                <Collapse accordion>
                    {
                        options.map((item: any, index: number): ReactElement => {

                            return <Panel key={ index } 
                            header={ <Checkbox indeterminate={ item.indeterminate }
                            onChange={ (e) => dealAllChange(e.target.checked, index) } checked={ item.checkAll }>
                            { item.label }
                            </Checkbox> }>
                                <Tree
                                checkable
                                onCheck={ (checkedKeys, infor) => handleTreeCheck(checkedKeys, infor, index) }
                                checkedKeys={ item.checkedKeys }
                                showLine={{ showLeafIcon: false }}
                                fieldNames={{
                                    title: 'label',
                                    key: 'value'
                                }}
                                blockNode={ true }
                                treeData={ item.children }
                                />
                            </Panel>
                        })
                    }
                </Collapse>
                <div className={ styles.btnBox }>
                    <Button className={ styles.btn } onClick={ () => insertRole() } type="primary">Save</Button>
                    <Button className={ styles.btn } onClick={ () => handleBack() }>Back</Button>
                </div>
            </div>
        </div>
    )
};

export default AddRole;

