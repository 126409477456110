// 编辑基本信息
import React from "react";
import { Input, Button, Modal, Form, Space, message } from "antd";
import styles from "./editbasicinfo.less";
import axios from "../../axios/axios";
import { Option } from "../../axios/axios-types";
const { TextArea } = Input;
interface Result {
  data: {
    code: number;
    data: any;
  };
}
interface Props {
  // 是否展示
  isVisible: boolean;
  // 服务 id
  serveId: string;
  // 类型
  infoType: "name" | "email" | "tel" | string | "remark" | "deviceCode";
  // 信息记录
  infoRecord?: string;

  close: Function;
  updataInfo: Function;
}

/** 校验 */
const validateLimit: any = async (rule: any, value: any): Promise<void> => {
  if (rule.field === "email") {
    // eslint-disable-next-line no-useless-escape
    const myreg: RegExp = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    // const myreg: RegExp = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
    if (value === "") {
      throw new Error("请填写邮箱");
    } else if (!myreg.test(value)) {
      throw new Error("邮箱格式不正确");
    }
  } else if (rule.field === "tel") {
    const phoneReg: RegExp = /^[1][3,4,5,7,8,9][0-9]{9}$/;
    if (value === "") {
      throw new Error("请填写手机号码");
    } else if (!phoneReg.test(value)) {
      throw new Error("手机号码有误");
    }
  } else if (rule.field === "cnname") {
    if (value === "") {
      throw new Error("请输入公司名称");
    }
  } else if (rule.field === "remark") {
    if (value === "") {
      throw new Error("请输入备注");
    }
  } else if(rule.field === "deviceCode") {
    if (value === "") {
      throw new Error("请输入授权码");
    }
  }
};

export default class EditBasicInfo extends React.Component<Props> {
  render() {
    /** 标题设置 */
    let titleText = "编辑基本信息";
    let urlName = "";
    let vaueType = "";
    if (!this.props.isVisible) {
      return null;
    }
    if (this.props.infoType === "name") {
      titleText = "修改公司名称";
      urlName = "updateCompanyName";
      vaueType = "cnname";
    } else if (this.props.infoType === "email") {
      titleText = "修改邮箱";
      urlName = "updateEamil";
      vaueType = "email";
    } else if (this.props.infoType === "tel") {
      titleText = "修改手机号码";
      urlName = "updatePhone";
      vaueType = "tel";
    } else if (this.props.infoType === "remark") {
      titleText = this.props.infoRecord ? "修改备注" : "添加备注";
      urlName = "updateLicenseRemark";
      vaueType = "remark";
    } else if(this.props.infoType === "deviceCode") {
      titleText = "修改设备码";
      urlName = "updateEquipmentCode";
      vaueType = "deviceCode";
    }

    /** 关闭弹窗 */
    const closeChild = (isUpdata: boolean) => {
      this.props.close(isUpdata);
    };
    /** 表单校验 */
    const onFinish = async (values: any) => {
      // console.log("表单校验", values);
      const options: Option = {
        method: "POST",
        url: "/v2/primgr/" + urlName,
        data: {
          licenseId: this.props.serveId,
          updateValue: values[vaueType],
        },
      };

      const result: Result = await axios(options);

      if (result.data.code === 0) {
        message.success(titleText + "成功");
        // 更新数据
        this.props.updataInfo(this.props.infoType, values[vaueType]);
        closeChild(false);
      } else {
        message.error(titleText + "失败");
      }
    };
    /** 表单校验失败 */
    const onFinishFailed = (errorInfo: any) => {
      console.log("表单校验失败", errorInfo);
    };
    // /** 提交 */
    // const submitBasicInfo = async () => {
    //   console.log("提交");
    // };
    return (
      <Modal
        visible={this.props.isVisible}
        title={titleText}
        onCancel={() => {
          closeChild(false);
        }}
        footer={null}
        destroyOnClose
      >
        <Form
          name="basic"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 16 }}
          initialValues={{
            cnname: this.props.infoRecord,
            email: this.props.infoRecord,
            tel: this.props.infoRecord,
            remark: this.props.infoRecord,
            deviceCode: this.props.infoRecord,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          size="large"
        >
          {this.props.infoType === "name" && (
            <Form.Item
              label="公司名称"
              name="cnname"
              rules={[{ required: true, validator: validateLimit }]}
            >
              <Input placeholder="请输入公司名称" />
            </Form.Item>
          )}
          {this.props.infoType === "email" && (
            <Form.Item
              label="邮箱"
              name="email"
              rules={[{ required: true, validator: validateLimit }]}
            >
              <Input placeholder="请输入邮箱" />
            </Form.Item>
          )}
          {this.props.infoType === "tel" && (
            <Form.Item
              label="手机号码"
              name="tel"
              rules={[{ required: true, validator: validateLimit }]}
            >
              <Input placeholder="请输入手机号码" />
            </Form.Item>
          )}
          {this.props.infoType === "remark" && (
            <Form.Item
              label="备注"
              name="remark"
              rules={[{ required: true, validator: validateLimit }]}
            >
              <TextArea placeholder="请输入备注" />
            </Form.Item>
          )}
            {this.props.infoType === "deviceCode" && (
            <Form.Item
              label="授权码"
              name="deviceCode"
              rules={[{ required: true, validator: validateLimit }]}
            >
              <TextArea placeholder="请输入授权码" />
            </Form.Item>
          )}
          <Form.Item
            wrapperCol={{ offset: 5, span: 19 }}
            className={styles.form_item_button}
          >
            <div className={styles.form_button}>
              <Space size={20}>
                <Button
                  onClick={() => {
                    closeChild(false);
                  }}
                >
                  取消
                </Button>
                <Button type="primary" htmlType="submit">
                  修改
                </Button>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
  // return (
  //     <Modal  title='删除客户' width={ 500 } getContainer={ false } centered: any
  //     confirmLoading={ confirmLoading }
  //     visible={ deleteVisible } onOk={ () => handleRemove: any() } onCancel={ () => setDeleteVisible(false)}>
  //         <p>您确定要删除该客户吗?</p>
  //     </Modal>
  // )
}
