/**
 * 服务选择预览
 */
import moment from "moment";
import styles from "./servecheck.less";

import {
  FormInfoConfig,
  FormInfoConfigEnum,
  ServeCheckExhibitionInterface,
} from "./type";
import { ReactElement, useEffect, useState } from "react";
import {
  ANYRTC_SERVE_OPTIONS,
  PORT_TRANSITION_CONFIG,
  RTC_SERVE_OPTIONS,
  TALK_SERVE_OPTIONS,
  getDefaultServeConfig,
} from "./serveconfig";

import CheckImg from "../../assets/images/check.png";

export default function ServeCheckExhibition(
  serveProps: ServeCheckExhibitionInterface
) {
  // 过期时间
  const [serveExpirTime, setServeExpirTime] = useState<string>("");

  /**
   * 服务选择进行处理
   */
  // anyRTC 服务选择列表
  const [anyRTCList, setAnyRTCList] = useState<any[]>(ANYRTC_SERVE_OPTIONS);
  // RTC 服务选择列表
  const [rtcList, setRtcList] = useState<any[]>(RTC_SERVE_OPTIONS);
  // 快对讲服务选择列表
  const [quickTalkList, setQuickTalkList] = useState<any[]>(TALK_SERVE_OPTIONS);

  // 服务信息
  const [serveInfo, setServeInfo] = useState<FormInfoConfig>({});

  // anyRTC 服务是否存在选中
  const [isAnyRTCServeChecked, setAnyRTCServeChecked] = useState(false);
  // 快对讲服务是否存在选中
  const [isQuickTalkServeChecked, setQuickTalkServeChecked] = useState(false);

  useEffect(() => {
    const { expirTime, configuredServeList, basicConfig } = serveProps;
    // 过期时间
    if (expirTime) {
      setServeExpirTime(moment.unix(expirTime).format("YYYY-MM-DD HH:mm:ss"));
    }
    if (configuredServeList && configuredServeList.length > 0) {
      const { serveType, serveConfig } = PORT_TRANSITION_CONFIG(
        configuredServeList,
        basicConfig
      );

      // 设置属性
      setServeInfo({ ...serveConfig });

      // 设置选中
      if (serveType.includes("anyRTC")) {
        const list = ANYRTC_SERVE_OPTIONS.map((item) => {
          if (configuredServeList.includes(item.value)) {
            item.checked = true;
          }
          return item;
        });
        setAnyRTCList(list);
      }

      if (serveType.includes("RTC")) {
        const list = RTC_SERVE_OPTIONS.map((item) => {
          if (configuredServeList.includes(item.value)) {
            item.checked = true;
          }
          return item;
        });
        setRtcList(list);
      }

      if (serveType.includes("Talk")) {
        const list = TALK_SERVE_OPTIONS.map((item) => {
          if (configuredServeList.includes(item.value)) {
            item.checked = true;
          }
          return item;
        });
        setQuickTalkList(list);
      }

      setAnyRTCServeChecked(
        serveType.includes("anyRTC") || serveType.includes("RTC")
      );
      setQuickTalkServeChecked(serveType.includes("Talk"));
    }

    return () => {
      // 组件销毁
      setAnyRTCList(
        ANYRTC_SERVE_OPTIONS.map((item) => {
          item.checked = false;
          return item;
        })
      );
      setQuickTalkList(
        TALK_SERVE_OPTIONS.map((item) => {
          item.checked = false;
          return item;
        })
      );
      setRtcList(
        RTC_SERVE_OPTIONS.map((item) => {
          item.checked = false;
          return item;
        })
      );
      // 恢复默认值
      setServeInfo(getDefaultServeConfig());
    };
  }, [serveProps]);

  return (
    <div className={styles.serveexhibition}>
      {serveExpirTime && (
        <p>
          <span className={styles.serve_expirTime}>过期时间：</span>
          {serveExpirTime}
        </p>
      )}
      <div className={styles.serve_check_options}>
        {/* anyRTC */}
        <div className={styles.serve_check_options_item}>
          <h3 className={styles.ul_title}>anyRTC 服务</h3>
          {isAnyRTCServeChecked ? (
            <ul className={styles.check_ul}>
              {anyRTCList
                .filter((item) => item.checked)
                .map((item: any): ReactElement => {
                  return (
                    <li key={item.label} className={styles.check_ul_li_check}>
                      <div className={styles.check_ul_li_check_options}>
                        <img src={CheckImg} alt="" />
                        {item.label}
                      </div>
                      {/* RTC 服务 */}
                      {item.label === ANYRTC_SERVE_OPTIONS[0].label &&
                        item.checked && (
                          <ul className={styles.check_li_options_ul}>
                            {/* RTC 相关配置 */}
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              并发数：
                              {serveInfo[
                                FormInfoConfigEnum.RTCConcurrencyConcurrencyNum
                              ] || 0}
                            </li>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              最大服务器数：
                              {serveInfo[FormInfoConfigEnum.RTCMaxServerNum] ||
                                0}
                            </li>
                            {/* RTC 相关服务选择 */}
                            {rtcList
                              .filter((item) => item.checked)
                              .map((serveItem: any) => {
                                return (
                                  <li
                                    key={serveItem.label}
                                    className={styles.check_ul_li_check}
                                  >
                                    <div
                                      className={
                                        styles.check_ul_li_check_options
                                      }
                                    >
                                      <img src={CheckImg} alt="" />
                                      {serveItem.label}
                                    </div>
                                    {/* 云端录制 */}
                                    {serveItem.label ===
                                      RTC_SERVE_OPTIONS[0].label &&
                                      serveItem.checked && (
                                        <ul
                                          className={styles.check_li_options_ul}
                                        >
                                          <li
                                            className={
                                              styles.check_li_options_ul_li_config
                                            }
                                          >
                                            最大服务器数：
                                            {serveInfo[
                                              FormInfoConfigEnum
                                                .RTCCloudRecordMaxServerNum
                                            ] || 0}
                                          </li>
                                        </ul>
                                      )}
                                    {/* 小程序 */}
                                    {serveItem.label ===
                                      RTC_SERVE_OPTIONS[1].label &&
                                      serveItem.checked && (
                                        <ul
                                          className={styles.check_li_options_ul}
                                        >
                                          <li
                                            className={
                                              styles.check_li_options_ul_li_config
                                            }
                                          >
                                            最大服务器数：
                                            {serveInfo[
                                              FormInfoConfigEnum
                                                .RTCMiniMaxServerNum
                                            ] || 0}
                                          </li>
                                        </ul>
                                      )}
                                    {/* 旁路推流 */}
                                    {serveItem.label ===
                                      RTC_SERVE_OPTIONS[2].label &&
                                      serveItem.checked && (
                                        <ul
                                          className={styles.check_li_options_ul}
                                        >
                                          <li
                                            className={
                                              styles.check_li_options_ul_li_config
                                            }
                                          >
                                            最大服务器数：
                                            {serveInfo[
                                              FormInfoConfigEnum
                                                .RTCPushMaxServerNum
                                            ] || 0}
                                          </li>
                                        </ul>
                                      )}
                                    {/* 输入在线媒体流 */}
                                    {serveItem.label ===
                                      RTC_SERVE_OPTIONS[3].label &&
                                      serveItem.checked && (
                                        <ul
                                          className={styles.check_li_options_ul}
                                        >
                                          <li
                                            className={
                                              styles.check_li_options_ul_li_config
                                            }
                                          >
                                            最大服务器数：
                                            {serveInfo[
                                              FormInfoConfigEnum
                                                .RTCOnlineMediaMaxServerNum
                                            ] || 0}
                                          </li>
                                        </ul>
                                      )}
                                  </li>
                                );
                              })}
                          </ul>
                        )}
                      {/* RTM 服务 */}
                      {item.label === ANYRTC_SERVE_OPTIONS[1].label &&
                        item.checked && (
                          <ul className={styles.check_li_options_ul}>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              最大服务器数：
                              {serveInfo[FormInfoConfigEnum.RTMMaxServerNum] ||
                                0}
                            </li>
                          </ul>
                        )}
                      {/* 互动白板服务 */}
                      {item.label === ANYRTC_SERVE_OPTIONS[2].label &&
                        item.checked && (
                          <ul className={styles.check_li_options_ul}>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              最大服务器数：
                              {serveInfo[
                                FormInfoConfigEnum.WhiteBoardMaxServerNum
                              ] || 0}
                            </li>
                          </ul>
                        )}
                      {/* 实时对讲服务 */}
                      {item.label === ANYRTC_SERVE_OPTIONS[3].label &&
                        item.checked && (
                          <ul className={styles.check_li_options_ul}>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              最大服务器数：
                              {serveInfo[
                                FormInfoConfigEnum.VoiceMaxServerNum
                              ] || 0}
                            </li>
                          </ul>
                        )}
                    </li>
                  );
                })}
            </ul>
          ) : (
            <div className={styles.out_service}>暂无 anyRTC 服务</div>
          )}
        </div>
        {/* 快对讲 */}
        <div
          className={styles.serve_check_options_item}
          style={{ marginLeft: "20px" }}
        >
          <h3 className={styles.ul_title}>融合通讯服务</h3>
          {isQuickTalkServeChecked ? (
            <ul className={styles.check_ul}>
              {quickTalkList
                .filter((item) => item.checked)
                .map((item) => {
                  return (
                    <li key={item.label} className={styles.check_ul_li_check}>
                      <div className={styles.check_ul_li_check_options}>
                        <img src={CheckImg} alt="" />
                        {item.label}
                      </div>

                      {/* 业务授权 */}
                      {item.label === TALK_SERVE_OPTIONS[0].label &&
                        item.checked && (
                          <ul className={styles.check_li_options_ul}>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              总账号数：
                              {serveInfo[
                                FormInfoConfigEnum
                                  .CharacterLicensingAccountAllNum
                              ] || 0}
                            </li>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              调度员数：
                              {serveInfo[
                                FormInfoConfigEnum.CharacterLicensingYardmanNum
                              ] || 0}
                            </li>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              管理员数：
                              {serveInfo[
                                FormInfoConfigEnum.CharacterLicensingAdminNum
                              ] || 0}
                            </li>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              会议录制并发数：
                              {serveInfo[
                                FormInfoConfigEnum
                                  .CharacterLicensingMeetRecordConcurrencyNum
                              ] || 0}
                            </li>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              通话录制并发数：
                              {serveInfo[
                                FormInfoConfigEnum
                                  .CharacterLicensingCallRecordConcurrencyNum
                              ] || 0}
                            </li>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              最大频道成员数：
                              {serveInfo[
                                FormInfoConfigEnum
                                  .CharacterLicensingMaxChannelMemberNum
                              ] || 0}
                            </li>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              最大服务器数：
                              {serveInfo[
                                FormInfoConfigEnum
                                  .CharacterLicensingMaxServerNum
                              ] || 0}
                            </li>
                          </ul>
                        )}
                      {/* 国标下联网关 */}
                      {item.label === TALK_SERVE_OPTIONS[1].label &&
                        item.checked && (
                          <ul className={styles.check_li_options_ul}>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              最大通道数：
                              {serveInfo[
                                FormInfoConfigEnum.DownLinkGatewayMaxChannelNum
                              ] || 0}
                            </li>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              最大并发数：
                              {serveInfo[
                                FormInfoConfigEnum
                                  .DownLinkGatewayMaxConcurrencyNum
                              ] || 0}
                            </li>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              最大服务器数：
                              {serveInfo[
                                FormInfoConfigEnum.DownLinkGatewayMaxServerNum
                              ] || 0}
                            </li>
                          </ul>
                        )}
                      {/* 国标上联网关 */}
                      {item.label === TALK_SERVE_OPTIONS[2].label &&
                        item.checked && (
                          <ul className={styles.check_li_options_ul}>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              最大通道数：
                              {serveInfo[
                                FormInfoConfigEnum.ConnectedGatewayMaxChannelNum
                              ] || 0}
                            </li>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              最大并发数：
                              {serveInfo[
                                FormInfoConfigEnum
                                  .ConnectedGatewayMaxConcurrencyNum
                              ] || 0}
                            </li>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              最大平台数：
                              {serveInfo[
                                FormInfoConfigEnum
                                  .ConnectedGatewayMaxPlatformNum
                              ] || 0}
                            </li>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              最大服务器数：
                              {serveInfo[
                                FormInfoConfigEnum.ConnectedGatewayMaxServerNum
                              ] || 0}
                            </li>
                          </ul>
                        )}
                      {/* 融合会议网关 */}
                      {item.label === TALK_SERVE_OPTIONS[3].label &&
                        item.checked && (
                          <ul className={styles.check_li_options_ul}>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              最大会议并发数：
                              {serveInfo[
                                FormInfoConfigEnum.MeetGatewayMaxConcurrencyNum
                              ] || 0}
                            </li>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              最大服务器数：
                              {serveInfo[
                                FormInfoConfigEnum.MeetGatewayMaxServerNum
                              ] || 0}
                            </li>
                          </ul>
                        )}
                      {/* H.323连接器网关 */}
                      {item.label === TALK_SERVE_OPTIONS[4].label &&
                        item.checked && (
                          <ul className={styles.check_li_options_ul}>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              最大设备数量：
                              {serveInfo[
                                FormInfoConfigEnum.H323GatewayMaxDeviceNum
                              ] || 0}
                            </li>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              最大呼出并发数：
                              {serveInfo[
                                FormInfoConfigEnum
                                  .H323GatewayMaxExpirationConcurrencyNum
                              ] || 0}
                            </li>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              最大呼入并发数：
                              {serveInfo[
                                FormInfoConfigEnum
                                  .H323GatewayMaxCallinConcurrencyNum
                              ] || 0}
                            </li>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              最大服务器数：
                              {serveInfo[
                                FormInfoConfigEnum.H323GatewayMaxServerNum
                              ] || 0}
                            </li>
                          </ul>
                        )}
                      {/* 电话会议网关 */}
                      {item.label === TALK_SERVE_OPTIONS[5].label &&
                        item.checked && (
                          <ul className={styles.check_li_options_ul}>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              最大并发数：
                              {serveInfo[
                                FormInfoConfigEnum
                                  .TeleconferencingGatewayMaxConcurrencyNum
                              ] || 0}
                            </li>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              最大服务器数：
                              {serveInfo[
                                FormInfoConfigEnum
                                  .TeleconferencingGatewayMaxServerNum
                              ] || 0}
                            </li>
                          </ul>
                        )}
                      {/* RTC2SIP */}
                      {item.label === TALK_SERVE_OPTIONS[6].label &&
                        item.checked && (
                          <ul className={styles.check_li_options_ul}>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              最大并发数：
                              {serveInfo[
                                FormInfoConfigEnum
                                  .RTC2SIPGatewayMaxConcurrencyNum
                              ] || 0}
                            </li>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              最大服务器数：
                              {serveInfo[
                                FormInfoConfigEnum.RTC2SIPGatewayMaxServerNum
                              ] || 0}
                            </li>
                          </ul>
                        )}
                      {/* 非标监控网关 */}
                      {item.label === TALK_SERVE_OPTIONS[7].label &&
                        item.checked && (
                          <ul className={styles.check_li_options_ul}>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              最大通道数：
                              {serveInfo[
                                FormInfoConfigEnum
                                  .NonstandardMonitorGatewayMaxChannelNum
                              ] || 0}
                            </li>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              最大并发数：
                              {serveInfo[
                                FormInfoConfigEnum
                                  .NonstandardMonitorGatewayMaxConcurrencyNum
                              ] || 0}
                            </li>
                            <li
                              className={styles.check_li_options_ul_li_config}
                            >
                              最大服务器数：
                              {serveInfo[
                                FormInfoConfigEnum
                                  .NonstandardMonitorGatewayMaxServerNum
                              ] || 0}
                            </li>
                          </ul>
                        )}
                    </li>
                  );
                })}
            </ul>
          ) : (
            <div className={styles.out_service}>暂无融合通讯服务</div>
          )}
        </div>
      </div>
    </div>
  );
}
