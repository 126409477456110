/**
 * 交互日志
 */

import { Table, message } from "antd";
import { useEffect, useState } from "react";

import type { Option as OptionInterface } from "../../../../axios/axios-types";
import axios from "../../../../axios/axios";
import moment from "moment";

const { Column } = Table;

interface ResultInterface {
  data: {
    code: number;
    data: any;
    msg: string;
  };
}

export default function InteractiveLog(customProps: any) {
  /**
   * 表格基本信息
   */
  const [tableLoading, setTableLoading] = useState(false);
  const [tableDataList, settableDataList] = useState([]);
  const [currentRecord, setCurrentRecord]: [number, Function] = useState(1);
  const [pageSizeRecord, setPageSizeRecord]: [number, Function] = useState(10);
  const [totalRecord, setTotalRecord]: [number, Function] = useState(0);

  // 操作记录分页变化
  const handleCurrentRecordChange = (pagination: any) => {
    setCurrentRecord(pagination.current);
    setPageSizeRecord(pagination.pageSize);
    getTableDataListFn();
  };

  // 表格信息获取
  const getTableDataListFn = async () => {
    setTableLoading(true);
    const option: OptionInterface = {
      url: "/v2/primgr/getOperationRecords",
      data: {
        licenseId: customProps.licenseId || "",
        pageNum: currentRecord,
        pageSize: pageSizeRecord,
      },
      method: "POST",
    };
    // 校验设备码
    const result: ResultInterface = await axios(option).finally(() => {
      setTableLoading(false);
    });
    if (result.data.code === 0) {
      const { list, totalNumber } = result.data.data;
      if (list && totalNumber > 0) {
        settableDataList(
          list.map((info: { createTs: number }) => {
            return {
              ...info,
              startTime:
                info.createTs > 0
                  ? moment.unix(info.createTs).format("YYYY-MM-DD HH:mm:ss")
                  : "--",
            };
          })
        );
      } else {
        settableDataList([]);
        setTotalRecord(0);
      }
    } else {
      message.error(result.data?.msg || "");
    }
  };

  useEffect(() => {
    getTableDataListFn();
  }, []);

  return (
    <div>
      <Table
        onChange={(pagination) => handleCurrentRecordChange(pagination)}
        loading={tableLoading}
        pagination={{
          defaultCurrent: 1,
          current: currentRecord,
          showSizeChanger: true,
          pageSize: pageSizeRecord,
          total: totalRecord,
        }}
        rowKey={() => `${Math.floor(Math.random() * 123333)}`}
        sticky={true}
        dataSource={tableDataList}
      >
        <Column align="center" title="设备码" dataIndex="startTime" />
        <Column align="center" title="请求 IP 地址" dataIndex="startTime" />
        <Column align="center" title="日期" dataIndex="startTime" />
        <Column align="center" title="请求状态" dataIndex="startTime" />
      </Table>
    </div>
  );
}
