/**
 * 基础为空提示
 * @param rule
 * @param value
 */
export const basicNullVerify = (hint: string) => {
  return async (rule: any, value: any): Promise<void> => {
    if (!value || value === "") {
      throw new Error(hint);
    }
  };
};

/**
 * 数字校验 + 首位是否可为0
 * @param rule
 * @param value
 */

export const basicNumberVerify = (
  hint?: string
) => {
  const regNumber = /^\d+(\.\d+)?$/;
  // 正则-判断大于1的正整数
  const regNumber2 = /^(1|([1-9]\d+)|([2-9]))$/;
  return async (rule: any, value: any): Promise<void> => {
    if (value && !regNumber.test(value)) {
      throw new Error(hint || "请输入正确格式(数字)");
    } else if (value && Number(value) > 0 && !regNumber2.test(value)) {
      throw new Error("多位数的首位不能为0");
    }
  };
};

/**
 * 最大值与最小值校验
 * @param rule
 * @param value
 */
export const basicExtremumVerify = (
  min: number = 1,
  max: number = 2147483647,
  hint?: string
) => {
  return async (rule: any, value: any): Promise<void> => {
    if (value && (value < min || value > max)) {
      throw new Error(hint || `请输入${min}-${max}之间的数字`);
    }
  };
};
