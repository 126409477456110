import { FC, ReactElement, useEffect, useState } from "react";
import { Select, Input, Button, Table, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { findPermissions } from "../../../../../api/permiss";
import { getTimestampToTime } from "../../../../../api/index";
import { Option } from "../../../../../axios/axios-types";
import axios from "../../../../../axios/axios";
import styles from "./periodexpiring.less";
const { Column } = Table;

namespace Antd {
  export const { Option } = Select;
}

interface Result {
  data: {
    code: number;
    data: any;
  };
}

interface IProps {
  cacheLifecycles: any;
}

enum Status {
  never,
  "已停用",
  "在使用",
  "快到期",
  "已到期",
}

const Period: FC<IProps> = (props): ReactElement => {
  const history: any = useHistory();
  // 部署类型
  const deploymentTypeOption: any[] = [
    {
      value: 1,
      label: "全部",
    },
    {
      value: 2,
      label: "anyRTC",
    },
    {
      value: 3,
      label: "快对讲",
    },
  ];
  // 部署类型选择
  const [deplType, setDeplType]: [number, Function] = useState(1);

  const [searchParam, setSearchParam]: [string, Function] = useState("");
  const [url, setUrl]: [string, Function] = useState("getAllUserList");
  const [loading, setLoading]: [boolean, Function] = useState(false);
  const [current, setCurrent]: [number, Function] = useState(1);
  const [pageSize, setPageSize]: [number, Function] = useState(10);
  const [total, setTotal]: [number, Function] = useState(0);
  const [tableData, setTableData]: [Array<any>, Function] = useState([]);
  const [tableLoading, setTableLoading]: [boolean, Function] = useState(false);

  // const data: any = {
  //   private: {
  //     name: "测试期客户",
  //     url: "/content/private/period",
  //   },
  //   perm: {
  //     xs: 31202,
  //     bh: 31203,
  //     my: 31204,
  //     glfw: 31205,
  //     bz: 31206,
  //     fwq: 31211,
  //     zzfw: 31221,
  //     zzxq: 31222,
  //     sqxq: 31223,
  //     bjxx: 31212,
  //     sc: 31213,
  //     jqxq: 31214,
  //   },
  // };

  useEffect((): void => {
    getAllUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, current, deplType, url]);

  props.cacheLifecycles.didRecover(() => {
    getAllUserList();
  });

  const getAllUserList: Function = async (): Promise<void> => {
    const option: Option = {
      url: `/v2/primgr/${url}`,
      data: {
        dType: 2,
        status: 3,
        pageNum: current,
        pageSize: pageSize,
        searchParam,
        serviceType: deplType,
      },
      method: "POST",
    };
    setTableLoading(true);
    url === "searchAllUserList" && setLoading(true);
    const result: Result = await axios(option);
    setTableLoading(false);
    url === "searchAllUserList" && setLoading(false);
    if (result.data.code === 0) {
      result.data.data.list.forEach((item: any): void => {
        item.status = Status[item.status];
        item.licType = item.arrangeType === 2 ? "混合云" : "私有云";
        item.applyTs = getTimestampToTime(item.applyTs);
        item.stopTs = getTimestampToTime(item.stopTs);
      });
      url === "getAllUserList" && setSearchParam("");
      setTotal(result.data.data.totalNumber);
      setTableData(result.data.data.list);
    } else {
      message.error("数据获取失败");
    }
  };

  const handleSearch: Function = (): void => {
    if (searchParam) {
      setCurrent(1);
      setUrl("searchAllUserList");
    } else {
      // message.error("请输入内容");
      setSearchParam("");
      setCurrent(1);
      setUrl("getAllUserList");
    }
  };

  const handlePagChange: Function = (pagination: any): void => {
    setCurrent(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const computedClass: Function = (record: any): string => {
    return record.status === "在使用"
      ? styles.complete
      : record.status === "快到期"
      ? styles.refused
      : styles.agree;
  };

  // 跳转到服务详情
  const handleView: Function = (record: any): void => {
    // history.push(
    //   "/content/private/detail/basicInfor",
    //   Object.assign(record, data)
    // );
    const data: any = {
      private: {
        name: "快到期测试客户",
        url: "/content/privateNew/periodexpiring",
      },
    };
    history.push(
      "/content/privateNew/serveDetail",
      Object.assign(
        {
          licenseId: record.licenseId,
        },
        data
      )
    );
  };

  // 部署选择
  const handelDeplTypeChange: Function = (value: number): void => {
    // 设置初始分页
    setCurrent(1);
    setDeplType(value);
    setSearchParam("");
    // 设置方法
    setUrl("getAllUserList");
  };
  return (
    <div className={styles.period}>
      <div className={styles.toolbar}>
        <div className={styles.toolbarLeft}>
          <Select
            className={styles.toolbarItem}
            style={{ width: 200 }}
            value={deplType}
            onChange={(value) => handelDeplTypeChange(value)}
          >
            {deploymentTypeOption.map(
              (item: any, index: number): ReactElement => {
                return (
                  <Antd.Option value={item.value} key={index}>
                    {item.label}
                  </Antd.Option>
                );
              }
            )}
          </Select>
          <Input
            className={styles.toolbarItem}
            value={searchParam}
            onChange={(e) => setSearchParam(e.target.value)}
            placeholder="请输入内容"
          />
          <Button
            className={styles.toolbarItem}
            loading={loading}
            onClick={() => handleSearch()}
            type="primary"
            icon={<SearchOutlined />}
          >
            Search
          </Button>
        </div>
      </div>
      <Table
        onChange={(pagination, filters, sorter) =>
          handlePagChange(pagination, filters, sorter)
        }
        loading={tableLoading}
        className={styles.table}
        pagination={{
          defaultCurrent: 1,
          current,
          showSizeChanger: true,
          pageSize,
          total,
        }}
        rowKey={() => `${Math.floor(Math.random() * 123333)}`}
        sticky={true}
        dataSource={tableData}
      >
        <Column align="center" title="申请时间" dataIndex="applyTs" />
        <Column align="center" title="到期时间" dataIndex="stopTs" />
        <Column align="center" title="公司名称" dataIndex="companyName" />
        <Column align="center" title="预留邮箱" dataIndex="email" />
        <Column align="center" title="预留手机号" dataIndex="phoneNumber" />
        <Column align="center" title="部署类型" dataIndex="licType" />
        <Column align="center" title="服务类型" dataIndex="serviceType" />
        <Column
          align="center"
          title="状态"
          render={(operation: any, record: any): ReactElement => (
            <span className={computedClass(record)}>{record.status}</span>
          )}
        />
        <Column align="center" title="备注" dataIndex="remark" />
        <Column
          align="center"
          title="操作"
          render={(operation: any, record: any): ReactElement => (
            <div className={styles.operationBox}>
              {findPermissions(42120) ? (
                <p
                  onClick={() => handleView(record)}
                  className={styles.operation}
                >
                  查看详情
                </p>
              ) : (
                <span className={styles.operation}>无权限</span>
              )}
            </div>
          )}
        />
      </Table>
    </div>
  );
};

export default Period;
