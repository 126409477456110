import { FC, Fragment, ReactElement, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Menu } from "antd";
// import { findPermissions } from "../../../../api/permiss";
import { MenuInfo } from "rc-menu/lib/interface";
import {
  dropByCacheKey,
  getCachingKeys,
  CacheRoute,
} from "react-router-cache-route";
import { Option } from "../../../../axios/axios-types";
import axios from "../../../../axios/axios";
import pubsub from "pubsub-js";

// 默认-正式客户
import Formal from "./formal/formal";
// 快到期正式用户
import Expiring from "./expiring/expiring";
// 到期正式用户
import Dueformally from "./dueformally/dueformally";
// 测试期客户
import Period from "./period/period";
// 快到期测试客户
import Periodexpiring from "./periodexpiring/periodexpiring";
// 到期测试客户
import Periodexpire from "./periodexpire/periodexpire";

import styles from "./index.less";

interface Result {
  data: {
    code: number;
    data: any;
  };
}

interface IProps {
  cacheLifecycles: any;
}

const Index: FC<IProps> = (props): ReactElement => {
  const history: any = useHistory();
  const location: any = useLocation();
  const [options, setOptions]: [any[], Function] = useState([
    {
      key: "privateNew",
      num: 0,
      icon: "icon-kehu",
      label: "正式客户",
    },
    {
      key: "expiring",
      num: 0,
      icon: "icon-shalou",
      label: "快到期正式用户",
    },
    {
      key: "dueformally",
      num: 0,
      icon: "icon-daoqi",
      label: "到期正式用户",
    },
    {
      key: "period",
      num: 0,
      icon: "icon-kehu",
      label: "测试客户",
    },
    {
      key: "periodexpiring",
      num: 0,
      icon: "icon-ceshishenqing",
      label: "快到期测试客户",
    },
    {
      key: "periodexpire",
      num: 0,
      icon: "icon-shenhe",
      label: "到期测试客户",
    },
  ]);
  const [selectedKeys, setSelectedKeys]: [string, Function] =
    useState("privateNew");

  useEffect((): void => {
    getSummaryUserNum();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  props.cacheLifecycles.didRecover(() => {
    getSummaryUserNum();
  });

  const getSummaryUserNum: Function = async (): Promise<void> => {
    const option: Option = {
      url: "/v2/primgr/getSummaryUserNum",
      method: "GET",
    };
    const result: Result = await axios(option);
    if (result.data.code === 0) {
      const optio: any[] = JSON.parse(JSON.stringify(options));
      optio.forEach((item: any): void => {
        switch (item.key) {
          case "privateNew": // 正式用户
            item.num = result.data.data.officalUserNum;
            break;
          case "expiring": // 快到期的正式用户
            item.num = result.data.data.nearExpireUserNum;
            break;
          case "dueformally": // 到期的正式用户
            item.num = result.data.data.expireUserNum;
            break;
          case "period": // 测试用户
            item.num = result.data.data.testUserNum;
            break;
          case "periodexpiring": // 快到期的测试用户
            item.num = result.data.data.nearExpireTestUserNum;
            break;
          case "periodexpire": // 到期的测试用户
            item.num = result.data.data.expireTestUserNum;
            break;
        }
      });
      setOptions(optio);
    }
  };

  const handleClick: Function = (e: MenuInfo): void => {
    setSelectedKeys(e.key);
    getSummaryUserNum();
    getCachingKeys().forEach((item: string): void => {
      dropByCacheKey(item);
    });
    setTimeout(() => {
      if (e.key === "privateNew") {
        history.push(`/content/${e.key}`, location.state);
      } else {
        history.push(`/content/privateNew/${e.key}`, location.state);
      }
    }, 0);
  };

  useEffect((): void => {
    const path: Array<string> = location.pathname.split("/");
    setSelectedKeys(path[path.length - 1]);
  }, [location.pathname]);

  useEffect((): void => {
    pubsub.unsubscribe("getSummaryUserNum");
    pubsub.subscribe("getSummaryUserNum", (): void => {
      getSummaryUserNum();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Menu
        onClick={(e) => handleClick(e)}
        selectedKeys={[selectedKeys]}
        mode="horizontal"
      >
        {options.map((item: any, index: number): ReactElement => {
          return (
            <Fragment key={index}>
              <Menu.Item key={item.key} className={styles.menuItem}>
                {item.label} {item.num}
              </Menu.Item>
            </Fragment>
          );
        })}
      </Menu>

      <CacheRoute
        path="/content/privateNew"
        cacheKey="privateNew"
        component={Formal}
        exact
      />

      <CacheRoute
        path="/content/privateNew/expiring"
        cacheKey="expiring"
        component={Expiring}
        exact
      />

      <CacheRoute
        path="/content/privateNew/dueformally"
        cacheKey="dueformally"
        component={Dueformally}
        exact
      />

      <CacheRoute
        path="/content/privateNew/period"
        cacheKey="period"
        component={Period}
        exact
      />

      <CacheRoute
        path="/content/privateNew/periodexpiring"
        cacheKey="periodexpiring"
        component={Periodexpiring}
        exact
      />

      <CacheRoute
        path="/content/privateNew/periodexpire"
        cacheKey="periodexpire"
        component={Periodexpire}
        exact
      />
    </div>
  );
};

export default Index;
