/**
 * 服务定义
 */

import { FormInfoConfig, FormInfoConfigEnum, ServeListType } from "./type";

// | 101  | anyRTC-RTC                |
// | 102  | anyRTC-云端录制           |
// | 103  | anyRTC-小程序             |
// | 104  | anyRTC-旁路推流           |
// | 105  | anyRTC-输入在线媒体流    |
// | 106  | anyRTC-RTM                |
// | 107  | anyRTC-互动白板           |
// | 108  | anyRTC-实时对讲           |
// | 201  | 融合通讯服务-业务授权     |
// | 202  | 融合通讯服务-国际下联网关 |
// | 203  | 融合通讯服务-国际上联网关 |
// | 204  | 融合通讯服务-融合会议网关 |
// | 205  | 融合通讯服务-H.323连接器网关 |
// | 206  | 融合通讯服务-电话会议网关 |
// | 207  | 融合通讯服务-RTC2SIP网关  |
// | 208  | 融合通讯服务-非标监控网关 |

// anyRTC 服务
export const ANYRTC_SERVE_OPTIONS = [
  {
    label: "RTC",
    value: 101,
    checked: false,
    // 是否存在表单
    isForm: true,
  },
  {
    label: "RTM",
    value: 106,
    checked: false,
    // 是否存在表单
    isForm: true,
  },
  {
    label: "互动白板",
    value: 107,
    checked: false,
    // 是否存在表单
    isForm: true,
  },
  {
    label: "实时对讲",
    value: 108,
    checked: false,
    // 是否存在表单
    isForm: true,
  },
];
// RTC 服务
export const RTC_SERVE_OPTIONS = [
  {
    label: "云端录制",
    value: 102,
    checked: false,
    // 是否存在表单
    isForm: true,
  },
  {
    label: "小程序",
    value: 103,
    checked: false,
    // 是否存在表单
    isForm: true,
  },
  {
    label: "旁路推流",
    value: 104,
    checked: false,
    // 是否存在表单
    isForm: true,
  },
  {
    label: "输入在线媒体流",
    value: 105,
    checked: false,
    // 是否存在表单
    isForm: true,
  },
];
// 快对讲服务
export const TALK_SERVE_OPTIONS = [
  {
    label: "业务授权",
    value: 201,
    checked: false,
    // 是否存在表单
    isForm: true,
  },
  {
    label: "国标下联网关",
    value: 202,
    checked: false,
    // 是否存在表单
    isForm: true,
  },
  {
    label: "国标上联网关",
    value: 203,
    checked: false,
    // 是否存在表单
    isForm: true,
  },
  {
    label: "融合会议网关",
    value: 204,
    checked: false,
    // 是否存在表单
    isForm: true,
    hint: "同时可以开多少个会议，并涵盖邀请监控入会功能",
  },
  {
    label: "H.323 连接器网关",
    value: 205,
    checked: false,
    // 是否存在表单
    isForm: true,
  },
  {
    label: "电话会议网关",
    value: 206,
    checked: false,
    // 是否存在表单
    isForm: true,
  },
  {
    label: "RTC2SIP 网关",
    value: 207,
    checked: false,
    // 是否存在表单
    isForm: true,
  },
  {
    label: "非标监控网关",
    value: 208,
    checked: false,
    // 是否存在表单
    isForm: true,
  },
];

// 默认服务器数量
const defaultServeNum = 2;
// 服务初始默认值
const DEFAULT_SERVE_CONFIG = {
  // 音视频并发数
  RTCConcurrencyConcurrencyNum: "",
  // 音视频最大服务器数量
  RTCMaxServerNum: defaultServeNum,

  // RTM 最大服务器数量
  RTMMaxServerNum: defaultServeNum,
  // 互动白板最大服务器数量
  WhiteBoardMaxServerNum: defaultServeNum,
  // 实时对讲最大服务器数量
  VoiceMaxServerNum: defaultServeNum,

  /**
   * RTC相关服务
   */
  // RTC 云端录制最大服务器数量
  RTCCloudRecordMaxServerNum: defaultServeNum,
  // RTC 小程序最大服务器数量
  RTCMiniMaxServerNum: defaultServeNum,
  // RTC 旁路推流最大服务器数量
  RTCPushMaxServerNum: defaultServeNum,
  // RTC 输入在线媒体流最大服务器数量
  RTCOnlineMediaMaxServerNum: defaultServeNum,

  /**
   * 融合通讯相关服务
   */

  // 业务授权-总账号数
  CharacterLicensingAccountAllNum: 20,
  // 业务授权-调度员数
  CharacterLicensingYardmanNum: 1,
  // 业务授权-管理员数
  CharacterLicensingAdminNum: 1,
  // 业务授权-会议录制并发数
  CharacterLicensingMeetRecordConcurrencyNum: "",
  // 业务授权-通话录制并发数
  CharacterLicensingCallRecordConcurrencyNum: "",
  // 业务授权-最大频道成员数
  CharacterLicensingMaxChannelMemberNum: 200,
  // 业务授权-最大服务器数
  CharacterLicensingMaxServerNum: defaultServeNum,

  // 国标下联网关-最大通道数
  DownLinkGatewayMaxChannelNum: 1000,
  // 国标下联网关-最大并发数
  DownLinkGatewayMaxConcurrencyNum: 30,
  // 国标下联网关-最大服务器数
  DownLinkGatewayMaxServerNum: defaultServeNum,

  // 国标上联网关-最大通道数
  ConnectedGatewayMaxChannelNum: 1000,
  // 国标上联网关-最大并发数
  ConnectedGatewayMaxConcurrencyNum: 30,
  // 国标上联网关-最大平台数
  ConnectedGatewayMaxPlatformNum: 1,
  // 国标上联网关-最大服务器数
  ConnectedGatewayMaxServerNum: defaultServeNum,

  // 融合会议网关-最大会议并发数
  MeetGatewayMaxConcurrencyNum: 20,
  // 融合会议网关-最大服务器数
  MeetGatewayMaxServerNum: defaultServeNum,

  // H.323连接器网关-最大设备数量
  H323GatewayMaxDeviceNum: 100,
  // H.323连接器网关-最大呼出并发数
  H323GatewayMaxExpirationConcurrencyNum: 8,
  // H.323连接器网关-最大呼入并发数
  H323GatewayMaxCallinConcurrencyNum: "",
  // H.323连接器网关-最大服务器数
  H323GatewayMaxServerNum: defaultServeNum,

  // 电话会议网关-最大并发数
  TeleconferencingGatewayMaxConcurrencyNum: 4,
  // 电话会议网关-最大服务器数
  TeleconferencingGatewayMaxServerNum: defaultServeNum,

  // RTC2SIP 网关-最大并发数
  RTC2SIPGatewayMaxConcurrencyNum: 4,
  // RTC2SIP 网关-最大服务器数
  RTC2SIPGatewayMaxServerNum: defaultServeNum,

  // 非标监控网关-最大通道数
  NonstandardMonitorGatewayMaxChannelNum: 100,
  // 非标监控网关-最大并发数
  NonstandardMonitorGatewayMaxConcurrencyNum: 30,
  // 非标监控网关-最大服务器数
  NonstandardMonitorGatewayMaxServerNum: defaultServeNum,
} as FormInfoConfig;

export const getDefaultServeConfig = (): FormInfoConfig => {
  return { ...DEFAULT_SERVE_CONFIG };
};

// 服务配置清除恢复默认设置
export const CLEAR_SERVE_CONFIG = (
  serveType: ServeListType,
  typeLabel: string,
  optionConfig?: FormInfoConfig
) => {
  let oM;
  if (serveType === "anyRTC") {
    switch (typeLabel) {
      case ANYRTC_SERVE_OPTIONS[0].label: // 清除RTC服务配置
        oM = {
          [FormInfoConfigEnum.RTCConcurrencyConcurrencyNum]:
            optionConfig?.RTCConcurrencyConcurrencyNum ||
            DEFAULT_SERVE_CONFIG.RTCConcurrencyConcurrencyNum ||
            "",
          [FormInfoConfigEnum.RTCMaxServerNum]:
            optionConfig?.RTCMaxServerNum ||
            DEFAULT_SERVE_CONFIG.RTCMaxServerNum ||
            "",
        };
        break;
      case ANYRTC_SERVE_OPTIONS[1].label: // 清除 RTM 服务配置
        oM = {
          [FormInfoConfigEnum.RTMMaxServerNum]:
            optionConfig?.RTMMaxServerNum ||
            DEFAULT_SERVE_CONFIG.RTMMaxServerNum ||
            "",
        };
        break;
      case ANYRTC_SERVE_OPTIONS[2].label: // 清除互动白板服务配置
        oM = {
          [FormInfoConfigEnum.WhiteBoardMaxServerNum]:
            optionConfig?.WhiteBoardMaxServerNum ||
            DEFAULT_SERVE_CONFIG.WhiteBoardMaxServerNum ||
            "",
        };
        break;
      case ANYRTC_SERVE_OPTIONS[3].label: // 清除实时对讲服务配置
        oM = {
          [FormInfoConfigEnum.VoiceMaxServerNum]:
            optionConfig?.VoiceMaxServerNum ||
            DEFAULT_SERVE_CONFIG.VoiceMaxServerNum ||
            "",
        };
        break;
      default:
        break;
    }
  }
  if (serveType === "RTC") {
    switch (typeLabel) {
      case RTC_SERVE_OPTIONS[0].label: // 云端录制最大服务器数量
        oM = {
          [FormInfoConfigEnum.RTCCloudRecordMaxServerNum]:
            optionConfig?.RTCCloudRecordMaxServerNum ||
            DEFAULT_SERVE_CONFIG.RTCCloudRecordMaxServerNum ||
            "",
        };
        break;
      case RTC_SERVE_OPTIONS[1].label: // 小程序最大服务器数量
        oM = {
          [FormInfoConfigEnum.RTCMiniMaxServerNum]:
            optionConfig?.RTCMiniMaxServerNum ||
            DEFAULT_SERVE_CONFIG.RTCMiniMaxServerNum ||
            "",
        };
        break;
      case RTC_SERVE_OPTIONS[2].label: // 旁路推流最大服务器数量
        oM = {
          [FormInfoConfigEnum.RTCPushMaxServerNum]:
            optionConfig?.RTCPushMaxServerNum ||
            DEFAULT_SERVE_CONFIG.RTCPushMaxServerNum ||
            "",
        };
        break;
      case RTC_SERVE_OPTIONS[3].label: // 输入在线媒体流最大服务器数量
        oM = {
          [FormInfoConfigEnum.RTCOnlineMediaMaxServerNum]:
            optionConfig?.RTCOnlineMediaMaxServerNum ||
            DEFAULT_SERVE_CONFIG.RTCOnlineMediaMaxServerNum ||
            "",
        };
        break;
      default:
        break;
    }
  }
  if (serveType === "Talk") {
    switch (typeLabel) {
      case TALK_SERVE_OPTIONS[0].label: // 快对讲-业务授权
        oM = {
          // 总账号数
          [FormInfoConfigEnum.CharacterLicensingAccountAllNum]:
            optionConfig?.CharacterLicensingAccountAllNum ||
            DEFAULT_SERVE_CONFIG.CharacterLicensingAccountAllNum ||
            "",
          // 调度员数
          [FormInfoConfigEnum.CharacterLicensingYardmanNum]:
            optionConfig?.CharacterLicensingYardmanNum ||
            DEFAULT_SERVE_CONFIG.CharacterLicensingYardmanNum ||
            "",
          // 管理员数
          [FormInfoConfigEnum.CharacterLicensingAdminNum]:
            optionConfig?.CharacterLicensingAdminNum ||
            DEFAULT_SERVE_CONFIG.CharacterLicensingAdminNum ||
            "",
          // 会议录制并发数
          [FormInfoConfigEnum.CharacterLicensingMeetRecordConcurrencyNum]:
            optionConfig?.CharacterLicensingMeetRecordConcurrencyNum ||
            DEFAULT_SERVE_CONFIG.CharacterLicensingMeetRecordConcurrencyNum ||
            "",
          // 通话录制并发数
          [FormInfoConfigEnum.CharacterLicensingCallRecordConcurrencyNum]:
            optionConfig?.CharacterLicensingCallRecordConcurrencyNum ||
            DEFAULT_SERVE_CONFIG.CharacterLicensingCallRecordConcurrencyNum ||
            "",
          // 最大频道成员数
          [FormInfoConfigEnum.CharacterLicensingMaxChannelMemberNum]:
            optionConfig?.CharacterLicensingMaxChannelMemberNum ||
            DEFAULT_SERVE_CONFIG.CharacterLicensingMaxChannelMemberNum ||
            "",
          // 最大服务器数
          [FormInfoConfigEnum.CharacterLicensingMaxServerNum]:
            optionConfig?.CharacterLicensingMaxServerNum ||
            DEFAULT_SERVE_CONFIG.CharacterLicensingMaxServerNum ||
            "",
        };
        break;
      case TALK_SERVE_OPTIONS[1].label: // 快对讲-国标下联网关
        oM = {
          [FormInfoConfigEnum.DownLinkGatewayMaxChannelNum]:
            optionConfig?.DownLinkGatewayMaxChannelNum ||
            DEFAULT_SERVE_CONFIG.DownLinkGatewayMaxChannelNum ||
            "",
          [FormInfoConfigEnum.DownLinkGatewayMaxConcurrencyNum]:
            optionConfig?.DownLinkGatewayMaxConcurrencyNum ||
            DEFAULT_SERVE_CONFIG.DownLinkGatewayMaxConcurrencyNum ||
            "",
          [FormInfoConfigEnum.DownLinkGatewayMaxServerNum]:
            optionConfig?.DownLinkGatewayMaxServerNum ||
            DEFAULT_SERVE_CONFIG.DownLinkGatewayMaxServerNum ||
            "",
        };
        break;
      case TALK_SERVE_OPTIONS[2].label: // 快对讲-国标上联网关
        oM = {
          [FormInfoConfigEnum.ConnectedGatewayMaxChannelNum]:
            optionConfig?.ConnectedGatewayMaxChannelNum ||
            DEFAULT_SERVE_CONFIG.ConnectedGatewayMaxChannelNum ||
            "",
          [FormInfoConfigEnum.ConnectedGatewayMaxConcurrencyNum]:
            optionConfig?.ConnectedGatewayMaxConcurrencyNum ||
            DEFAULT_SERVE_CONFIG.ConnectedGatewayMaxConcurrencyNum ||
            "",
          [FormInfoConfigEnum.ConnectedGatewayMaxPlatformNum]:
            optionConfig?.ConnectedGatewayMaxPlatformNum ||
            DEFAULT_SERVE_CONFIG.ConnectedGatewayMaxPlatformNum ||
            "",
          [FormInfoConfigEnum.ConnectedGatewayMaxServerNum]:
            optionConfig?.ConnectedGatewayMaxServerNum ||
            DEFAULT_SERVE_CONFIG.ConnectedGatewayMaxServerNum ||
            "",
        };
        break;
      case TALK_SERVE_OPTIONS[3].label: // 快对讲-融合会议网关
        oM = {
          [FormInfoConfigEnum.MeetGatewayMaxConcurrencyNum]:
            optionConfig?.MeetGatewayMaxConcurrencyNum ||
            DEFAULT_SERVE_CONFIG.MeetGatewayMaxConcurrencyNum ||
            "",
          [FormInfoConfigEnum.MeetGatewayMaxServerNum]:
            optionConfig?.MeetGatewayMaxServerNum ||
            DEFAULT_SERVE_CONFIG.MeetGatewayMaxServerNum ||
            "",
        };
        break;
      case TALK_SERVE_OPTIONS[4].label: // 快对讲-H.323 连接器网关
        oM = {
          [FormInfoConfigEnum.H323GatewayMaxDeviceNum]:
            optionConfig?.H323GatewayMaxDeviceNum ||
            DEFAULT_SERVE_CONFIG.H323GatewayMaxDeviceNum ||
            "",
          [FormInfoConfigEnum.H323GatewayMaxExpirationConcurrencyNum]:
            optionConfig?.H323GatewayMaxExpirationConcurrencyNum ||
            DEFAULT_SERVE_CONFIG.H323GatewayMaxExpirationConcurrencyNum ||
            "",
          [FormInfoConfigEnum.H323GatewayMaxCallinConcurrencyNum]:
            optionConfig?.H323GatewayMaxCallinConcurrencyNum ||
            DEFAULT_SERVE_CONFIG.H323GatewayMaxCallinConcurrencyNum ||
            "",
          [FormInfoConfigEnum.H323GatewayMaxServerNum]:
            optionConfig?.H323GatewayMaxServerNum ||
            DEFAULT_SERVE_CONFIG.H323GatewayMaxServerNum ||
            "",
        };
        break;
      case TALK_SERVE_OPTIONS[5].label: // 快对讲-电话会议网关
        oM = {
          [FormInfoConfigEnum.TeleconferencingGatewayMaxConcurrencyNum]:
            optionConfig?.TeleconferencingGatewayMaxConcurrencyNum ||
            DEFAULT_SERVE_CONFIG.TeleconferencingGatewayMaxConcurrencyNum ||
            "",
          [FormInfoConfigEnum.TeleconferencingGatewayMaxServerNum]:
            optionConfig?.TeleconferencingGatewayMaxServerNum ||
            DEFAULT_SERVE_CONFIG.TeleconferencingGatewayMaxServerNum ||
            "",
        };
        break;
      case TALK_SERVE_OPTIONS[6].label: // 快对讲-RTC2SIP
        oM = {
          [FormInfoConfigEnum.RTC2SIPGatewayMaxConcurrencyNum]:
            optionConfig?.RTC2SIPGatewayMaxConcurrencyNum ||
            DEFAULT_SERVE_CONFIG.RTC2SIPGatewayMaxConcurrencyNum ||
            "",
          [FormInfoConfigEnum.RTC2SIPGatewayMaxServerNum]:
            optionConfig?.RTC2SIPGatewayMaxServerNum ||
            DEFAULT_SERVE_CONFIG.RTC2SIPGatewayMaxServerNum ||
            "",
        };
        break;
      case TALK_SERVE_OPTIONS[7].label: // 快对讲-非标监控网关
        oM = {
          [FormInfoConfigEnum.NonstandardMonitorGatewayMaxChannelNum]:
            optionConfig?.NonstandardMonitorGatewayMaxChannelNum ||
            DEFAULT_SERVE_CONFIG.NonstandardMonitorGatewayMaxChannelNum ||
            "",
          [FormInfoConfigEnum.NonstandardMonitorGatewayMaxConcurrencyNum]:
            optionConfig?.NonstandardMonitorGatewayMaxConcurrencyNum ||
            DEFAULT_SERVE_CONFIG.NonstandardMonitorGatewayMaxConcurrencyNum ||
            "",
          [FormInfoConfigEnum.NonstandardMonitorGatewayMaxServerNum]:
            optionConfig?.NonstandardMonitorGatewayMaxServerNum ||
            DEFAULT_SERVE_CONFIG.NonstandardMonitorGatewayMaxServerNum ||
            "",
        };
        break;
      default:
        break;
    }
  }

  return oM || {};
};

/**
 * 服务选中解析
 */

enum PortServeArgumentsEnum {
  "rtcConcurrencyNum" = "rtcMaxConcurrency", // RTC服务的并发量
  "rtcMaxServerNum" = "rtcMaxMachine", // RTC服务的最大服务器数量

  "rtcCloudRecordMaxServerNum" = "vodMaxMachine", // RTC云端录制最大服务器数量
  "rtcMiniMaxServerNum" = "appletMaxMachine", // RTC小程序最大服务器数量
  "rtcPushMaxServerNum" = "pushStreamMaxMachine", // RTC旁路推流最大服务器数量
  "rtcOnlineMediaMaxServerNum" = "onlineStreamMaxMachine", // RTC 输入在线媒体流最大服务器数量

  "rtmMaxServerNum" = "rtmMaxMachine", // RTM服务的最大服务器数量
  "whiteBoardMaxServerNum" = "whiteBoardMaxMachine", // 互动白板最大服务器数量
  "voiceMaxServerNum" = "talkMaxMachine", // 实时对讲最大服务器数量

  "characterLicensingAccountAllNum" = "qtalkMaxAccount", // 业务授权-总账号数
  "characterLicensingDispatcherNum" = "qtalkMaxDispatcher", // 业务授权-调度员数量
  "characterLicensingAdminNum" = "qtalkMaxAdmin", // 业务授权-管理员数量
  "characterLicensingMeetRecordConcurrencyNum" = "qtalkConferenceRecordMaxConcurrency", // 业务授权-会议录制并发数
  "characterLicensingCallRecordConcurrencyNum" = "qtalkCallRecordMaxConcurrency", // 业务授权-通话录制并发数
  "characterLicensingMaxChannelMemberNum" = "qtalkMaxGroupMember", // 业务授权-最大频道成员数
  "characterLicensingMaxServerNum" = "qtalkMaxMachine", // 业务授权-最大服务器数

  "downLinkGatewayMaxChannelNum" = "gb28181DownMaxChannel", // 国标下联-最大通道数
  "downLinkGatewayMaxConcurrencyNum" = "gb28181DownMaxConcurrency", // 国标下联-最大并发数
  "downLinkGatewayMaxServerNum" = "gb28181DownMaxMachine", // 国标下联网关-最大服务器数

  "connectedGatewayMaxChannelNum" = "gb28181UpMaxChannel", // 国标上联网关-最大通道数
  "connectedGatewayMaxConcurrencyNum" = "gb28181UpMaxConcurrency", // 国标上联网关-最大并发数
  "connectedGatewayMaxPlatformNum" = "gb28181UpMaxPlatform", // 国标上联网关-最大平台数
  "connectedGatewayMaxServerNum" = "gb28181UpMaxMachine", // 国标上联网关-最大服务器数

  "meetGatewayMaxConcurrencyNum" = "conferenceMaxConcurrency", // 融合会议网关-最大会议并发数
  "meetGatewayMaxServerNum" = "conferenceMaxMachine", // 融合会议网关-最大服务器数

  "h323GatewayMaxDeviceNum" = "h323MaxDevice", // H.323连接器网关-最大设备数量
  "h323GatewayMaxExpirationConcurrencyNum" = "h323MaxOutConcurrency", // H.323连接器网关-最大呼出并发数
  "h323GatewayMaxCallinConcurrencyNum" = "h323MaxInConcurrency", // H.323连接器网关-最大呼入并发数
  "h323GatewayMaxServerNum" = "h323MaxMachine", // H.323连接器网关-最大服务器数

  "teleconferencingGatewayMaxConcurrencyNum" = "qtalkVoipMaxConcurrency", // 电话会议网关-最大并发数
  "teleconferencingGatewayMaxServerNum" = "qtalkvoipMaxMachine", // 电话会议网关-最大服务器数

  "rtc2SipGatewayMaxConcurrencyNum" = "rtc2sipMaxConcurrency", // RTC2SIP 网关-最大并发数
  "rtc2SipGatewayMaxServerNum" = "rtc2sipMaxMachine", // RTC2SIP 网关-最大服务器数

  "nonstandardMonitorGatewayMaxChannelNum" = "monitorMaxChannel", // 非标监控网关-最大通道数
  "nonstandardMonitorGatewayMaxConcurrencyNum" = "monitorMaxConcurrency", // 非标监控网关-最大并发数
  "nonstandardMonitorGatewayMaxServerNum" = "monitorMaxMachine", // 非标监控网关-最大服务器数
}

/**
 * 服务选中并定义转换成接口配置
 */
const PORT_CONFIG = (typeLabel: string, optionConfig?: FormInfoConfig) => {
  let oM;
  switch (typeLabel) {
    // anyRTC
    case ANYRTC_SERVE_OPTIONS[0].label: // 清除RTC服务配置
      oM = {
        // RTC服务的并发量
        [PortServeArgumentsEnum.rtcConcurrencyNum]:
          Number(optionConfig?.RTCConcurrencyConcurrencyNum) || 0,
        // RTC服务的最大服务器数量
        [PortServeArgumentsEnum.rtcMaxServerNum]:
          Number(optionConfig?.RTCMaxServerNum) || 0,
      };
      break;
    case ANYRTC_SERVE_OPTIONS[1].label: // 清除 RTM 服务配置
      oM = {
        [PortServeArgumentsEnum.rtmMaxServerNum]:
          Number(optionConfig?.RTMMaxServerNum) || 0,
      };
      break;
    case ANYRTC_SERVE_OPTIONS[2].label: // 清除互动白板服务配置
      oM = {
        [PortServeArgumentsEnum.whiteBoardMaxServerNum]:
          Number(optionConfig?.WhiteBoardMaxServerNum) || 0,
      };
      break;
    case ANYRTC_SERVE_OPTIONS[3].label: // 清除实时对讲服务配置
      oM = {
        [PortServeArgumentsEnum.voiceMaxServerNum]:
          Number(optionConfig?.VoiceMaxServerNum) || 0,
      };
      break;

    // RTC
    case RTC_SERVE_OPTIONS[0].label: // 云端录制最大服务器数量
      oM = {
        // 云端录制最大服务器数量
        [PortServeArgumentsEnum.rtcCloudRecordMaxServerNum]:
          Number(optionConfig?.RTCCloudRecordMaxServerNum) || 0,
      };
      break;
    case RTC_SERVE_OPTIONS[1].label: // 小程序最大服务器数量
      oM = {
        [PortServeArgumentsEnum.rtcMiniMaxServerNum]:
          Number(optionConfig?.RTCMiniMaxServerNum) || 0,
      };
      break;
    case RTC_SERVE_OPTIONS[2].label: // 旁路推流最大服务器数量
      oM = {
        [PortServeArgumentsEnum.rtcPushMaxServerNum]:
          Number(optionConfig?.RTCPushMaxServerNum) || 0,
      };
      break;
    case RTC_SERVE_OPTIONS[3].label: // 输入在线媒体流最大服务器数量
      oM = {
        [PortServeArgumentsEnum.rtcOnlineMediaMaxServerNum]:
          Number(optionConfig?.RTCOnlineMediaMaxServerNum) || 0,
      };
      break;

    // Talk
    case TALK_SERVE_OPTIONS[0].label: // 快对讲-业务授权
      oM = {
        // 总账号数
        [PortServeArgumentsEnum.characterLicensingAccountAllNum]:
          Number(optionConfig?.CharacterLicensingAccountAllNum) || 0,
        // 调度员数
        [PortServeArgumentsEnum.characterLicensingDispatcherNum]:
          Number(optionConfig?.CharacterLicensingYardmanNum) || 0,
        // 管理员数
        [PortServeArgumentsEnum.characterLicensingAdminNum]:
          Number(optionConfig?.CharacterLicensingAdminNum) || 0,
        // 会议录制并发数
        [PortServeArgumentsEnum.characterLicensingMeetRecordConcurrencyNum]:
          Number(optionConfig?.CharacterLicensingMeetRecordConcurrencyNum) || 0,
        // 通话录制并发数
        [PortServeArgumentsEnum.characterLicensingCallRecordConcurrencyNum]:
          Number(optionConfig?.CharacterLicensingCallRecordConcurrencyNum) || 0,
        // 最大频道成员数
        [PortServeArgumentsEnum.characterLicensingMaxChannelMemberNum]:
          Number(optionConfig?.CharacterLicensingMaxChannelMemberNum) || 0,
        // 最大服务器数
        [PortServeArgumentsEnum.characterLicensingMaxServerNum]:
          Number(optionConfig?.CharacterLicensingMaxServerNum) || 0,
      };
      break;
    case TALK_SERVE_OPTIONS[1].label: // 快对讲-国标下联网关
      oM = {
        [PortServeArgumentsEnum.downLinkGatewayMaxChannelNum]:
          Number(optionConfig?.DownLinkGatewayMaxChannelNum) || 0,
        [PortServeArgumentsEnum.downLinkGatewayMaxConcurrencyNum]:
          Number(optionConfig?.DownLinkGatewayMaxConcurrencyNum) || 0,
        [PortServeArgumentsEnum.downLinkGatewayMaxServerNum]:
          Number(optionConfig?.DownLinkGatewayMaxServerNum) || 0,
      };
      break;
    case TALK_SERVE_OPTIONS[2].label: // 快对讲-国标上联网关
      oM = {
        [PortServeArgumentsEnum.connectedGatewayMaxChannelNum]:
          Number(optionConfig?.ConnectedGatewayMaxChannelNum) || 0,
        [PortServeArgumentsEnum.connectedGatewayMaxConcurrencyNum]:
          Number(optionConfig?.ConnectedGatewayMaxConcurrencyNum) || 0,
        [PortServeArgumentsEnum.connectedGatewayMaxPlatformNum]:
          Number(optionConfig?.ConnectedGatewayMaxPlatformNum) || 0,
        [PortServeArgumentsEnum.connectedGatewayMaxServerNum]:
          Number(optionConfig?.ConnectedGatewayMaxServerNum) || 0,
      };
      break;
    case TALK_SERVE_OPTIONS[3].label: // 快对讲-融合会议网关
      oM = {
        [PortServeArgumentsEnum.meetGatewayMaxConcurrencyNum]:
          Number(optionConfig?.MeetGatewayMaxConcurrencyNum) || 0,
        [PortServeArgumentsEnum.meetGatewayMaxServerNum]:
          Number(optionConfig?.MeetGatewayMaxServerNum) || 0,
      };
      break;
    case TALK_SERVE_OPTIONS[4].label: // 快对讲-H.323 连接器网关
      oM = {
        [PortServeArgumentsEnum.h323GatewayMaxDeviceNum]:
          Number(optionConfig?.H323GatewayMaxDeviceNum) || 0,
        [PortServeArgumentsEnum.h323GatewayMaxExpirationConcurrencyNum]:
          Number(optionConfig?.H323GatewayMaxExpirationConcurrencyNum) || 0,
        [PortServeArgumentsEnum.h323GatewayMaxCallinConcurrencyNum]:
          Number(optionConfig?.H323GatewayMaxCallinConcurrencyNum) || 0,
        [PortServeArgumentsEnum.h323GatewayMaxServerNum]:
          Number(optionConfig?.H323GatewayMaxServerNum) || 0,
      };
      break;
    case TALK_SERVE_OPTIONS[5].label: // 快对讲-电话会议网关
      oM = {
        [PortServeArgumentsEnum.teleconferencingGatewayMaxConcurrencyNum]:
          Number(optionConfig?.TeleconferencingGatewayMaxConcurrencyNum) || 0,
        [PortServeArgumentsEnum.teleconferencingGatewayMaxServerNum]:
          Number(optionConfig?.TeleconferencingGatewayMaxServerNum) || 0,
      };
      break;
    case TALK_SERVE_OPTIONS[6].label: // 快对讲-RTC2SIP
      oM = {
        [PortServeArgumentsEnum.rtc2SipGatewayMaxConcurrencyNum]:
          Number(optionConfig?.RTC2SIPGatewayMaxConcurrencyNum) || 0,
        [PortServeArgumentsEnum.rtc2SipGatewayMaxServerNum]:
          Number(optionConfig?.RTC2SIPGatewayMaxServerNum) || 0,
      };
      break;
    case TALK_SERVE_OPTIONS[7].label: // 快对讲-非标监控网关
      oM = {
        [PortServeArgumentsEnum.nonstandardMonitorGatewayMaxChannelNum]:
          Number(optionConfig?.NonstandardMonitorGatewayMaxChannelNum) || 0,
        [PortServeArgumentsEnum.nonstandardMonitorGatewayMaxConcurrencyNum]:
          Number(optionConfig?.NonstandardMonitorGatewayMaxConcurrencyNum) || 0,
        [PortServeArgumentsEnum.nonstandardMonitorGatewayMaxServerNum]:
          Number(optionConfig?.NonstandardMonitorGatewayMaxServerNum) || 0,
      };
      break;

    default:
      break;
  }
  return oM;
};
export const CONFIG_TRANSITION_PORT = (
  serveList: any[],
  serveConfig: FormInfoConfig
) => {
  const checkIdList = [] as number[];
  const portConfig = {} as any;

  serveList.forEach((item) => {
    if (item.checked) {
      checkIdList.push(item.value);
      const oM = PORT_CONFIG(item.label, serveConfig);
      Object.assign(portConfig, oM);
    }
  });

  return {
    checkIdList,
    portConfig,
  };
};

/**
 * 接口配置转服务定义(id列表+配置)
 */
export const PORT_TRANSITION_CONFIG = (
  idList: number[],
  portConfig: any,
  minSet?: any
) => {
  // 服务可选类型
  let serveType = new Set();

  let serveConfig = getDefaultServeConfig();

  /** anyRTc */
  // 开启 RTC 服务
  if (idList.includes(ANYRTC_SERVE_OPTIONS[0].value)) {
    serveType.add("anyRTC");
    serveConfig[FormInfoConfigEnum.RTCConcurrencyConcurrencyNum] =
      portConfig[PortServeArgumentsEnum.rtcConcurrencyNum];
    serveConfig[FormInfoConfigEnum.RTCMaxServerNum] =
      portConfig[PortServeArgumentsEnum.rtcMaxServerNum];

    if (minSet && minSet.setRestrictRTCMinServerNum) {
      minSet.setRestrictRTCMinServerNum(
        portConfig[PortServeArgumentsEnum.rtcMaxServerNum]
      );
    }
  }
  // 开启 RTM 服务
  if (idList.includes(ANYRTC_SERVE_OPTIONS[1].value)) {
    serveType.add("anyRTC");
    serveConfig[FormInfoConfigEnum.RTMMaxServerNum] =
      portConfig[PortServeArgumentsEnum.rtmMaxServerNum];

    if (minSet && minSet.setRestrictRTMMinServerNum) {
      minSet.setRestrictRTMMinServerNum(
        portConfig[PortServeArgumentsEnum.rtmMaxServerNum]
      );
    }
  }
  // 开启互动白板服务
  if (idList.includes(ANYRTC_SERVE_OPTIONS[2].value)) {
    serveType.add("anyRTC");
    serveConfig[FormInfoConfigEnum.WhiteBoardMaxServerNum] =
      portConfig[PortServeArgumentsEnum.whiteBoardMaxServerNum];

    if (minSet && minSet.setRestrictWhiteBoardMinServerNum) {
      minSet.setRestrictWhiteBoardMinServerNum(
        portConfig[PortServeArgumentsEnum.whiteBoardMaxServerNum]
      );
    }
  }
  // 开启实时对讲服务
  if (idList.includes(ANYRTC_SERVE_OPTIONS[3].value)) {
    serveType.add("anyRTC");
    serveConfig[FormInfoConfigEnum.VoiceMaxServerNum] =
      portConfig[PortServeArgumentsEnum.voiceMaxServerNum];

    if (minSet && minSet.setRestrictVoiceMinServerNum) {
      minSet.setRestrictVoiceMinServerNum(
        portConfig[PortServeArgumentsEnum.voiceMaxServerNum]
      );
    }
  }

  /** RTC */
  // 开启 RTC 云端录制服务
  if (idList.includes(RTC_SERVE_OPTIONS[0].value)) {
    serveType.add("RTC");
    serveConfig[FormInfoConfigEnum.RTCCloudRecordMaxServerNum] =
      portConfig[PortServeArgumentsEnum.rtcCloudRecordMaxServerNum];

    if (minSet && minSet.setRestrictRTCCloudRecordMinServerNum) {
      minSet.setRestrictRTCCloudRecordMinServerNum(
        portConfig[PortServeArgumentsEnum.rtcCloudRecordMaxServerNum]
      );
    }
  }
  // 开启 RTC 程序服务
  if (idList.includes(RTC_SERVE_OPTIONS[1].value)) {
    serveType.add("RTC");
    serveConfig[FormInfoConfigEnum.RTCMiniMaxServerNum] =
      portConfig[PortServeArgumentsEnum.rtcMiniMaxServerNum];

    if (minSet && minSet.setRestrictRTCMiniMinServerNum) {
      minSet.setRestrictRTCMiniMinServerNum(
        portConfig[PortServeArgumentsEnum.rtcMiniMaxServerNum]
      );
    }
  }
  // 开启 RTC 旁路推流服务
  if (idList.includes(RTC_SERVE_OPTIONS[2].value)) {
    serveType.add("RTC");
    serveConfig[FormInfoConfigEnum.RTCPushMaxServerNum] =
      portConfig[PortServeArgumentsEnum.rtcPushMaxServerNum];

    if (minSet && minSet.setRestrictRTCPushMinServerNum) {
      minSet.setRestrictRTCPushMinServerNum(
        portConfig[PortServeArgumentsEnum.rtcPushMaxServerNum]
      );
    }
  }
  // 开启 RTC 输入在线媒体流服务
  if (idList.includes(RTC_SERVE_OPTIONS[3].value)) {
    serveType.add("RTC");
    serveConfig[FormInfoConfigEnum.RTCOnlineMediaMaxServerNum] =
      portConfig[PortServeArgumentsEnum.rtcOnlineMediaMaxServerNum];

    if (minSet && minSet.setRestrictRTCOnlineMediaMinServerNum) {
      minSet.setRestrictRTCOnlineMediaMinServerNum(
        portConfig[PortServeArgumentsEnum.rtcOnlineMediaMaxServerNum]
      );
    }
  }

  /** Talk */
  // 开启快对讲-业务授权
  if (idList.includes(TALK_SERVE_OPTIONS[0].value)) {
    serveType.add("Talk");
    // 总账号数
    serveConfig[FormInfoConfigEnum.CharacterLicensingAccountAllNum] =
      portConfig[PortServeArgumentsEnum.characterLicensingAccountAllNum];
    // 调度员数
    serveConfig[FormInfoConfigEnum.CharacterLicensingYardmanNum] =
      portConfig[PortServeArgumentsEnum.characterLicensingDispatcherNum];
    // 管理员数
    serveConfig[FormInfoConfigEnum.CharacterLicensingAdminNum] =
      portConfig[PortServeArgumentsEnum.characterLicensingAdminNum];
    // 会议录制并发数
    serveConfig[FormInfoConfigEnum.CharacterLicensingMeetRecordConcurrencyNum] =
      portConfig[
        PortServeArgumentsEnum.characterLicensingMeetRecordConcurrencyNum
      ];
    // 通话录制并发数
    serveConfig[FormInfoConfigEnum.CharacterLicensingCallRecordConcurrencyNum] =
      portConfig[
        PortServeArgumentsEnum.characterLicensingCallRecordConcurrencyNum
      ];
    // 最大频道成员数
    serveConfig[FormInfoConfigEnum.CharacterLicensingMaxChannelMemberNum] =
      portConfig[PortServeArgumentsEnum.characterLicensingMaxChannelMemberNum];
    // 最大服务器数
    serveConfig[FormInfoConfigEnum.CharacterLicensingMaxServerNum] =
      portConfig[PortServeArgumentsEnum.characterLicensingMaxServerNum];

    if (minSet && minSet.setRestrictCharacterLicensingMinServerNum) {
      minSet.setRestrictCharacterLicensingMinServerNum(
        portConfig[PortServeArgumentsEnum.characterLicensingMaxServerNum]
      );
    }
  }
  // 开启快对讲-国标下联网关
  if (idList.includes(TALK_SERVE_OPTIONS[1].value)) {
    serveType.add("Talk");
    serveConfig[FormInfoConfigEnum.DownLinkGatewayMaxChannelNum] =
      portConfig[PortServeArgumentsEnum.downLinkGatewayMaxChannelNum];

    serveConfig[FormInfoConfigEnum.DownLinkGatewayMaxConcurrencyNum] =
      portConfig[PortServeArgumentsEnum.downLinkGatewayMaxConcurrencyNum];

    serveConfig[FormInfoConfigEnum.DownLinkGatewayMaxServerNum] =
      portConfig[PortServeArgumentsEnum.downLinkGatewayMaxServerNum];

    if (minSet && minSet.setRestrictDownLinkGatewayMinServerNum) {
      minSet.setRestrictDownLinkGatewayMinServerNum(
        portConfig[PortServeArgumentsEnum.downLinkGatewayMaxServerNum]
      );
    }
  }
  // 开启快对讲-国标上联网关
  if (idList.includes(TALK_SERVE_OPTIONS[2].value)) {
    serveType.add("Talk");
    serveConfig[FormInfoConfigEnum.ConnectedGatewayMaxChannelNum] =
      portConfig[PortServeArgumentsEnum.connectedGatewayMaxChannelNum];

    serveConfig[FormInfoConfigEnum.ConnectedGatewayMaxConcurrencyNum] =
      portConfig[PortServeArgumentsEnum.connectedGatewayMaxConcurrencyNum];

    serveConfig[FormInfoConfigEnum.ConnectedGatewayMaxPlatformNum] =
      portConfig[PortServeArgumentsEnum.connectedGatewayMaxPlatformNum];

    serveConfig[FormInfoConfigEnum.ConnectedGatewayMaxServerNum] =
      portConfig[PortServeArgumentsEnum.connectedGatewayMaxServerNum];

    if (minSet && minSet.setRestrictConnectedGatewayMinServerNum) {
      minSet.setRestrictConnectedGatewayMinServerNum(
        portConfig[PortServeArgumentsEnum.connectedGatewayMaxServerNum]
      );
    }
  }
  // 开启快对讲-融合会议网关
  if (idList.includes(TALK_SERVE_OPTIONS[3].value)) {
    serveType.add("Talk");
    serveConfig[FormInfoConfigEnum.MeetGatewayMaxConcurrencyNum] =
      portConfig[PortServeArgumentsEnum.meetGatewayMaxConcurrencyNum];

    serveConfig[FormInfoConfigEnum.MeetGatewayMaxServerNum] =
      portConfig[PortServeArgumentsEnum.meetGatewayMaxServerNum];

    if (minSet && minSet.setRestrictMeetGatewayMinServerNum) {
      minSet.setRestrictMeetGatewayMinServerNum(
        portConfig[PortServeArgumentsEnum.meetGatewayMaxServerNum]
      );
    }
  }
  // 开启快对讲-H.323 连接器网关
  if (idList.includes(TALK_SERVE_OPTIONS[4].value)) {
    serveType.add("Talk");
    serveConfig[FormInfoConfigEnum.H323GatewayMaxDeviceNum] =
      portConfig[PortServeArgumentsEnum.h323GatewayMaxDeviceNum];

    serveConfig[FormInfoConfigEnum.H323GatewayMaxExpirationConcurrencyNum] =
      portConfig[PortServeArgumentsEnum.h323GatewayMaxExpirationConcurrencyNum];

    serveConfig[FormInfoConfigEnum.H323GatewayMaxCallinConcurrencyNum] =
      portConfig[PortServeArgumentsEnum.h323GatewayMaxCallinConcurrencyNum];

    serveConfig[FormInfoConfigEnum.H323GatewayMaxServerNum] =
      portConfig[PortServeArgumentsEnum.h323GatewayMaxServerNum];

    if (minSet && minSet.setRestrictH323GatewayMinServerNum) {
      minSet.setRestrictH323GatewayMinServerNum(
        portConfig[PortServeArgumentsEnum.h323GatewayMaxServerNum]
      );
    }
  }
  // 开启快对讲-电话会议网关
  if (idList.includes(TALK_SERVE_OPTIONS[5].value)) {
    serveType.add("Talk");
    serveConfig[FormInfoConfigEnum.TeleconferencingGatewayMaxConcurrencyNum] =
      portConfig[
        PortServeArgumentsEnum.teleconferencingGatewayMaxConcurrencyNum
      ];

    serveConfig[FormInfoConfigEnum.TeleconferencingGatewayMaxServerNum] =
      portConfig[PortServeArgumentsEnum.teleconferencingGatewayMaxServerNum];

    if (minSet && minSet.setRestrictTeleconferencingGatewayMinServerNum) {
      minSet.setRestrictTeleconferencingGatewayMinServerNum(
        portConfig[PortServeArgumentsEnum.teleconferencingGatewayMaxServerNum]
      );
    }
  }
  // 开启快对讲-RTC2SIP
  if (idList.includes(TALK_SERVE_OPTIONS[6].value)) {
    serveType.add("Talk");
    serveConfig[FormInfoConfigEnum.RTC2SIPGatewayMaxConcurrencyNum] =
      portConfig[PortServeArgumentsEnum.rtc2SipGatewayMaxConcurrencyNum];

    serveConfig[FormInfoConfigEnum.RTC2SIPGatewayMaxServerNum] =
      portConfig[PortServeArgumentsEnum.rtc2SipGatewayMaxServerNum];

    if (minSet && minSet.setRestrictRTC2SIPGatewayMinServerNum) {
      minSet.setRestrictRTC2SIPGatewayMinServerNum(
        portConfig[PortServeArgumentsEnum.rtc2SipGatewayMaxServerNum]
      );
    }
  }
  // 开启快对讲-非标监控网关
  if (idList.includes(TALK_SERVE_OPTIONS[7].value)) {
    serveType.add("Talk");
    serveConfig[FormInfoConfigEnum.NonstandardMonitorGatewayMaxChannelNum] =
      portConfig[PortServeArgumentsEnum.nonstandardMonitorGatewayMaxChannelNum];

    serveConfig[FormInfoConfigEnum.NonstandardMonitorGatewayMaxConcurrencyNum] =
      portConfig[
        PortServeArgumentsEnum.nonstandardMonitorGatewayMaxConcurrencyNum
      ];

    serveConfig[FormInfoConfigEnum.NonstandardMonitorGatewayMaxServerNum] =
      portConfig[PortServeArgumentsEnum.nonstandardMonitorGatewayMaxServerNum];

    if (minSet && minSet.setRestrictNonstandardMonitorGatewayMinServerNum) {
      minSet.setRestrictNonstandardMonitorGatewayMinServerNum(
        portConfig[PortServeArgumentsEnum.nonstandardMonitorGatewayMaxServerNum]
      );
    }
  }

  return {
    serveType: Array.from(serveType),
    serveConfig,
  };
};
